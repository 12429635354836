<ul class="row">
  <ng-container
    *ngFor="
      let c of crumbs;
      let first = first;
      let last = last;
      let idx = index
    "
  >
    <li *ngIf="(hideCrumbs && idx > 1) || !hideCrumbs"
      ><a [routerLink]="c.link" *ngIf="!last || first">{{ c.label }}</a
      ><strong *ngIf="last && !first">{{ c.label }}</strong
      ><mat-icon *ngIf="!last">arrow_forward</mat-icon></li
    >
  </ng-container>
</ul>
