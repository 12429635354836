import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import * as moment from 'moment';
import { EnvironmentService } from '../../../services/environment.service';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';

@Component({
  selector: 'app-top5Summary',
  templateUrl: './top5Summary.component.html',
  styleUrls: ['./top5Summary.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class Top5SummaryComponent implements OnInit {
  @Input() set solutionId(value) {
    this.solution = value;
  }
  get solutionId() {
    return this.solution;
  }

  private environment;

  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(moment().subtract(30, 'days'));
  endDate = new FormControl(moment());
  gridData = { Headers: [], Data: null };
  solution;
  casesRan = [];
  classesChanged = [];
  rights = [];
  turnOffSummaryPage = false;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private router: Router,
    private envSvc: EnvironmentService,
    private location: Location
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.rights = this.ss.get('rights');
    this.getTop5();
  }

  dateChange() {
    this.getTop5();
  }

  getTop5() {
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs
      .getCaseStatsTop(this.unit, this.solution, start, end)
      .subscribe(x => {
        this.casesRan = x.results.MostCases;
        this.classesChanged = x.results.ScoreClassChanges;
      });

    this.rbs.getGroupProfile('DashboardGlobals').subscribe(x => {
      if (x.UnitInfo?.TurnOffSummaryPage) {
        this.turnOffSummaryPage = true;
      }
    });
  }

  dataRowClicked(row, type) {
    console.log(row, type);
    // switch (type) {
    //   case 'user':
    //     console.log(row);
    //     this.dataView = 'data';
    //     this.getUserCaseSummary(row.UserID);
    //     row['level'] = 'top5';
    //     this.history.push(row);
    //     this.updateBreadcrumb();
    //     break;
    //   case 'client':
    //     this.viewCase(null, row.ProfileID);
    //     row['level'] = 'top5';
    //     this.history.push(row);
    //     this.updateBreadcrumb();
    //     break;
    //   case 'count':
    //     this.viewHistory(null, row.ProfileID);
    //     this.dataView = 'data';
    //     row['level'] = 'top5';
    //     this.history.push(row);
    //     this.updateBreadcrumb();
    //     break;
    // }
    // console.log(this.history);
  }

  viewUser(ev, id) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    // this.loading = true;
    this.router.navigate(['/case-explorer', { user: id.id }]);
  }

  viewCase(ev, data) {
    const { ProfileID, Solution } = data;
    const solution = Solution.toLowerCase();

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (this.turnOffSummaryPage) {
      if (solution === 'li') {
        window.open(
          `${this.location.prepareExternalUrl(
            ''
          )}case/${solution}/${ProfileID}/validate`,
          '_blank'
        );
      }
      window.open(
        `${this.location.prepareExternalUrl(
          ''
        )}case/${solution}/${ProfileID}/results`,
        '_blank'
      );
    } else {
      window.open(
        `${this.location.prepareExternalUrl('')}case/summary/${ProfileID}`,
        '_blank'
      );
    }
  }

  viewHistory(ev, data) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    window.open(
      `${this.environment.basePath}profile-history/${data.Solution}/${data.ProfileID}`,
      '_blank'
    );
  }
}
