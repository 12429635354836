import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../post-trade.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import {
  tradeInfoKey,
  productInfoKey,
  suitabilityKey,
  accountKey,
  tradePositionKey,
  scoreClassKey,
  annuityPositionKey,
  associatedCaseKey,
} from './post-trade-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-post-trade-detail',
  templateUrl: './post-trade-detail.component.html',
  styleUrls: ['./post-trade-detail.component.scss'],
})
export class PostTradeDetailComponent implements OnInit, OnDestroy {
  @Input() tradeId;
  tradeDetail;
  tradeInfoKey = tradeInfoKey;
  productInfoKey = productInfoKey;
  suitabilityKey = suitabilityKey;
  accountKey = accountKey;
  tradePositionKey = tradePositionKey;
  annuityPositionKey = annuityPositionKey;
  associatedCaseKey = associatedCaseKey;
  scoreClassKey = scoreClassKey;
  isEmpty = isEmpty;

  constructor(
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.ptSvc.getPostTradeDetail(this.tradeId).subscribe(resp => {
      this.tradeDetail = resp;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  returnZero() {
    return 0;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  approveTrade() {
    this.loading = true;
    this.ptSvc
      .updateTrade({ TradeID: [this.tradeId], ApprovalStatus: 'APP' })
      .subscribe(() => {
        this.ptSvc.getPostTradeDetail(this.tradeId).subscribe(resp => {
          this.snacky.open(`${this.tradeId} was updated!`, 'Close', {
            duration: 4000,
          });

          this.tradeDetail = resp;
          this.loading = false;
        });
      });
  }

  downloadFactSheet() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }
}
