import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-yesno',
  styleUrls: ['form-yesno.component.scss'],
  template: `<mat-checkbox
      [formControl]="formControl"
      [formlyAttributes]="field"
      (change)="change($event, to, field)"
      [required]="props.required"
      ><span [innerHTML]="props.label"></span
    ></mat-checkbox>
    <!-- <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error> -->
    <mat-error *ngIf="formControl.hasError('regRequired')"
      >This field is needed for suitability review. You can opt out below if the
      applicant refuses to provide the information.</mat-error
    >`,
})
export class FormlyYesNoComponent extends FieldType {
  change(ev, to, field) {
    // console.log(ev, to, field);
    field.model[field.key] = ev.checked;
  }
}
