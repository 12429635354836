import { Injectable } from '@angular/core';
import { Maskito, MaskitoOptions, maskitoUpdateElement } from '@maskito/core';
import {
  maskitoNumberOptionsGenerator,
  maskitoDateOptionsGenerator,
  maskitoAddOnFocusPlugin,
  maskitoRemoveOnBlurPlugin,
  maskitoEventHandler,
  maskitoCaretGuard,
} from '@maskito/kit';
import { Decimal } from 'decimal.js-light';
@Injectable({ providedIn: 'root' })
export class MaskService {
  constructor() {}

  removeMoneyMask(obj) {
    if (obj && (typeof obj === 'string' || obj instanceof String)) {
      return Number(obj.replace(/\,/gi, '').replace('$', ''));
    }
    return obj;
  }

  removePercentMask(obj) {
    if (typeof obj === 'string' || obj instanceof String) {
      if (obj.includes('%,') || obj.includes('%, ')) {
        return +obj.replace(/%/g, '').replace(/\s/g, '').trim() / 100;
      } else {
        return (
          Number(obj.replace(/\,/gi, '').replace(/%/g, '').replace(/\s/g, '')) /
          100
        );
      }
    }
    return Number(obj) / 100;
  }

  formatPercent(num, multiply = false) {
    const inpt = new Decimal(num);
    // return Math.round( ( num + Number.EPSILON ) * 1000 ) / 100;
    if (multiply) {
      return inpt.times(100);
    } else {
      return inpt;
    }
  }

  deconstructPercent(num) {
    return (num / 1000) * 100;
  }

  dollarMaskSpecs() {
    const { plugins, ...numberOptions } = maskitoNumberOptionsGenerator({
      precision: 2,
      prefix: '$',
      thousandSeparator: ',',
    });
    const dollar: MaskitoOptions = {
      ...numberOptions,
      plugins: [
        ...plugins,
        maskitoAddOnFocusPlugin('$'),
        maskitoRemoveOnBlurPlugin('$'),
      ],
    };
    return dollar;
  }

  percentMaskSpecs(precision = 2): {} {
    const { plugins, ...numberOptions } = maskitoNumberOptionsGenerator({
      precision: precision,
      postfix: '%',
      min: 0,
      max: 100,
    });
    const perc: MaskitoOptions = {
      ...numberOptions,
      plugins: [
        ...plugins,
        // Forbids caret to be placed after postfix
        maskitoCaretGuard(value => [0, value.length - 1]),
        maskitoEventHandler('focus', element => {
          if (element.value === '') {
            maskitoUpdateElement(element, `%`);
          }
        }),
        maskitoEventHandler('blur', element => {
          if (element.value === '%') {
            maskitoUpdateElement(element, ``);
          }
        }),
      ],
    };
    return perc;
  }

  dateMaskSpecs(): {} {
    const date = maskitoDateOptionsGenerator({
      mode: 'dd/mm/yyyy',
      separator: '/',
    });
    return date;
  }
}
