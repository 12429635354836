import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class SharedReportDialogComponent implements OnInit {
  form: FormGroup;
  headline: string;
  content: string;
  confirm: string;
  placeholder: string;
  selectedBridges = {};
  reportOptions = { filename: null, annReview: false, needSummary: true, prodSummary: true, needDetail: true, prodDetail: true, threshold: 45, bridges: []};
  specific = false;

  constructor(public dialogRef: MatDialogRef<SharedReportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    if (this.data) {
      this.headline = this.data.headline || 'Select Report Options';
      this.content = this.data.content || '';
      this.placeholder = this.data.placeholder || 'Custom Filename';
      this.confirm = this.data.confirm || 'Generate Report';
      this.reportOptions.prodSummary = this.data.app === 'cp' ? true : false;
      this.reportOptions.prodDetail = this.data.app === 'cp' ? true : false;
      this.reportOptions.needDetail = this.data.app === 'se' && this.data.bulk ? false : true;
      this.reportOptions.threshold = this.data.app === 'cp' ? -1000 : this.reportOptions.threshold;
    }

  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    // { annReview: false, needSummary: false, prodSummary: false, needDetail: false, prodDetail: false }
    const bridgeList = Object.keys(this.selectedBridges);
    if(bridgeList.length) {
      bridgeList.forEach(el => {
        if (this.selectedBridges[el]){
          this.reportOptions.bridges.push(el);
        }
      });
    }
    console.log(this.reportOptions, this.selectedBridges);
    this.dialogRef.close({ data: this.reportOptions });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

}
