import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { AdminService } from '../admin.service';
import { Application, Unit } from '../../models/admin-models';

@Component({
  selector: 'app-unit-profile-upload',
  templateUrl: './unit-profile-upload.component.html',
  styleUrls: ['./unit-profile-upload.component.scss'],
})
export class UnitProfileUploadComponent implements OnInit {
  file;
  fileName;
  uploadForm: FormGroup;
  status;
  statusClass;
  filteredUnits;
  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  applications: Application[] = [
    { value: 'aw', name: 'Annuity Wizard' },
    { value: 'cp', name: 'Client Profiler' },
    { value: 'iw', name: 'Investment Wizard' },
    { value: 'li', name: 'Life Insurance Wizard' },
    { value: 'pp', name: 'Product Profiler' },
    { value: 'rb', name: 'Control KB (RB)' },
    { value: 'cm', name: 'Case Management (CM)' },
    { value: 'pm', name: 'Book Management (PM)' },
  ];
  selectedApp;
  selectedUnit;

  constructor(
    private admnSrvc: AdminService,
    public dialogRef: MatDialogRef<UnitProfileUploadComponent>
  ) {
    const formGroup = {};
    formGroup['app'] = new FormControl('', Validators.required);
    formGroup['note'] = new FormControl('', Validators.required);
    formGroup['bugTrackerId'] = new FormControl('', Validators.required);
    this.uploadForm = new UntypedFormGroup(formGroup);
  }

  ngOnInit(): void {
    this.getUnits();
  }

  getUnits() {
    this.filteredUnits = this.unitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if (value.length > 2) {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  onFileSubmit() {
    if (
      this.uploadForm.valid &&
      this.unitsControl.value.length > 0 &&
      this.file
    ) {
      const note = `Bug Tracker #${
        this.uploadForm.get('bugTrackerId').value
      }: ${this.uploadForm.get('note').value}`;
      const app = this.uploadForm.get('app').value;
      const formData = new FormData();
      formData.append('unitProfileXml', this.file);

      let obs: Observable<string> = null;
      obs = this.admnSrvc.postUnitProfile(
        formData,
        this.unitsControl.value,
        app,
        encodeURIComponent(note)
      );

      obs.subscribe(response => {
        if (response.indexOf('Success') >= 0) {
          this.status = `Uploaded ${this.fileName}`;
          this.statusClass = 'success';
        } else {
          this.status = `Error uploading ${this.fileName}`;
          this.statusClass = 'failure';
        }
      });
    } else {
      this.status = 'Missing required input';
    }
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSrvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }
}
