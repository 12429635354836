<div mat-dialog-header>
  <h1 class="w-100">Create a New Template</h1>
</div>
<div mat-dialog-content class="dialog-container">
  <div class="w-100">
    <form class="col-12 row my-3">
      <mat-form-field class="col-12 col-md-6">
        <input
          matInput
          type="text"
          placeholder="Template Name"
          [formControl]="templateName"
          name="tmpltName"
          [(ngModel)]="template.name"
          required
        />
        <mat-error *ngIf="templateName.hasError('required')"
          >This field is required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <input
          placeholder="Search for a Program"
          matInput
          [formControl]="programControl"
          [matAutocomplete]="programs"
          (change)="checkFundStatus($event)"
          required
        />
        <mat-autocomplete
          #programs="matAutocomplete"
          [displayWith]="displayProg"
          (optionSelected)="handleProgSelect($event)"
        >
          <mat-option
            *ngFor="let option of filteredPrograms | async"
            [value]="option"
            >{{ option.DisplayName }}</mat-option
          >
        </mat-autocomplete>
        <!-- <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" class="clearButton"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button> -->
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <input
          placeholder="Search for a fund"
          matInput
          [formControl]="fundControl"
          [matAutocomplete]="auto"
        />
        <button
          *ngIf="fundControl.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="error"
          (click)="clearFundSearch($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFund"
          (optionSelected)="handleFundSelect($event)"
        >
          @if (dropdownLoading) {
            <mat-option disabled class="dropdownLoadingSpinner"
              ><mat-spinner diameter="48"></mat-spinner
            ></mat-option>
          }
          @if (dropdownNoResults) {
            <mat-option disabled>No Results</mat-option>
          }
          @if (!dropdownLoading && !dropdownNoResults) {
            <mat-option *ngFor="let option of filteredFunds" [value]="option">
              {{ option.FundID }} - {{ option.FundName }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-3">
        <input
          matInput
          mask="separator.2"
          thousandSeparator=","
          decimalMarker="."
          suffix="%"
          placeholder="Percent to Allocate"
          name="fundPercent"
          [(ngModel)]="selected.percent"
          [disabled]="!this.template.program"
        />
      </mat-form-field>
      <button
        mat-button
        class="col-12 col-md-2 greenButton"
        (click)="saveFund()"
        color="accent"
        [disabled]="!selected.percent || !selected.fund"
      >
        <mat-icon aria-label="Add Fund">add</mat-icon> Add Fund
      </button>
      <!-- <button mat-icon-button class="col-12 col-md-1 blueButton" (click)="saveFund()" *ngIf="showFundAdd">
        <mat-icon>save</mat-icon>
      </button> -->
    </form>

    <table class="w-100 mb-3" *ngIf="template.funds">
      <thead>
        <th>Fund</th>
        <th>Amount</th>
        <th width="40"></th>
      </thead>
      <tbody>
        <tr *ngFor="let fnd of template.funds">
          <td>{{ fnd.ticker }} - {{ fnd.name }}</td>
          <td>
            <mat-form-field class="col-12">
              <input
                matInput
                mask="separator.2"
                thousandSeparator=","
                decimalMarker="."
                suffix="%"
                placeholder="Percent to Allocate"
                name="fundPercent"
                [(ngModel)]="fnd.percent"
                [disabled]="!this.template.program"
              />
            </mat-form-field>
          </td>
          <td class="text-center">
            <button
              mat-icon-button
              (click)="deleteFund(fnd.ticker)"
              matTooltip="Delete Fund"
              [matTooltipShowDelay]="1000"
            >
              <mat-icon aria-label="Delete Profile">delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div mat-dialog-actions class="row buttons">
  <mat-checkbox [(ngModel)]="shared" class="ml-3" [disabled]="disableShare"
    >Share Template</mat-checkbox
  >
  <mat-checkbox
    class="ml-3"
    [formControl]="firmTemplate"
    [(ngModel)]="template.firmTemplate"
    *hasPermission="'edit_firm_templates'"
    (click)="setScope()"
    >Firm Template</mat-checkbox
  >
  <button
    mat-raised-button
    style="margin-left: auto"
    (click)="close(); $event.preventDefault()"
    >Cancel</button
  >
  <button
    mat-raised-button
    style="margin-left: 10px"
    color="accent"
    (click)="saveData(); $event.preventDefault()"
    [disabled]="
      !template.funds || (template.funds && template.funds.length < 1)
    "
    >Save Template</button
  >
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
