import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService }  from '../services/session-storage.service'
import { EnvironmentService } from '../services/environment.service';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FundFeeLookupService {

  constructor(
    private http: HttpClient,
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
   }

   private feeCalcDataSource = new Subject();
   feeCalcData$ = this.feeCalcDataSource.asObservable();

   private environment;
   private org: string;

  fundSearch(fund) {
    let sessId = this.ss.get('session');
    let reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', 'iw').set('X-RB-O', this.org);
    return this.rbs.getRequest(`${this.rbs.baseUrl}/Investment/Symbol?searchString=${fund}`, reqHeaders);
  }

  calculate(data) {
    let sessId = this.ss.get('session');
    let reqHeaders = new HttpHeaders().set('X-RB-S', sessId).set('X-RB-I', 'iw').set('X-RB-O', this.org);
    return this.rbs.postRequest(`${this.rbs.baseUrl}/Process/PortfolioCalc`, data, reqHeaders);
  }

  calculationData(ann) {
    this.feeCalcDataSource.next(ann);
  }
}
