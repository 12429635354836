<mat-form-field>
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker" >
    <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
    <input matEndDate placeholder="End date" [(ngModel)]="endDate">
  </mat-date-range-input>
  <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<mat-form-field class="ml-3">
  <mat-label>Alert ID</mat-label>
  <input matInput type="text" [(ngModel)]="alertId" placeholder="Please Enter an Alert ID"/>
</mat-form-field>

<mat-form-field class="ml-3">
  <mat-label>Application</mat-label>
  <mat-select
    [(ngModel)]="selectedApp"
  >
    <mat-option *ngFor="let app of applications" [value]="app.value">
      {{ app.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="ml-3">
  <input
    type="text"
    placeholder="Search For a Unit"
    matInput
    [matAutocomplete]="auto"
    name="unitsControl"
    [formControl]="unitsControl"
    [(ngModel)]="selectedUnit"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<button
  mat-raised-button
  color="primary"
  class="mx-auto"
  (click)="getExceptions()"
  class="ml-3"
  [disabled]="!selectedUnit || !startDate || !endDate || !alertId || !selectedApp"
>
  Get Exceptions
</button>

<div class="row">
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  *ngIf="exceptionsData.data"
  (click)="exportToExcel(exceptionsGrid)"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>


<kendo-grid
  #exceptionsGrid="kendoGrid"
  [hidden]="!exceptionsData.data"
  class="col-12 mt-2 load-history-grid"
  [kendoGridBinding]="exceptionsData.data"
>
  <ng-template ngFor [ngForOf]="exceptionsData.headers" let-column>
    <kendo-grid-column [field]="column.DataField" [title]="column.Label">
      <ng-template *ngIf="column.DataField === 'Pct'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | percent }}
        </ng-template>
    </kendo-grid-column>
  </ng-template>
  <ng-template kendoGridNoRecordsTemplate>
    No Results
 </ng-template>
  <kendo-grid-excel
    fileName="RightBridge-RepExceptionsReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
