<div class="page-container post-trade-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade')" [disabled]="tradeId === ''">
      <span class="text-container">Trade Review</span>
    </button>
    @if (tradeId !== '') {
      <span>/</span>
      <button
        mat-button
        (click)="nav('post-trade')"
        [disabled]="tradeId !== ''"
      >
        <span class="text-container">Trade Details</span>
      </button>
    }
  </div>

  @if (tradeId === '') {
    <h1 class="header col-12 mb-0"> Trade Review </h1>
  } @else {
    <h1 class="header col-12 mb-0"> Trade Details - {{ tradeId }} </h1>
  }

  @if (tradeId === '') {
    <app-post-trade-grid></app-post-trade-grid>
  } @else {
    <app-post-trade-detail [tradeId]="tradeId"></app-post-trade-detail>
  }
</div>
