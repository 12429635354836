<mat-card appearance="outlined" class="col-12 stretch top-opps">
  <mat-card-header>
    Top Opportunities
  </mat-card-header>
  <mat-card-content>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
    <div class="no-results" *ngIf="state == 'none'">
      <h4>No Opportunities Available</h4>
    </div>
    <table *ngIf="state == 'loaded'">
      <thead>
        <th class="name">Name</th>
        <th class="suggestion">Suggestion</th>
      </thead>
      <tbody>
        <tr *ngFor="let opp of opportunities;" (click)="goToProfile($event, opp.id)">
          <td>{{opp.name}}</td>
          <td class="suggestion">
            <ng-container *ngFor="let sg of opp.suggestions; let idx = index;">
              <ng-container *ngIf="idx > 0">, </ng-container>
              <span class="sugg">
                <strong>{{sg.Name}}</strong>&nbsp;<span>{{sg.Relevance}}</span>
              </span>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
