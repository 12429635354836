import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AnnuityDialogComponent } from '../annuity-dialog/annuity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-annuity-lookup',
  templateUrl: './annuity-lookup.component.html',
  styleUrls: ['./annuity-lookup.component.scss'],
})
export class AnnuityLookupComponent implements OnInit {
  @Output() beaconData = new EventEmitter();
  @Input() index;
  @Input() fieldData;

  dialogRef;
  profile;
  varset;

  constructor(
    private dialog: MatDialog,
    private params: ActivatedRoute
  ) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.profile = params.id;
    });
  }

  triggerLookUp(data) {
    this.varset = this.fieldData.key.split('_')[0];

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(AnnuityDialogComponent, {
        panelClass: 'beacon-dialog',
        autoFocus: false,
        data: {
          companies: data,
          index: this.index,
          group: this.fieldData.templateOptions.group,
          profile: this.profile,
          varset: this.varset,
        },
        minHeight: '50vh',
        width: '70vw',
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  alphabetize(a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
}
