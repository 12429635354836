import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investment-wizard',
  templateUrl: './investment-wizard.component.html',
  styleUrls: ['./investment-wizard.component.scss']
})
export class InvestmentWizardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
