<div mat-dialog-title>
  <h1>{{ header }}</h1>
</div>
<div mat-dialog-content #scroll class="dialog-container">
  <ng-container *ngIf="data.action === 'like'; else follow_up">
    <form>
      <div class="row justify-content-center">
        <mat-radio-group
          [(ngModel)]="action.type"
          name="type"
          class="col-6"
          [value]="action.type"
        >
          <mat-radio-button value="2335"
            >Add to call list - and keep on top</mat-radio-button
          >
          <mat-radio-button value="2300"
            >Keep it near the top of the suggestions</mat-radio-button
          >
          <mat-radio-button value="followUp"
            >Follow Up Later (set a call back date)</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div
        class="row justify-content-center"
        *ngIf="action.type === 'followUp'"
      >
        <mat-form-field class="col-3 mt-3">
          <mat-select [(ngModel)]="follow_up_type" name="followup">
            <mat-option value="2120">Around</mat-option>
            <mat-option value="2150">On</mat-option>
            <mat-option value="2160">Not Before</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-3 mt-3">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Choose a date"
            [(ngModel)]="action.date"
            name="date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <mat-form-field class="col-6">
          <textarea
            matInput
            placeholder="Notes"
            [(ngModel)]="action.notes"
            name="notes"
            matTextareaAutosize
            matAutosizeMinRows="3"
            matAutosizeMaxRows="6"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <mat-checkbox
          [(ngModel)]="action.futureLikes"
          name="futureLikes"
          class="col-6"
          >Do this for all future "Likes"</mat-checkbox
        >
      </div>
    </form>
  </ng-container>
  <ng-template #follow_up>
    <form>
      <div class="row justify-content-center">
        <label class="col-12">When to Follow Up</label>
        <mat-form-field class="col-3">
          <mat-select [(ngModel)]="action.type" name="followup">
            <mat-option value="2155">Around</mat-option>
            <mat-option value="2150">On</mat-option>
            <mat-option value="2160">Not Before</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-3">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Choose a date"
            [(ngModel)]="action.date"
            name="date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <mat-form-field class="col-6">
          <textarea
            matInput
            placeholder="Notes"
            [(ngModel)]="action.notes"
            name="notes"
            matTextareaAutosize
            matAutosizeMinRows="3"
            matAutosizeMaxRows="6"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions class="row buttons">
  <button
    mat-raised-button
    color="accent"
    (click)="save()"
    [disabled]="!action.type"
    >Save</button
  >
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>
