import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { BookManagementService } from '../book-management.service';

@Component({
  selector: 'app-bm-launch-modal',
  templateUrl: './bm-launch-modal.component.html',
  styleUrls: ['./bm-launch-modal.component.scss'],
})
export class BmLaunchModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<BmLaunchModalComponent>,
    public cdkDropList: CdkDropList,
    public cdkDrag: CdkDrag,
    private bmSrvc: BookManagementService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  selected = [];
  exchange = [];
  deposit = [];
  loading = false;

  ngOnInit() {
    this.selected = this.data.selectedAnnuities;
  }

  drop(event) {
    if (
      event.previousContainer === event.container ||
      (event.container.element.nativeElement.classList.contains(
        'deposit-list'
      ) &&
        event.container.data.length >= 1)
    ) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  saveData() {
    this.loading = true;
    let exchangeChecked = false;
    let depositChecked = false;
    const processedExchange = [];
    const processedDeposit = [];

    if (this.exchange.length > 0) {
      this.exchange.map((policy, index) => {
        this.bmSrvc.getPolicy(policy.ID, 'ConvertToCADA').subscribe(resp => {
          processedExchange.push(resp['CurrentAnnuityDetailsArray']);
          if (index === this.exchange.length - 1) {
            exchangeChecked = true;
          }

          if (
            exchangeChecked &&
            depositChecked &&
            index === this.exchange.length - 1
          ) {
            setTimeout(() => {
              this.processSaveData(processedExchange, processedDeposit);
            }, 2500);
          }
        });
      });
    } else {
      exchangeChecked = true;
    }

    if (this.deposit.length > 0) {
      this.deposit.map((policy, index) => {
        depositChecked = true;
        this.bmSrvc
          .getPolicy(policy.ID, 'ConvertToAdditionalDepositAnnuity')
          .subscribe(resp => {
            processedDeposit.push(resp['AdditionalDepositAnnuity']);
            if (index === this.deposit.length - 1) {
              depositChecked = true;
            }

            if (
              exchangeChecked &&
              depositChecked &&
              index === this.deposit.length - 1
            ) {
              setTimeout(() => {
                this.processSaveData(processedExchange, processedDeposit);
              }, 2500);
            }
          });
      });
    } else {
      depositChecked = true;
    }
  }

  processSaveData(exchangeArray, depositArray) {
    this.loading = false;
    this.selected = [];
    this.exchange = [];
    this.deposit = [];
    this.dialogRef.close({ data: 'cancel' });
  }

  close() {
    this.selected = [];
    this.exchange = [];
    this.deposit = [];
    this.dialogRef.close({ data: 'cancel' });
  }

  removeAnnuity(item, listType) {
    const index = this[listType].findIndex(policy => policy.ID === item.ID);
    this[listType].splice(index, 1);
  }
}
