import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  Form,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import * as moment from 'moment';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../../environments/environment';
import { Chart } from 'angular-highcharts';
import { Platform } from '@angular/cdk/platform';

import * as Highcharts from 'highcharts';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-systemUsage',
  templateUrl: './systemUsage.component.html',
  styleUrls: ['./systemUsage.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SystemUsageComponent implements OnInit {
  @ViewChild('usageGrid', { static: false }) usageGrid;
  @Input() mode;

  private environment;
  private baseUrl: string;
  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    moment().utc().subtract(30, 'days').startOf('day')
  );
  endDate = new FormControl(moment().utc().endOf('day'));
  data;
  viewMode = 'chart';
  units: any[];
  filteredUnits;
  unitCtrl = new FormControl();
  selectedUnit;
  chart = { data: null, legend: null };
  months = 5;
  limit30 = true;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.getUnits();
    const globals = this.ss.get('globals');
    this.selectedUnit = globals?.user.managerUnits[0].id;
    // this.months = 1;

    this.getChart();
    this.getData();
  }

  getChart() {
    this.loading = true;
    this.dbs.getUsageChart(this.selectedUnit, +this.months).subscribe(x => {
      this.chart.data = x.charts[0].Data;
      this.chart.legend = x.charts[0].Legend;
      this.loading = false;
    });
  }

  getData(user?) {
    this.loading = true;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : moment(this.startDate.value).startOf('day').toISOString();
    const end = this.limit30
      ? new Date().toISOString()
      : moment(this.endDate.value).add(1, 'days').startOf('day').toISOString();

    this.dbs.getUsageData(this.selectedUnit, start, end).subscribe(x => {
      this.data = x;
      this.loading = false;
    });
  }

  getUnits() {
    // this.usServ.unitSearch().subscribe(x => {
    //   console.log(x.names);
    //   this.units = x.names;
    this.filteredUnits = this.unitCtrl.valueChanges
      // .startWith('')
      // .debounceTime(500)
      .pipe(
        distinctUntilChanged(),
        switchMap(unit => {
          if (unit.length > 2) {
            return this._filterUnits(unit);
          }
          return [];
        })
      );

    this.selectedUnit = this.filteredUnits[0];
    // });
  }

  exportExcel() {
    this.usageGrid.saveAsExcel();
  }

  exportCSV() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs.getUsageData(this.selectedUnit, start, end, true).subscribe(x => {
      console.log(x);
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(x);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, 'system-usage.csv', {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        this.loading = false;
      };
      this.loading = false;
    });
  }

  selectUnit(ev) {
    console.log(ev.option.value);
    this.selectedUnit = ev.option.value.id;
    this.getChart();
    this.getData();
  }

  displayFn(val) {
    // console.log(val);
    return val ? val.name : '';
  }

  private _filterUnits(value: string) {
    const list = this.dbs.unitSearch(value).pipe(
      map(x => {
        // console.log(x.names);
        return x.names;
      })
    );
    // console.log(list);
    return list;
  }
}
