import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topBridges',
  templateUrl: './topBridges.component.html',
  styleUrls: ['./topBridges.component.scss']
})
export class TopBridgesComponent implements OnInit {
  bridges: any[];
  state: string = 'loading';

  constructor(private ses: SuggestionEngineService, private router: Router) { }

  ngOnInit() {
    this.ses.getTopBridges().subscribe(x => {
      if (x.profiles && x.profiles.length) {
        this.bridges = x.profiles.map (z => {
          return { id: z.profile.bridge.bridgeId, name: z.profile.bridge.name, rank: z.profile.AverageRank, score: +z.profile.AverageScore / 10, relevant: z.profile.NumRelevant};
        });
        this.state = 'loaded';
      } else if (!x.ClientOpportunities.Clients) {
        this.state = 'none';
      }
    });
  }

  goToSearch(ev, id) {
    this.router.navigate(['/se/search', {type: 'suggestion', id: id}]);
  }

}
