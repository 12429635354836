import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { ProductResultComponent } from '../shared-components/product-result/product-result.component';
import { UniversalSearchComponent } from '../universal-search/universal-search/universal-search.component';
import { ProxyComponent } from '../proxy/proxy.component';
import { AppGlobalsService } from '../services/app-globals.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { FormlyComponent } from '../conditional-form/formly.component';
import { ResultDetailComponent } from '../shared-components/result-detail/result-detail.component';
import { HelpComponent } from '../shared-components/help/help.component';

const routes: Routes = [
  {
    path: 'se',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'search',
        component: UniversalSearchComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'search/:type',
        component: UniversalSearchComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'search/:type/:id',
        component: UniversalSearchComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Existing Case',
        },
      },
      {
        path: 'client/:id',
        component: ProductResultComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Results',
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'client/:id/need-detail/:idx',
        component: ResultDetailComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Results',
        },
      },
      {
        path: 'client/edit/:id',
        component: FormlyComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
          breadcrumb: 'Results',
        },
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
        },
      },
      {
        path: 'proxy',
        component: ProxyComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'SE',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SeRouting {}
