import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
// - import { FieldType } from '@ngx-formly/core';
// + import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

// @Component({
//   template: `<input [formControl]="formControl" />`,
// })
// - export class CustomFieldType extends FieldType {}
// + export class CustomFieldType extends FieldType<FieldTypeConfig> {}
@Component({
  selector: 'formly-compactRepeat-section',
  styleUrls: ['form-compactRepeat.component.scss'],
  template: `
    <!-- <div class="toolbar clearfix w-100"> -->
    <!-- <app-fund-search [type]="props.searchType"></app-fund-search> -->
    <!-- <button mat-button class="btn btn-primary pull-right" color="primary" type="button" (click)="add()"><mat-icon class="mr-2">add_circle</mat-icon>Add</button> -->
    <!-- </div> -->
    <ng-container *ngIf="!table; else table_layout">
      <div
        *ngFor="let field of field.fieldGroup; let i = index"
        class="row compact-repeat"
      >
        <formly-group
          [field]="field"
          [class]="'w-95 row formly-form-container'"
        >
        </formly-group>
        <div class="button-container remove clearfix w-5">
          <button
            mat-icon-button
            class="btn btn-danger w-100"
            color="warn"
            type="button"
            (click)="remove(i)"
            ><mat-icon class="mr-2">add_circle</mat-icon></button
          >
        </div>
      </div>
      <div class="button-container clearfix col-4 offset-4 mt-3">
        <button
          mat-button
          class="btn btn-primary w-100"
          color="primary"
          type="button"
          (click)="add()"
          ><mat-icon class="mr-2">add_circle</mat-icon>Add</button
        >
      </div>
    </ng-container>

    <ng-template #table_layout>
      <table class="w-100">
        <thead>
          <tr>
            <th *ngFor="let col of columns">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let field of field.fieldGroup; let i = index">
            <!-- <pre>{{formControl | json}}</pre> -->
          </tr>
        </tbody>
      </table>
      <!-- <pre>{{field.fieldGroup | json}}</pre> -->
    </ng-template>
  `,
})
export class FormlyCompactRepeat extends FieldArrayType implements OnInit {
  // TODO: add explicit constructor

  @HostBinding('class') classes =
    'w-100 form-section row compact-repeat-container';

  columns = [];
  table = false;
  columnWidth;

  ngOnInit() {
    this.onPopulate(this.field);

    this.table = this.field.fieldArray['props'].layout == 'table';

    this.columns = this.field.fieldArray['fieldGroup'].map(el => {
      return { label: el.props.label, key: el.key };
    });

    this.columnWidth = 100 / this.columns.length;
  }
}
