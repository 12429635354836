<h5 *ngIf="this.title">{{this.title}}</h5>
<div class="clearfix mb-5 tables-wrapper">
  <table class="{{style}}">
    <thead *ngIf="style != 'minimal'">
        <tr>
            <th *ngFor="let h of chart.Header; let idx = index;" [class.text-center]="h == 'Status'">
                {{h}}</th>
        </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let t of chart.Data; let idx = index;" >
        <tr *ngIf="idx < chart.Limit" [ngClass]="t.Status">
          <td class="label">
            <ng-container *ngIf="style == 'minimal'">
              <mat-icon *ngIf="t.Status == 'Positive'">check_circle</mat-icon>
              <mat-icon *ngIf="t.Status == 'Negative'">remove_circle</mat-icon>
              <mat-icon *ngIf="t.Status == 'Warning'">error</mat-icon>
            </ng-container>
              {{t.Area}}
          </td>
          <td class="text-center" *ngIf="style != 'minimal'">
            <ng-container *ngIf="t.Status == 'Positive' || t.Status == 'Negative' || t.Status == 'Warning'; else text_content">
              <mat-icon *ngIf="t.Status == 'Positive'">check</mat-icon>
              <mat-icon *ngIf="t.Status == 'Negative'">close</mat-icon>
              <mat-icon *ngIf="t.Status == 'Warning'">priority_high</mat-icon>
            </ng-container>
            <ng-template #text_content>
              <strong>{{t.Status}}</strong>
            </ng-template>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
    <table class="{{style}}">
      <thead *ngIf="style != 'minimal'">
          <tr>
              <th *ngFor="let h of chart.Header; let idx = index;" [class.text-center]="h == 'Status'">
                  {{h}}</th>
          </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let t of chart.Data; let idx = index;" >
          <tr *ngIf="idx >= chart.Limit" [ngClass]="t.Status">
            <td class="label">
              <ng-container *ngIf="style == 'minimal'">
                <mat-icon *ngIf="t.Status == 'Positive'">check_circle</mat-icon>
                <mat-icon *ngIf="t.Status == 'Negative'">remove_circle</mat-icon>
                <mat-icon *ngIf="t.Status == 'Warning'">error</mat-icon>
              </ng-container>
                {{t.Area}}
            </td>
            <td class="text-center" *ngIf="style != 'minimal'">
              <ng-container *ngIf="t.Status == 'Positive' || t.Status == 'Negative' || t.Status == 'Warning'; else text_content">
                <mat-icon *ngIf="t.Status == 'Positive'">check</mat-icon>
                <mat-icon *ngIf="t.Status == 'Negative'">close</mat-icon>
                <mat-icon *ngIf="t.Status == 'Warning'">priority_high</mat-icon>
              </ng-container>
              <ng-template #text_content>
                <strong>{{t.Status}}</strong>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
</div>
