<div class="utility-nav-container" [ngClass]="{ vertical: vertical }">
  <ng-container *ngIf="current !== 'se'">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>

    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
  </ng-container>

  <ng-container *hasPermission="'NewDashboardTab'">
    <button
      mat-button
      (click)="nav('dashboard')"
      *ngIf="links.includes('dash')"
      [ngClass]="{ active: current === 'dash' }"
    >
      <mat-icon>dashboard</mat-icon>
      <span class="text-container">Dashboard</span>
    </button>
  </ng-container>

  <button
    mat-button
    (click)="nav('se/search')"
    *ngIf="current === 'se'"
    [ngClass]="{ active: current === 'search' }"
  >
    <mat-icon>search</mat-icon>
    <span class="text-container">Find Opportunities</span>
  </button>

  <ng-container *hasPermission="'CaseExplorer'">
    <button
      mat-button
      (click)="nav('/case-explorer')"
      *ngIf="links.includes('caseExplorer')"
      [ngClass]="{ active: current === 'caseExplorer' }"
    >
      <mat-icon>find_in_page</mat-icon>
      <span class="text-container">Case Explorer</span>
    </button>
  </ng-container>

  <ng-container *hasPermission="'ReportingTabInMenu'">
    <button
      mat-button
      (click)="nav('reporting')"
      *ngIf="links.includes('reporting')"
      [ngClass]="{ active: current === 'reporting' }"
    >
      <mat-icon>assessment</mat-icon>
      <span class="text-container">Reporting</span>
    </button>
  </ng-container>
</div>
