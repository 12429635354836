<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    VIA Rates
    <button mat-button (click)='load()' class="header-button download-link">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
    <span *ngIf="state=='loaded' || state=='none'">
      <div mat-dialog-content class="dialog-container">
        <div class='row'>
          <mat-form-field class='col-2'>
            <mat-label>Rows</mat-label>
            <select matNativeControl (change)='updateRowCount($event.target.value)'>
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </mat-form-field>
          <div class='col-9'></div>
          <button class='col-1' mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()'
            class="header-button download-link">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </span>
    <div class="no-results" *ngIf="state == 'none'">
      <h4>No Annuities Available</h4>
    </div>
    <strong *ngIf="state == 'error'">Error Loading Rates</strong>
    <strong *ngIf="expiring">Annuity Rates Expiring</strong>
    <table *ngIf="expiring">
      <thead>
        <th>Carrier</th>
        <th>Carrier Code</th>
        <th>Product Code</th>
        <th>Modifier</th>
        <th>Version Date</th>
        <th>Full Name</th>
        <th>ID</th>
        <th>Cap Rate</th>
        <th>Participation Rate</th>
        <th>Participation Threshold</th>
        <th>Participation Tier Two</th>
        <th>Spread</th>
        <th>Step Rate</th>
        <th>Rate Lock Period</th>
        <th>Buffer</th>
        <th>Floor</th>
        <th>Term</th>
        <th>Prem Min</th>
        <th>Prem Max</th>
        <th>Effective Date</th>
        <th>Deprecate Date</th>
      </thead>
      <tbody>
        <tr *ngFor="let a of expiring;" [className]="GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])">
          <td>{{a['Carrier Name']}}</td>
          <td>{{a.CarrierCode}}</td>
          <td>{{a['Product Code']}}</td>
          <td>{{a.Modifier}}</td>
          <td>{{a['Version Date']}}</td>
          <td>{{a['Full Name']}}</td>
          <td>{{a.id}}</td>
          <td>{{a['Rate Cap']}}</td>
          <td>{{a['Participation Rate']}}</td>
          <td>{{a['Participation Threshold']}}</td>
          <td>{{a['Participation Tier 2']}}</td>
          <td>{{a.Spread}}</td>
          <td>{{a['Step/Trigger Rate']}}</td>
          <td>{{a['Rate Lock Period']}}</td>
          <td>{{a.Buffer}}</td>
          <td>{{a.Floor}}</td>
          <td>{{a.Term}}</td>
          <td>{{a['Premium Minimum']}}</td>
          <td>{{a['Premium Maximum']}}</td>
          <td>{{a['Rate Effective Date']}}</td>
          <td>{{a['Rate Deprecate Date']}}</td>
        </tr>
      </tbody>
    </table>
    <strong *ngIf="updating">Annuity Rates Updating</strong>
    <table *ngIf="updating">
      <thead>
        <th>Carrier</th>
        <th>Carrier Code</th>
        <th>Product Code</th>
        <th>Modifier</th>
        <th>Version Date</th>
        <th>Full Name</th>
        <th>ID</th>
        <th>Cap Rate</th>
        <th>Participation Rate</th>
        <th>Participation Threshold</th>
        <th>Participation Tier Two</th>
        <th>Spread</th>
        <th>Step Rate</th>
        <th>Rate Lock Period</th>
        <th>Buffer</th>
        <th>Floor</th>
        <th>Term</th>
        <th>Prem Min</th>
        <th>Prem Max</th>
        <th>Effective Date</th>
        <th>Deprecate Date</th>
      </thead>
      <tbody>
        <tr *ngFor="let a of updating;" [className]="GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])">
          <td>{{a['Carrier Name']}}</td>
          <td>{{a.CarrierCode}}</td>
          <td>{{a['Product Code']}}</td>
          <td>{{a.Modifier}}</td>
          <td>{{a['Version Date']}}</td>
          <td>{{a['Full Name']}}</td>
          <td>{{a.id}}</td>
          <td>{{a['Rate Cap']}}</td>
          <td>{{a['Participation Rate']}}</td>
          <td>{{a['Participation Threshold']}}</td>
          <td>{{a['Participation Tier 2']}}</td>
          <td>{{a.Spread}}</td>
          <td>{{a['Step/Trigger Rate']}}</td>
          <td>{{a['Rate Lock Period']}}</td>
          <td>{{a.Buffer}}</td>
          <td>{{a.Floor}}</td>
          <td>{{a.Term}}</td>
          <td>{{a['Premium Minimum']}}</td>
          <td>{{a['Premium Maximum']}}</td>
          <td>{{a['Rate Effective Date']}}</td>
          <td>{{a['Rate Deprecate Date']}}</td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>