<mat-form-field class="" class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="usage.unit"
    [matAutocomplete]="usageAuto"
    name="usageUnitsControl"
    [formControl]="usageUnitsControl"
  />
  <mat-autocomplete #usageAuto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="startPicker"
    placeholder="Start Date"
    [(ngModel)]="usage.start"
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="startPicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #startPicker></mat-datepicker>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="endPicker"
    placeholder="End Date"
    [(ngModel)]="usage.end"
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="endPicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #endPicker></mat-datepicker>
</mat-form-field>
<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getUsage()"
  [disabled]="!usage.unit"
>
  Get Report
</button>
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  *ngIf="usageGridData.data"
  (click)="exportToExcel(unitUsageGrid)"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>

<kendo-grid
  #unitUsageGrid="kendoGrid"
  [hidden]="!usageGridData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="usageGridData.data"
  [height]="600"
  [selectable]="true"
>
  <kendo-grid-column
    *ngFor="let col of usageGridData.header"
    [field]="col.DataField"
    [title]="col.Label"
  ></kendo-grid-column>
  <kendo-grid-excel
    fileName="RightBridge-UnitUsageReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
