<div mat-dialog-title>
  <h1>{{headline}}</h1>
</div>
<div mat-dialog-content #scroll class="dialog-container">
  <!-- <form [formGroup]="form"> -->
    <!-- <mat-form-field class="col-12">
      <textarea matInput [formControlName]="'reason'" [placeholder]="placeholder" [required]="required"></textarea>
      <mat-error>This field is required</mat-error>
    </mat-form-field> -->
  <!-- </form> -->
  </div>
<div mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">{{confirm}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>