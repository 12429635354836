<div class="w-100 notes clearfix">
  <h5>Notes</h5>
  <p [innerHTML]="this.notesData.prompt"></p>
  <mat-form-field class="w-100">
    @if (moduleNotes && notesData.required) {
      <div class="notes-notification" [ngClass]="{ valid: note }">
        @if (note && moduleNoteSaved) {
          <mat-icon class="mr-1">thumb_up</mat-icon>Required Notes Entered for
          This Module
        } @else {
          <mat-icon class="mr-1">error</mat-icon>Notes Required for This Module
        }
      </div>
    }
    <mat-label>Enter Notes Here</mat-label>
    <textarea
      matInput
      [placeholder]="'Enter Notes Here'"
      [(ngModel)]="note"
      (keyup)="noteEntry.next($event)"
    ></textarea>
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    class="pull-right"
    (click)="saveNote()"
    [disabled]="!note || !fieldTouched"
    *hasPermission="'!NoteValidation'"
  >
    Save Note
  </button>
  <button
    *hasPermission="'NoteValidation'"
    mat-flat-button
    color="primary"
    class="pull-right"
    (click)="validateAndSaveNote()"
    [disabled]="!note || !fieldTouched"
  >
    Save & Review
  </button>

  <div
    class="note-save-success"
    [ngClass]="{ show: noteSaved, hide: noteScore !== '' }"
  >
    <mat-icon>thumb_up</mat-icon> Your note was saved.
  </div>

  <div
    *hasPermission="'ShowNoteAssistant'"
    class="note-score-container"
    [ngClass]="noteScore"
  >
    <div>
      <mat-icon class="close-icon" (click)="closeValidation()">close</mat-icon>
      <ng-container *ngIf="noteScore === 'positive'">
        Your note was reviewed and saved.
      </ng-container>
      <ng-container *ngIf="noteScore === 'negative'">
        <mat-icon>error</mat-icon> {{ scoreMessage }}
      </ng-container>
    </div>
  </div>

  <div *ngIf="containsPii" class="note-score-container negative">
    <div>
      <mat-icon class="close-icon" (click)="closePii()">close</mat-icon>
      <mat-icon>error</mat-icon> The submitted note appears to have sensitive
      information in it like an SSN or Account Number(s). Please check for this,
      remove any of these details, and try again.
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
