import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-status',
  styleUrls: ['form-status.component.scss'],
  template: `
    <ng-container *ngFor="let stati of to.options">
      <div class="indicator"
        [ngClass]="{'neutral': +formControl.value == 0 || +formControl.value == 3, 'positive': +formControl.value == 1 || +formControl.value == 4, 'negative': +formControl.value == 1}"
        *ngIf="stati.value == +formControl.value"
        [matTooltip]="stati.label"
      >
        <mat-icon *ngIf="+formControl.value == 0">not_interested</mat-icon>
        <mat-icon *ngIf="+formControl.value == 1">thumb_down_alt</mat-icon>
        <mat-icon *ngIf="+formControl.value == 2">verified_user</mat-icon>
        <mat-icon *ngIf="+formControl.value == 3">report_problem</mat-icon>
        <mat-icon *ngIf="+formControl.value == 4">local_atm</mat-icon>
        <!-- {{stati.label}} -->
      </div>
    </ng-container>
  `
})
export class FormlyStatus extends FieldType { }
