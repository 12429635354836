<mat-card appearance="outlined" class="col-12 stretch">
    <mat-card-header>
      Fixed Annuities Active Rates
      <button mat-button (click)='load()' class="header-button download-link"><mat-icon>cloud_download</mat-icon></button>
    </mat-card-header>
    <mat-card-content>
      <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
      <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()' class="header-button download-link"><mat-icon>save</mat-icon></button>
      <div class="no-results" *ngIf="state == 'none'">
        <h4>No Annuities Available</h4>
      </div>
        <strong *ngIf="state == 'error'">Error Loading Rates</strong>
          <strong *ngIf="updating">Active Fixed Annuity Rates</strong>
          <table *ngIf="updating">
            <thead>
              <th>Carrier</th>
              <th>Carrier Code</th>
              <th>ID</th>
              <th>Cusip</th>
              <th>Product</th>
              <th>Version</th>
              <th>Term</th>
              <th>BaseRate</th>
              <th>Guarantee Length</th>
              <th>Bonus</th>
              <th>PreMin</th>
              <th>PremMax</th>
              <th>ROP</th>
              <th>Effective Date</th>
              <th>Expiration</th>
            </thead>
            <tbody>
              <tr *ngFor="let a of updating;" [className]="GetClass(a.RateEffectiveDate, a.RateDeprecateDate)">
                <td>{{a.CarrierName}}</td>
                <td>{{a.CarrierCode}}</td>
                <td>{{a.id}}</td>
                <td>{{a.cusip}}</td>
                <td>{{a.Product}}</td>
                <td>{{a.Version}}</td>
                <td>{{a.term}}</td>
                <td>{{a.baseRate}}</td>
                <td>{{a.guaranteeLength}}</td>
                <td>{{a.bonusRate}}</td>
                <td>{{a.premiumRangeMin}}</td>
                <td>{{a.premiumRangeMax}}</td>
                <td>{{a.rop}}</td>
                <td>{{a.RateEffectiveDate}}</td>
                <td>{{a.RateDeprecateDate}}</td>
              </tr>
            </tbody>
        </table>
    </mat-card-content>
  </mat-card>