import { AfterViewChecked, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HasPermissionDirective } from '../../directives/permissions.directive';

@Component({
  selector: 'app-utility-toolbar',
  templateUrl: './utility-toolbar.component.html',
  styleUrls: ['./utility-toolbar.component.scss'],
})
export class UtilityToolbarComponent implements AfterViewChecked {
  @Input() links = ['home', 'dash', 'reporting', 'caseExplorer'];
  @Input() vertical = false;
  @Input() current = null;
  @ViewChild(HasPermissionDirective) permDirect: HasPermissionDirective;

  constructor(private route: Router) {}

  ngAfterViewChecked(): void {
    document.getElementsByClassName('page-container')[0] &&
    !document
      .getElementsByClassName('page-container')[0]
      .classList.value.includes('toolbar-parent') &&
    !this.vertical
      ? document
          .getElementsByClassName('page-container')[0]
          .classList.add('toolbar-parent')
      : null;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
