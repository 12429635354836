import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { FormService } from '../../form-service.service';

export interface Related {
  ProfileID: string;
  LinkedID: string;
  CaseNumber: string;
  Description: string;
  FirstName: string;
  LastName: string;
  LastInteractionDate: string;
}

@Component({
  selector: 'form-insuredSwap',
  styleUrls: ['form-insuredSwap.component.scss'],
  template: `
    <div class="row w-100 ">
      <div
        class="info-container"
        [ngClass]="{
          'col-6': relatedList && relatedList.length > 0,
          'col-12': !relatedList || relatedList.length < 1
        }"
      >
        <div
          class="w-100 mb-3"
          *ngIf="!to.model.InsuredInfo.ClientPerson_LastName"
        >
          <span class="case-message"
            ><mat-icon>add_circle</mat-icon> Your new case has been successfully
            created!</span
          >
          <span [innerHTML]="InsuredSwapPt1"></span>
        </div>
        <div
          class="w-100 mb-3"
          *ngIf="
            props.model.Client_BaseID == to.data.profile &&
            to.model.InsuredInfo.ClientPerson_LastName
          "
        >
          <span [innerHTML]="InsuredSwapPt2"></span>
        </div>
        <div
          class="w-100 mb-3"
          *ngIf="
            props.model.Client_BaseID !== to.data.profile &&
            to.model.InsuredInfo.ClientPerson_LastName
          "
        >
          <span [innerHTML]="InsuredSwapPt3"></span>
        </div>
        <button
          mat-raised-button
          color="primary"
          (click)="swapInsured($event)"
          [formlyAttributes]="field"
          type="button"
          *ngIf="props.model.InsuredInfo.ClientPerson_LastName"
          >{{ to.label }}</button
        >
      </div>
      <div
        *ngIf="relatedList && relatedList.length > 0"
        class="col-6 related-cases pr-0"
      >
        <h4>Related Cases</h4>
        <ul class="related-list">
          <li
            *ngFor="let profile of relatedList"
            (click)="gotoProfile(profile.LinkedID)"
          >
            <mat-icon>account_circle</mat-icon> {{ profile.CaseNumber }} -
            {{ profile.FirstName }} {{ profile.LastName }} -
            {{ profile.LastInteractionDate }}
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class FormlyInsuredSwap extends FieldType implements OnInit {
  @Output() returnData = new EventEmitter();

  relatedList: Related[];
  InsuredSwapPt1;
  InsuredSwapPt2;
  InsuredSwapPt3;
  rights;

  constructor(
    private frmsvc: FormService,
    private router: Router,
    private ss: SessionStorageService
  ) {
    super();
  }

  ngOnInit() {
    this.getLinked();
    this.processMessages();
    this.rights = this.ss.get('rights');
  }

  getLinked() {
    if (this.props.model.Client_BaseID != 'empty') {
      this.frmsvc
        .getLinkedCases(this.props.model.Client_BaseID)
        .subscribe(data => {
          this.relatedList = data.results.filter(x => x.LastName.length);
        });
    }
  }

  swapInsured(ev) {
    this.frmsvc
      .swapInsured(this.props.data.profile, this.model.Profile_Description)
      .subscribe(data => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate([`case/${this.props.data.app}/${data.results}/0`]);
      });
  }

  gotoProfile(id) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([`case/${this.props.data.app}/${id}/0`]);
  }

  processMessages() {
    if (this.props.model.UnitQuestions_InsuredSwapPt1) {
      this.InsuredSwapPt1 = this.replaceVars(
        this.props.model.UnitQuestions_InsuredSwapPt1
      );
    }

    if (this.props.model.UnitQuestions_InsuredSwapPt2) {
      this.InsuredSwapPt2 = this.replaceVars(
        this.props.model.UnitQuestions_InsuredSwapPt2
      );
    }

    if (this.props.model.UnitQuestions_InsuredSwapPt3) {
      this.InsuredSwapPt3 = this.replaceVars(
        this.props.model.UnitQuestions_InsuredSwapPt3
      );
    }
  }

  replaceVars(message) {
    const varReg = /{{.*?}}/gi;
    const varMatch = message.match(varReg);

    if (varMatch && varMatch.length > 0) {
      varMatch.forEach(el => {
        let varName = el.replace('{{', '');
        varName = varName.replace('}}', '');
        varName = varName ? varName.split('.') : null;

        let val = this.props.model;
        varName.forEach(x => {
          val = val[x];
        });
        if (val) {
          message = message.replace(el, val);
        }
      });
    }
    return message;
  }
}
