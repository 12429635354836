import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[DebounceKeyUp]'
})
export class DebounceKeyUpDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 750;
    @Output() debounceKeyUp = new EventEmitter();
    private keyUp = new Subject();
    private subscription: Subscription;

    constructor() { }

    ngOnInit() {
        this.subscription = this.keyUp.pipe(
            debounceTime(this.debounceTime)
        ).subscribe(e => {console.log(e); this.debounceKeyUp.emit(e)});
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('change', ['$event'])
    keyUpEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event);
        this.keyUp.next(event);
    }
}