<mat-form-field class="mr-3">
  <mat-label>Application</mat-label>
  <mat-select
    [(ngModel)]="selectedApp"
    name="apps"
    (selectionChange)="onAppChange()"
  >
    <mat-option *ngFor="let app of applications" [value]="app.value">
      {{ app.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- <pre>{{filteredUnits | json}}</pre> -->
<mat-form-field class="" class="mr-3">
  <input
    type="text"
    placeholder="Search For a Unit"
    matInput
    [(ngModel)]="selectedUnit"
    [matAutocomplete]="auto"
    name="unitsControl"
    [formControl]="unitsControl"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <mat-label>Pick a ReportType</mat-label>
  <mat-select
  [(ngModel)]="selectedType"
  name="reportTypes">
    <mat-option *ngFor="let type of reportTypes" [value]="type">
      {{ type }}
    </mat-option>
  </mat-select>
</mat-form-field>
<button
  mat-raised-button
  (click)="getUnitProfileReport()"
  [disabled]="!selectedApp && !selectedUnit && !selectedType"
  color="accent"
>
  Get Unit Settings Report
</button>
<ngx-loading [show]="loading"></ngx-loading>
