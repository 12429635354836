import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { F5500Service } from './f5500.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SortDescriptor, orderBy, process, State, FilterDescriptor, groupBy, GroupDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
// import { formatNumber } from '@telerik/kendo-intl';
import { SessionStorageService }  from '../services/session-storage.service'
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'f5500-product-shelf',
  templateUrl: './f5500-product-shelf.component.html',
  styleUrls: ['./f5500-product-shelf.component.scss']
})
export class F5500ProductShelfComponent implements OnInit {
  gridData = { data: [], headers: null };
  loading = false;
  lastPage = false;
  public pageSize = 10;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize
  };


  public searchTerm = "";
  public zip = "";
  public distance = "";
  public sponsor = "";
  public planType = "";
  public participantMin = "";
  public participantMax = "";
  public feeMin = "";
  public feeMax = "";

  constructor(
    private fs: F5500Service,
    private ss: SessionStorageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    //this.getGridData();
  }


  getGridData() {
    this.loading = true;
    this.fs.getToken().subscribe(token => {
      try {
        this.fs.getProductGrid(token["access_token"], this.zip, this.participantMin,
          this.participantMax, this.feeMin, this.feeMax, this.distance,
          this.planType, this.searchTerm, this.gridData['data'].length).subscribe(res => {
            if (res.body.results.data.length == 0)
            {
              this.lastPage = true;
            }
            this.gridData.headers = res.body.results.headers;
            this.gridData.data = this.gridData.data.concat(res.body.results.data);
            this.setColumnVisiblity();
            this.loading = false;
          });
      }
      catch {
        this.loading = false;
      }
    });
  }

  setColumnVisiblity() {
    this.gridData.headers.forEach((x, i) => {
      console.log(this.gridData['subHead'])
      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          // this.hideColumn(x.DataField);
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
      }
    });

    this.hidden.forEach(x => {
      this.hideColumn(x);
    });

    // this.resultsGrid.autoFitColumns();
  }

  isHidden(columnName: string): boolean {
    // console.log(columnName, this.hidden, this.hidden.indexOf(columnName));
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    // console.log(this.hidden);
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.state['skip'] = skip;
    this.state['take'] = take;
    if (skip + take >= this.gridData['data'].length && !this.lastPage) {
      this.getGridData();
    }
  }

  searchValid() {
    return this.searchTerm.length > 0 ||
      (this.distance.length > 0 && this.zip.length > 0) ||
      (this.participantMax.length > 0 && this.participantMin.length > 0) ||
      (this.feeMax.length > 0 && this.feeMin.length > 0);
  }

  search() {
    this.gridData.data = [];
    this.state.skip = 0;
    this.lastPage = false;
    this.getGridData();
  }

  setStorage() {
    this.ss.set('hiddenpProductColumns', this.hidden);
  }

  compare(ev, data) {
    console.log(ev, data);
  }

  dlFactsheet(ev, data) {
    console.log(ev, data);
  }

  createNewCase(ev, data) {
    console.log(ev, data);
  }

}
