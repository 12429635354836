<div
  class="page-container questionnaire-container container {{
    formContext.type
  }}-container"
  id="scrollContainer"
>
  <div class="questions-container row">
    <span class="page-message" *ngIf="model['UnitQuestions_PageHeadline']">{{
      model['UnitQuestions_PageHeadline']
    }}</span>
    <div class="w-100">
      <form [formGroup]="form" (ngSubmit)="saveForm(model)">
        <formly-form
          #questionnaire
          [fields]="fields"
          [model]="model"
          [form]="form"
          [ngClass]="{
            'col-12 row': formContext.type !== 'unified',
            'w-100': formContext.type === 'unified'
          }"
          class="{{ formContext.type }}"
          (modelChange)="modelUpdate($event)"
        ></formly-form>
        <div class="col-9 error">
          <!-- <<<<<<<<<<<<<<<<
          {{checkCustomError()}} {{!form.valid}} {{submitted}} {{this.context | json}} -->
          <ng-container *ngIf="!form.valid && submitted">
            <!-- <p *ngIf="formErrors.includes('required' ) || form.status === 'INVALID'">
              <mat-icon>warning</mat-icon>All required fields have not been entered.
            </p> -->

            <ng-container *ngIf="checkCustomError()">
              <p *ngFor="let msg of customMessages">
                <mat-icon>warning</mat-icon><span>{{ msg }}</span>
              </p>
            </ng-container>
          </ng-container>
          <!-- >>>>>>>>>>>>>>> -->
        </div>
        <div class="col-12 col-lg-9 pt-4 opt-out">
          <p
            *ngIf="
              ((model['LifeWizard_AssessmentType'] === '2' ||
                model['LifeWizard_AssessmentType'] === 2) &&
                !form.valid &&
                submitted &&
                formErrors.includes('regRequired')) ||
              ((model['LifeWizard_AssessmentType'] === '2' ||
                model['LifeWizard_AssessmentType'] === 2) &&
                model['regSkipped']) ||
              ((model['AnnuityWizard_AssessmentType'] === '2' ||
                model['AnnuityWizard_AssessmentType'] === 2) &&
                !form.valid &&
                submitted &&
                formErrors.includes('regRequired')) ||
              ((model['AnnuityWizard_AssessmentType'] === '2' ||
                model['AnnuityWizard_AssessmentType'] === 2) &&
                model['regSkipped'])
            "
          >
            <mat-checkbox
              [(ngModel)]="model['regSkipped']"
              [ngModelOptions]="{ standalone: true }"
              (change)="handleRegClick()"
            ></mat-checkbox>
            <span class="opt-out-message" [innerHTML]="optOutMessage"></span>
          </p>
        </div>
        <div class="buttons col-12 row" #buttons>
          <ng-container
            *ngIf="
              !loading &&
              !noSaveProfile &&
              (!formContext ||
                (formContext &&
                  formContext.type !== 'unitProfile' &&
                  formContext.type !== 'unified'))
            "
          >
          </ng-container>
          <ng-container *ngIf="!loading && !sections && !formContext.type">
            <button
              mat-raised-button
              type="submit"
              color="accent"
              class="main-button"
              >{{ nextButtonText }}
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </ng-container>
        </div>
        <pre *ngIf="debug">{{ model | json }}</pre>
      </form>
    </div>
  </div>
  <ngx-loading
    [show]="loading"
    *ngIf="context && context.type !== 'unified'"
  ></ngx-loading>
</div>
