import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../suggestion-engine/suggestion-engine.service';
// import { HasPermissionDirective } from '../directives/permissions.directive';
import { SessionStorageService } from '../services/session-storage.service';

import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  msgContent;
  app = this.ss.get('currentApp');
  solutions = [{ label: 'All', value: 'ALL' }];
  solution = 'ALL';
  unit = this.ss.get('firm');
  startDate = moment().subtract(30, 'days').toISOString();
  endDate = moment().toISOString();
  rights = this.ss.get('rights');

  constructor(
    private ses: SuggestionEngineService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.ses.getMessages().subscribe(msgs => {
      this.msgContent = msgs.messages;
    });

    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.solutions.push({
            label: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.solutions.push({
            label: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        // case 'rw':
        //   this.solutions.push({label: 'Rollover Wizard', value: el.toLowerCase()});
        //   break;
        case 'li':
          this.solutions.push({
            label: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.solutions.push({
            label: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        case 'rb':
          this.solutions.push({
            label: 'Control KB (RB)',
            value: el.toLowerCase(),
          });
          break;
        // case 'cp':
        //   this.solutions.push({label: 'Client Profiler', value: el.toLowerCase()});
        //   break;
        // case 'se':
        //   this.solutions.push({label: 'Suggestion Engine', value: el.toLowerCase()});
        //   break;
        default:
          break;
      }
    });

    this.solution = this.solutions[0].value;
  }
}
