import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, of, Subject } from 'rxjs';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
// import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class F5500Service {
  private environment;
	private token: string;

	constructor(
    private http: HttpClient,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
	}
	getProductGrid(
    token: string,
    zip: string,
    pMin: string,
    pMax: string,
    feeMin: string,
    feeMax: string,
    distance: string,
    planType: string,
    name: string,
    start: number
  ): Observable<any> {
		this.token = token;
		const reqHeaders = new HttpHeaders()
			.set('Accept', 'application/json')
			.set('Response-Type', 'application/json')
			.set('X-RB-O', this.rbs.getOrg())
			.set('X-RB-I', this.rbs.getApp())
			.set('X-RB-E', this.getEnv())
			.set('Authorization', `Bearer ${token}`);

		let search = {};
		if (zip?.length > 0)
		{
			search["Zip"] = {"Value": zip};
		}
		if (distance?.length > 0)
		{
			search["Distance"] = {"Value": distance};
		}
		if (name?.length > 0)
		{
			search["Sponsor"] = {"Value": name};
		}
		if (feeMin?.length > 0 )
		{
			search["TotalFee"] = {"Min": feeMin, "Max": feeMax};
		}
		if (pMin?.length > 0)
		{
			search["ParticipantCount"] = {"Min": pMin, "Max": pMax};
		}
		if (planType?.length > 0)
		{
			search["PlanType"] = {"Value": planType};
		}
		let url = this.environment.f5500Base + `/F5500/Search?Action=SEARCHPLANSAPI&Values=${JSON.stringify(search)}&Start=${start}`;
		return this.http.get(url, { headers: reqHeaders, observe: 'response' });
	}

	getToken(): Observable<any> {
		if (this.token == null) {
			const reqHeaders = new HttpHeaders()
				.set('Content-Type', "application/json")
				.set('X-RB-O', this.rbs.getOrg())
				.set('X-RB-I', this.rbs.getApp())
				.set('X-RB-E', this.getEnv());

			let url = this.environment.f5500Base + '/Auth/Token';
			return this.http.post(url, `{"subjectToken":"${this.rbs.getSession()}"}`, { headers: reqHeaders });
		}
		else {
			return of({'access_token': this.token});
		}
	}

	getEnv(): string {
		if (this.environment.f5500Base.indexOf("localhost") >= 0)
		{
			return "local";
		}
		else if(this.environment.f5500Base.indexOf("dev.") >= 0)
		{
			return "dev";
		}
		else if(this.environment.f5500Base.indexOf("qa.") >= 0)
		{
			return "qa";
		}
		else if(this.environment.f5500Base.indexOf("uat.") >= 0)
		{
			return "uat";
		}
		return "prod";
	}
}
