import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { distinctUntilChanged, map, switchMap, startWith } from 'rxjs/operators';
import { ReportingService } from '../../reporting.service';

@Component({
  selector: 'app-sales-tracking',
  templateUrl: './sales-tracking.component.html',
  styleUrls: ['./sales-tracking.component.scss']
})
export class SalesTrackingComponent implements OnInit {

  salesUnitsControl = new FormControl();
  selectedUnit;
  sales = {unit: null};
  filteredUnits;
  salesTrackingData = {data: null, headers: null};

  constructor(private admnSvc: AdminService, private rptSvc: ReportingService) { }

  ngOnInit(): void {
    this.getSalesUnits();
  }

  getSalesTracking() {
    this.rptSvc.getSalesTracking(this.sales.unit).subscribe({
      next: (data) => {
        this.salesTrackingData = {...data.results};
      },
      error: (error) => {
        console.log(error)
      }
   });
  }


  getSalesUnits() {
    this.filteredUnits = this.salesUnitsControl.valueChanges
      .pipe(
        startWith('%'),
        distinctUntilChanged(),
        switchMap(value => {
          if((value && value.length > 2) || value == '%') {
            return this._filterUnits(value);
          } return [];
        })
      );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25'
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

}
