<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<div mat-dialog-content class="dialog-container col-12">
  <div class="inner row col-12 network-body" [innerHTML]="body"></div>
  @if (networkResponseReceived) {
    <div
      class="inner row col-12 network-message"
      [innerHTML]="'Response received.'"
    ></div>
  } @else {
    <app-simple-loading></app-simple-loading>
    <div
      class="inner row col-12 network-message"
      [innerHTML]="currentLoadingMessage"
    ></div>
  }
</div>
