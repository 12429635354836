import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import {
  SortDescriptor,
  orderBy,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { SessionStorageService } from '../../services/session-storage.service';
import { UniversalSearchService } from '../services/universal-search.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { HierarchyModalComponent } from '../hierarchy-modal/hierarchy-modal.component';
import { CopyDialogComponent } from '../../copy-dialog/copy-dialog.component';
import { ConsolidatedReportModalComponent } from '../../shared-components/consolidated-report-modal/consolidated-report-modal.component';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

@Component({
  selector: 'app-profile-search',
  templateUrl: './profile-search.component.html',
  styleUrls: ['./profile-search.component.scss'],
})
export class ProfileSearchComponent implements OnInit {
  @Output() caseSelected = new EventEmitter();
  @ViewChild(DataBindingDirective) directive;

  public gridData;
  public ogData = [];
  public gridColumns = [];
  public pageSize = 10;
  public skip = 0;
  public sort: SortDescriptor[] = [
    {
      field: 'LastInteractionDate',
      dir: 'desc',
    },
  ];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  loading = false;
  currentUser: string;
  proxyUser: string;

  selectedUser;
  selectedUnit;
  selectedRepCode;

  userSearch;
  unitSearch;
  repCodeSearch;

  searchType: string;
  suggestions;

  searchModel = {
    firstName: '%',
    lastName: '%',
    oppNum: null,
    relThresh: 50,
    actionDate: null,
    suggestions: {},
    actions: {},
    caseNumber: '%',
  };

  searchValue: string = null;
  rows;
  searchControl = new FormControl();
  chooseHierarchy = false;
  rights;
  showCopy = true;
  canCopy = false;
  canDelete = false;
  canSwap = false;
  canPrint = false;

  managerSearch = false;
  noResults = 'Please perform a search to review cases';

  initialSearch = false;
  dialogRef;
  public show = true;
  showSummarylink = false;

  constructor(
    private rbs: RightBridgeApiService,
    private usService: UniversalSearchService,
    private ss: SessionStorageService,
    private dialog: MatDialog,
    private router: Router,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    const globals = this.ss.get('globals');
    this.managerSearch = globals?.user.managerUnitCount > 0;
    this.rights = this.ss.get('rights');
    this.proxyUser = this.ss.get('proxySession')
      ? this.ss.get('proxySession').userID
      : null;
    this.currentUser = this.proxyUser || globals?.user.id;
    this.chooseHierarchy =
      !this.selectedUnit &&
      !this.selectedUser &&
      !this.selectedRepCode &&
      this.rights?.includes(
        'DisplayAndSearchOnUnitId',
        'DisplayAndSearchOnUserId',
        'ForceHierarchySelection'
      );
    this.hidden = this.ss.get('hiddenSearchColumns') || [];
    this.showCopy = this.rights?.includes(
      'CopyProfile',
      'SwapProfile',
      'DeleteProfile'
    );
    this.canCopy = this.rights?.includes('CopyProfile');
    this.canDelete = this.rights?.includes('DeleteProfile');
    this.canSwap = this.rights?.includes('SwapProfile');
    this.canPrint = this.rights?.includes('PrintFromSearchPage');

    this.search('%', 0, 200);
    ``;

    this.getApps();
  }

  getApps() {
    this.unfFlowSvc.getAllApps().subscribe(x => {
      x.Modules.map(module => {
        if (module.name === 'SummaryPage') {
          this.showSummarylink = true;
        }
      });
    });
  }

  search(value?, start?, end?) {
    const srch = { user: null };
    let searchDef: string;
    const startRow = start || 1;
    const endRow = end || 999999;

    if (this.selectedUnit) {
      srch['unit'] = this.selectedUnit.id;
    }
    if (this.selectedUser) {
      srch['user'] = this.selectedUser.id;
    }
    if (this.selectedRepCode) {
      srch['rep'] = this.selectedRepCode.repCode;
    }
    if (this.selectedRepCode) {
      searchDef = `{search: [{caseNumber: "${value}"}, {searchby: "CaseNumber"}, {repCode: "${this.selectedRepCode.repCode}"}, {startRow: ${startRow}}, {endRow: ${endRow}}]}`;
    } else if (this.selectedUser) {
      searchDef = `{search: [{caseNumber: "${value}"}, {searchby: "CaseNumber"}, {userid: "${this.selectedUser.id}"}, {startRow: ${startRow}}, {endRow: ${endRow}}]}`;
    } else if (this.selectedUnit) {
      searchDef = `{search: [{caseNumber: "${value}"}, {searchby: "CaseNumber"}, {unitid: "${this.selectedUnit.id}"}, {startRow: ${startRow}}, {endRow: ${endRow}}]}`;
    } else {
      searchDef = `{search: [{caseNumber: "${value}"}, {searchby: "CaseNumber"}, {userid: "${this.currentUser}"}, {startRow: ${startRow}}, {endRow: ${endRow}}]}`;
    }

    this.loading = true;
    this.usService.profileSearch(searchDef, true).subscribe(x => {
      const data = x;

      data.data.forEach(el => {
        el.LastInteractionDate = new Date(el.LastInteractionDate);
      });

      const sorted = orderBy(data.data, this.sort);

      this.ogData = sorted;
      this.rows = sorted;
      this.directive.skip = 0;

      if (data.data.length > 0) {
        data.headers.forEach(z => {
          switch (z.RespVis) {
            case 'xs':
              z.RespVis = '';
              break;
            case 'sm':
              z.RespVis = '(min-width: 700px)';
              break;
            case 'md':
              z.RespVis = '(min-width: 1100px)';
              break;
            case 'lg':
              z.RespVis = '(min-width: 1200px)';
              break;
            case 'xl':
              z.RespVis = '(min-width: 1500px)';
              break;
          }

          if (z.Hidden) {
            this.hidden.push(z.DataField);
          }
        });

        if (end == 200) {
          this.initialSearch = true;
        } else {
          this.initialSearch = false;
        }

        this.gridColumns = data.headers;
        this.loading = false;
      } else {
        this.noResults = 'No cases were found based on the search term';
        this.loading = false;
      }
    });
  }

  isHidden(columnName: string): boolean {
    return this.hidden.includes(columnName);
  }

  setStorage() {
    this.ss.set('hiddenSearchColumns', this.hidden);
  }

  setHierarchy() {
    const dialogRef = this.dialog.open(HierarchyModalComponent, {
      panelClass: 'hierarchy-dialog',
      minHeight: '30vh',
      width: '70vw',
      data: { mode: 'profileSearch' },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedUnit = result.data.unit || null;
      this.selectedUser = result.data.user || null;
      this.selectedRepCode = result.data.rep || null;

      this.chooseHierarchy =
        !this.selectedUnit &&
        !this.selectedUser &&
        !this.selectedRepCode &&
        this.rights.includes(
          'DisplayAndSearchOnUnitId',
          'DisplayAndSearchOnUserId',
          'ForceHierarchySelection'
        );
      this.search('%', 0, 200);
    });
  }

  clearHierarchy() {
    this.selectedUnit = null;
    this.selectedUser = null;
    this.selectedRepCode = null;
    this.chooseHierarchy =
      !this.selectedUnit &&
      !this.selectedUser &&
      !this.selectedRepCode &&
      this.rights.includes(
        'DisplayAndSearchOnUnitId',
        'DisplayAndSearchOnUserId',
        'ForceHierarchySelection'
      );
    this.search('%', 0, 200);
  }

  clearSearch() {
    this.noResults = 'Please perform a search to review cases';
    this.searchValue = null;
    this.rows = this.ogData;
    this.searchControl.setValue('');
    this.search('%', 0, 200);
  }

  updateFilter(event, input) {
    const val = input.value.toLowerCase();
    const temp = this.ogData.filter(d => {
      return (
        d.FullName.toLowerCase().indexOf(val) !== -1 ||
        d.caseNumber.includes(val) ||
        d.caseNumber.toLowerCase().includes(val) ||
        !val
      );
    });
    this.rows = temp;
  }

  delete(id) {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content: 'Are you sure you want to delete this profile?',
        confirm: 'Yes',
        hideCancel: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result, id);
      if (result == 'continue') {
        this.loading = true;
        this.rbs.deleteProfile(id).subscribe(() => {
          this.search('%', 0, 200);
          this.loading = false;
        });
      }
      dialogRef = null;
    });
  }

  copy(item) {
    const dialogRef = this.dialog.open(CopyDialogComponent, {
      panelClass: 'copy-dialog',
      data: {
        headline: 'Copy Profile',
        rights: this.rights,
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data && result.data != 'cancel') {
        const desc = result.data;
        this.loading = true;
        this.rbs.copyProfile(item.id, desc).subscribe(x => {
          const event = { id: x['results'], app: result['app'] };
          this.caseSelected.emit(event);
          this.loading = false;
        });
      }
    });
  }

  swap(item) {
    const dialogRef = this.dialog.open(CopyDialogComponent, {
      panelClass: 'copy-dialog',
      data: {
        headline: 'Copy Profile and Reverse Spouses',
        rights: this.rights,
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data && result.data != 'cancel') {
        const desc = result.data;
        this.loading = true;
        this.rbs.swapProfile(item.id, desc).subscribe(x => {
          const event = {
            id: x['results'],
            app: result['app'] === 'pp' ? 'rb' : result['app'],
          };
          this.caseSelected.emit(event);
          this.loading = false;
        });
      }
      this.loading = false;
    });
  }

  printReport(ev, profileData) {
    console.log(profileData);
    const status = {
      actions: false,
      aw: false,
      clientinfo: false,
      iw: false,
      li: false,
      pp: false,
      summarypage: false,
    };

    Object.keys(status).forEach(x => {
      if (profileData.hasOwnProperty(`${x}Status`)) {
        status[x] = profileData[`${x}Status`] == 'C';
      }
    });

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
        panelClass: 'consolidated-report',
        data: { profile: profileData.id, appStatus: status },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }

    return false;
  }

  launchApp(ev, id, app) {
    ev.preventDefault();
    if (app === 'cm') {
      this.router.navigate([`/case-management/${id}`]);
    } else {
      this.caseSelected.emit({ id: id, app: app, unified: true });
    }
  }
}
