import { Component, OnInit, Input } from '@angular/core';
import { RixtremaService } from '../rixtrema.service';
import { MatDialog } from '@angular/material/dialog';
import { RixtremaDialogComponent } from '../rixtrema-dialog/rixtrema-dialog.component';

@Component({
  selector: 'app-form5500',
  templateUrl: './form5500.component.html',
  styleUrls: ['./form5500.component.scss']
})
export class Form5500Component implements OnInit {
  @Input() fieldData;
  constructor(private rixtremaSvc: RixtremaService, private dialog: MatDialog) {
   }

  ngOnInit() {
  }

  startSession() {
    this.openLookUpDialog({});
  }

  openLookUpDialog(data) {
    data["groupIndex"] = this.fieldData.parent.form.controls.indexOf(this.fieldData.form);
    data["planType"] = this.fieldData.form.controls['CurrentPlanDetailsArray_PlanType'].value;
    data['server'] = 'rightbridge';
    let dialogRef = this.dialog.open(RixtremaDialogComponent, { panelClass: 'rixtrema-dialog', data: data, minHeight: '60vh', width: '95vw' });
    // dialogRef.afterOpened().subscribe(x => { this.loading = false; });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}
