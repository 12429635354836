<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <form [formGroup]="form">
    <mat-form-field class="col-12">
      <textarea
        matInput
        [formControlName]="'reason'"
        [placeholder]="placeholder"
        [required]="required"
      ></textarea>
      <mat-error>This field is required</mat-error>
    </mat-form-field>
  </form>
  <div *ngIf="type === 'fund'" class="disclaimer col-12">
    <strong
      >The client could be missing out on significant breakpoints at $250K or
      more in C-shares.</strong
    >
    The MF disclosure form must be completed.
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">{{
    confirm
  }}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
