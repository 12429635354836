import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGlobalsService } from '../services/app-globals.service';
import { BookManagementComponent } from './book-management-shell/book-management.component';
import { AuthService } from '../services/auth-service.service';

const routes: Routes = [
  {
    path: 'book-management',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: BookManagementComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'BookManagement',
          breadcrumb: 'Book Management',
        },
      },
      {
        path: ':id',
        component: BookManagementComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'BookManagement',
          breadcrumb: 'Book Management',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookManagementRouting {}
