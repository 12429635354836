import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-profile-dialog',
  templateUrl: './create-profile-dialog.component.html',
  styleUrls: ['./create-profile-dialog.component.scss']
})
export class CreateProfileDialogComponent implements OnInit {
  @ViewChild('profileForm', { static: true }) private form: FormGroup;

  modalData = {
    acctType: null,
    state: null,
    purpose: null
  }
  annuity;
  rider;
  states = [
    {value: "AL", label: "Alabama"},
    {value: "AK", label: "Alaska"},
    {value: "AZ", label: "Arizona"},
    {value: "AR", label: "Arkansas"},
    {value: "CA", label: "California"},
    {value: "CO", label: "Colorado"},
    {value: "CT", label: "Connecticut"},
    {value: "DE", label: "Delaware"},
    {value: "DC", label: "District Of Columbia"},
    {value: "FL", label: "Florida"},
    {value: "GA", label: "Georgia"},
    {value: "HI", label: "Hawaii"},
    {value: "ID", label: "Idaho"},
    {value: "IL", label: "Illinois"},
    {value: "IN", label: "Indiana"},
    {value: "IA", label: "Iowa"},
    {value: "KS", label: "Kansas"},
    {value: "KY", label: "Kentucky"},
    {value: "LA", label: "Louisiana"},
    {value: "ME", label: "Maine"},
    {value: "MD", label: "Maryland"},
    {value: "MA", label: "Massachusetts"},
    {value: "MI", label: "Michigan"},
    {value: "MN", label: "Minnesota"},
    {value: "MS", label: "Mississippi"},
    {value: "MO", label: "Missouri"},
    {value: "MT", label: "Montana"},
    {value: "NE", label: "Nebraska"},
    {value: "NV", label: "Nevada"},
    {value: "NH", label: "New Hampshire"},
    {value: "NJ", label: "New Jersey"},
    {value: "NM", label: "New Mexico"},
    {value: "NY", label: "New York"},
    {value: "NC", label: "North Carolina"},
    {value: "ND", label: "North Dakota"},
    {value: "OH", label: "Ohio"},
    {value: "OK", label: "Oklahoma"},
    {value: "OR", label: "Oregon"},
    {value: "PA", label: "Pennsylvania"},
    {value: "RI", label: "Rhode Island"},
    {value: "SC", label: "South Carolina"},
    {value: "SD", label: "South Dakota"},
    {value: "TN", label: "Tennessee"},
    {value: "TX", label: "Texas"},
    {value: "UT", label: "Utah"},
    {value: "VT", label: "Vermont"},
    {value: "VA", label: "Virginia"},
    {value: "WA", label: "Washington"},
    {value: "WV", label: "West Virginia"},
    {value: "WI", label: "Wisconsin"},
    {value: "WY", label: "Wyoming"},
    {value: "AS", label: "American Samoa"},
    {value: "GU", label: "Guam"},
    {value: "MP", label: "Northern Mariana Islands"},
    {value: "PR", label: "Puerto Rico"},
    {value: "UM", label: "United States Minor Outlying Islands"},
    {value: "VI", label: "Virgin Islands"},
  ]

  constructor(
    public dialogRef: MatDialogRef<CreateProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}


  ngOnInit(): void {
    this.annuity = this.data.annuity;
    this.rider = this.data.rider;
    this.modalData.state = this.data.state
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if(this.modalData.purpose && this.modalData.state) {
      this.dialogRef.close(this.modalData);
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }
}
