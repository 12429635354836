<mat-dialog-header>
  <h1>{{headline}}</h1>
</mat-dialog-header>
<mat-dialog-content #scroll class="dialog-container">
  <form>
    <mat-form-field class="col-12">
      <textarea matInput [(ngModel)]="noteData.text" name="text" [placeholder]="placeholder" required></textarea>
      <mat-error>This field is required</mat-error>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="noteData.pinned" name="pinned" class="col-6">Pinned Note</mat-checkbox>
    <mat-checkbox [(ngModel)]="noteData.alert" name="alert" class="col-6">Alert Note</mat-checkbox>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">{{confirm}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>