import { Component, OnInit } from '@angular/core';
import { Form, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService }  from '../../../services/session-storage.service'
import * as moment from 'moment';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-highActivityStats',
  templateUrl: './highActivityStats.component.html',
  styleUrls: ['./highActivityStats.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HighActivityStatsComponent implements OnInit {
  private environment;
  private baseUrl: string;

  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit = this.unitsManaged && this.unitsManaged.length > 0 ? this.unitsManaged[0].id : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(moment().utc().subtract(30, 'days'));
  endDate = new FormControl(moment().utc());
  data;
  viewingUser = false;
  limit30 = true;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) { }

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.getData();
  }

  getData(user?) {
    this.loading = true;
    this.viewingUser = user ? true : false;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    let start = this.limit30 ? back30.toISOString() : this.startDate.value.startOf('day').toISOString();
    let end = this.limit30 ? new Date().toISOString() : this.endDate.value.endOf('day').toISOString();

    this.dbs.getUnitProfileCaseStats(this.unit, start, end, user).subscribe(x => {
      this.data = x;
      this.loading = false;
    });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }

  exportCSV(user?) {
    console.log(user);
    this.loading = true;
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    let start = this.limit30 ? back30.toISOString() : this.startDate.value.startOf('day').toISOString();
    let end = this.limit30 ? new Date().toISOString() : this.endDate.value.endOf('day').toISOString();

    this.dbs.getProfileCaseStatsHistory(this.unit, start, end, user).subscribe(x => {

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(x);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, "Activity-Results.csv", {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari
        });
        this.loading = false;
      };
      this.loading = false;
    });
  }
}
