<mat-card appearance="outlined" class="col-12 stretch">
    <mat-card-header>
      FIA - Active Rates
      <button mat-button (click)='load()' class="header-button download-link"><mat-icon>cloud_download</mat-icon></button>
    </mat-card-header>
    <mat-card-content>
      <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
      <span *ngIf="state=='loaded' || state=='none'">
        <div mat-dialog-content class="dialog-container">
        <div class='row'>
            <mat-form-field class='col-4'>
                <input matInput [(ngModel)]="startDate" placeholder="Start Date MM-DD-YYYY"/>
            </mat-form-field>
            <mat-form-field class='col-4'>
                <input matInput [(ngModel)]="endDate" placeholder="End Date MM-DD-YYYY"/>
            </mat-form-field>
            <div class='col-4'>
              <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()' class="header-button download-link"><mat-icon>save</mat-icon></button>
            </div>
        </div>
      </div>
      </span>
      <div class="no-results" *ngIf="state == 'none'">
        <h4>No Annuities Available</h4>
      </div>
        <strong *ngIf="state == 'error'">Error Loading Rates</strong>
          <strong *ngIf="updating">Active Rates</strong>
          <table *ngIf="updating">
            <thead>
              <th>id</th>
              <th>CarrierCode</th>
              <th>CarrierName</th>
              <th>ProductCode</th>
              <th>VersionDate</th>
              <th>DeclaredRateCap</th>
              <th>Spread</th>
              <th>Modifier</th>
              <th>ParticipationRate</th>
              <th>IndexAllocation</th>
              <th>PerformanceTriggerRate</th>
              <th>Annuity Term</th>
              <th>Multiplier</th>
              <th>PremiumMin</th>
              <th>PremiumMax</th>
              <th>EffectiveDate</th>
              <th>DeprecateDate</th>
            </thead>
            <tbody>
              <tr *ngFor="let a of updating;" [className]="GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])">
                <td>{{a.id}}</td>
                <td>{{a.CarrierCode}}</td>
                <td>{{a['Carrier Name']}}</td>
                <td>{{a['Product Code']}}</td>
                <td>{{a['Version Date']}}</td>
                <td>{{a['Declared Rate Cap']}}</td>
                <td>{{a.Spread}}</td>
                <td>{{a.Modifier}}</td>
                <td>{{a['Participation Rate']}}</td>
                <td>{{a['Index Allocation']}}</td>
                <td>{{a['Performance Trigger Rate']}}</td>
                <td>{{a['Annuity Term']}}</td>
                <td>{{a.Multiplier}}</td>
                <td>{{a['Premium Minimum']}}</td>
                <td>{{a['Premium Maximum']}}</td>
                <td>{{a['Effective Date']}}</td>
                <td>{{a['Deprecate Date']}}</td>
              </tr>
            </tbody>
            </table>
    </mat-card-content>
  </mat-card>