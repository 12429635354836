import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';

@Injectable()
export class PostTradeService {
  private environment;
  public baseUrl: string;
  public f5500BaseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private rbs: RightBridgeApiService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.f5500BaseUrl = this.environment.f5500Base;
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.org = this.environment.org || 'basev2';
  }

  getPostGridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/TradeGridSetup?firmid=a`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPostTradeGridData(queryData?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = this.baseUrl + `/PostTrade/TradeGrid`;

    if (queryData) {
      url = url + `?query=${queryData}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPostTradeDetail(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/Detail?id=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  updateTrade(approveIdParams) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/UpdateTradeRecord`;

    return this.http.post(url, approveIdParams, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }
}
