<app-ag-print
  *ngIf="data.name?.toLowerCase() != 'pershing'"
  [profile]="data.profile"
  [customDefinition]="data.reportDef?.definition"
  (closeDialog)="closeModal($event)"
></app-ag-print>
<app-pershing-print
  *ngIf="data.name?.toLowerCase() === 'pershing'"
  [profile]="data.profile"
  [data]="data"
  [headline]="data.headline"
  [content]="data.content"
  [accountTypes]="data.accounts"
  (closeDialog)="closeModal($event)"
></app-pershing-print>
