import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AnnuityWizardService } from '../services/annuity-wizard-service.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { Location } from '@angular/common';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { CreateProfileDialogComponent } from '../create-profile-dialog/create-profile-dialog.component';
import { CalculateIncomeDialogComponent } from '../calculate-income-dialog/calculate-income-dialog.component';
import { ProductShelfCompareDialogComponent } from '../product-shelf-compare-dialog/product-shelf-compare-dialog.component';
import { EnvironmentService } from '../../services/environment.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-product-shelf',
  templateUrl: './product-shelf.component.html',
  styleUrls: ['./product-shelf.component.scss'],
})
export class ProductShelfComponent implements OnInit, OnDestroy {
  @ViewChild('productGrid', { static: false }) productGrid;

  private environment;
  private baseUrl: string;
  private globals;

  rights;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
  };
  columnWidths = {
    carrierName: 200,
    productName: 200,
    deathRiderName: 250,
    riderName: 250,
    deathRiderType: 200,
  };
  gridColumns;
  ogData;
  ogHeaders;
  ogHidden = [];
  loading;
  public pageSize = 10;
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  externalFilters = [];
  ogExternalFilters = [];
  gridView;
  rateViews = ['fia', 'fa', 'rila'];
  unitSettings;
  compareList = [];
  compareChecked = {};
  resetFilters = false;
  hideGrid = false;
  loadedUserPrefs = false;
  filterData = {};
  paramsSubscription;
  viewOption;
  showFilterMessage = false;
  solveType = 'F';
  incomeCalcButtonLabel = 'Calculate Income';
  incomeCalculated = false;
  hideLaunchToAnnuityBtn: boolean;
  cusip: string;

  constructor(
    private aws: AnnuityWizardService,
    private rbs: RightBridgeApiService,
    private router: Router,
    private pltfrm: Platform,
    private dialog: MatDialog,
    private envSvc: EnvironmentService,
    private prefSrvc: UserPreferencesService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService,
    private params: ActivatedRoute,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl =
      this.environment.apiBase ||
      'https://dev.rightbridge.net/api/BaseV2/api/v3';

    this.globals = this.ss.get('globals');
    this.rights = this.ss.get('rights');
    this.paramsSubscription = this.params.params.subscribe(params => {
      this.viewOption = params.viewOption;
      if (
        (this.viewOption === 'fa' && !this.rights.includes('AnnuityGrid')) ||
        (this.viewOption === 'fia' && !this.rights.includes('FIAGrid')) ||
        (this.viewOption === 'rila' && !this.rights.includes('ShowVIAFunds'))
      ) {
        this.router.navigate(['/']);
      }
      this.cusip = params.cusip;
    });

    this.aws.getGroupProfile('UnitValuesAW').subscribe(x => {
      this.unitSettings = x;
      if (x.UnitProductShelf && x.UnitProductShelf.CalculateButtonLabel) {
        this.incomeCalcButtonLabel = x.UnitProductShelf.CalculateButtonLabel;
      }
      if (this.viewOption === 'fia') {
        this.gridView = 'fia';
      } else if (this.viewOption === 'fa') {
        this.gridView = 'fa';
      } else if (this.viewOption === 'rila') {
        this.gridView = 'rila';
      } else if (this.viewOption === 'product') {
        this.gridView = 'product';
        this.getGridData();
      } else if (this.viewOption === 'death') {
        this.gridView = 'death';
        this.getRiderData('death');
        this.router.navigate([`aw/product-shelf/death`]);
      } else if (
        x.UnitProductShelf?.DefaultIncomeView === 'Y' ||
        this.viewOption === 'calculate' ||
        this.viewOption === 'income'
      ) {
        this.gridView = 'income';
        this.getRiderData('income');
      } else {
        this.gridView = 'product';
        this.getGridData();
      }
    });

    this.hideLaunchToAnnuityBtn =
      this.globals &&
      this.globals.GlobalsGroup.UnitInfo.HideLaunchToAnnuityButton;
  }

  ngOnDestroy() {
    this.ss.remove('incomeCalc');

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getGridData() {
    this.loading = true;
    this.hideGrid = true;
    this.filter = { logic: 'and', filters: [] };
    this.state.filter = { logic: 'and', filters: [] };
    this.resetGridData();
    this.setEmptySubheads();
    this.externalFilters = [];

    this.aws.getProductGrid().subscribe(res => {
      if (res.results) {
        this.ogData = res.results.data;
        this.ogHeaders = res.results.headers;
        this.gridData.headers = res.results.headers;
        this.gridData.data = res.results.data;
        this.gridColumns = res.results.headers.filter(x => !x.subgroup);
      }
      this.buildFilterOptions();
      this.updateExternalFilterOptions(this.gridData.headers);
      this.setColumnVisiblity();
      this.loading = false;
      this.hideGrid = false;
    });
  }

  getRiderData(riderType, data?, filters?) {
    this.loading = true;
    this.hideGrid = true;
    this.updateFilters({ logic: 'and', filters: [] });
    this.filter = { logic: 'and', filters: [] };
    this.state.filter = { logic: 'and', filters: [] };
    this.resetGridData();
    this.setEmptySubheads();
    this.externalFilters = [];
    if (riderType === 'death') {
      this.aws.getDeathRiderGrid().subscribe(res => {
        this.ogData = res.results.data;
        this.ogHeaders = res.results.headers;
        this.gridData.headers = res.results.headers;
        this.gridColumns = res.results.headers.filter(x => !x.subgroup);
        this.gridData.data = res.results.data;
        if (filters) {
          this.updateFilters({ logic: 'and', filters: [filters] });
        }
        this.buildFilterOptions();
        this.updateExternalFilterOptions(this.gridData.headers);
        this.setColumnVisiblity();
        this.loading = false;
        this.hideGrid = false;
      });
    } else {
      this.aws.getIncomeRiderGrid(data).subscribe(res => {
        this.ogData = res.results.data;
        this.ogHeaders = res.results.headers;
        this.gridData.headers = res.results.headers;
        this.gridColumns = res.results.headers.filter(x => !x.subgroup);
        if (this.solveType === 'B') {
          this.gridData.headers.find(
            header => header.Label === 'Income'
          ).Label = 'Premium';
        }

        if (data) {
          if (this.solveType === 'B') {
            this.sort = [{ field: 'calculatedIncome', dir: 'asc' }];
            this.state.sort = this.sort;
            this.updateFilters({
              logic: 'and',
              filters: [
                { field: 'calculatedIncome', operator: 'neq', value: 0 },
              ],
            });
          } else {
            this.updateFilters({
              logic: 'and',
              filters: [
                { field: 'calculatedIncome', operator: 'neq', value: 0 },
              ],
            });
            this.sort = [{ field: 'calculatedIncome', dir: 'desc' }];
            this.state.sort = this.sort;
          }
        } else {
          this.gridData.data = res.results.data;
        }

        this.buildFilterOptions();
        this.updateExternalFilterOptions(this.gridData.headers);
        this.setColumnVisiblity();
        this.loading = false;
        this.hideGrid = false;
        const incomeCalcFilters = this.ss.get('incomeCalcFilter');

        if (filters && !incomeCalcFilters) {
          this.updateFilters({ logic: 'and', filters: [filters] });
        }

        if (incomeCalcFilters) {
          const newFilters = [...incomeCalcFilters.filters];
          newFilters.push(filters);
          this.updateFilters({ logic: 'and', filters: newFilters });
          this.ss.remove('incomeCalcFilter');
        }

        if (this.viewOption === 'calculate') {
          this.calculateIncome();
        }
      });
    }
  }

  buildDropdownFilterOptions(el) {
    let options;

    options = this.gridData.data.map(x => {
      return { value: x[el.DataField], display: x[el.DataField] };
    });
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = options.map(x => {
        let label = el.Validation.find(z => x.value == z.value);

        label = label ? label.label : x.value;

        return { value: x.value, display: label };
      });
    }
    return options;
  }

  setColumnVisiblity() {
    this.hidden = [];
    this.gridData.headers.forEach(x => {
      if (x.subgroup) {
        let tempGroup = [];
        if (this.gridData.subHead.has(x.subgroup)) {
          tempGroup = this.gridData.subHead.get(x.subgroup);
          tempGroup.push(x);
        } else {
          this.gridData.subHead.set(x.subgroup, []);
          tempGroup.push(x);
        }

        this.gridData.subHead.set(x.subgroup, tempGroup);
        this.gridData.headers = this.gridData.headers.filter(z => z != x);
      }

      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }

      if (Object.keys(this.columnWidths).includes(x.DataField)) {
        x.Width = this.columnWidths[x.DataField];
      } else if (!Object.keys(this.columnWidths).includes(x.DataField)) {
        x.Width = null;
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
        this.ogHidden.push(x.DataField);
      }
    });
    this.hidden.forEach(x => {
      this.hideColumn(x);
    });
  }

  setEmptySubheads() {
    this.gridData.subHead.clear();
  }

  resetGridData() {
    this.gridData.headers = [];
    this.gridData.data = [];
    this.setEmptySubheads();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.setStorage();
    }
  }

  setStorage() {
    this.ss.set('hiddenAWProductColumns', this.hidden);
  }

  compare() {
    const compareCriteria = this.gridData.headers.filter(
      x => !x.excludeCompare
    );
    const rows = compareCriteria.map(x => {
      return {
        var: x.DataField,
        label: x.Label,
        validation: x.Validation,
        format: x.Type,
      };
    });
    const dialogRef = this.dialog.open(ProductShelfCompareDialogComponent, {
      panelClass: 'product-compare-dialog',
      width: '80vw',
      maxHeight: '90vh',
      maxWidth: '80vw',
      data: { rows: rows, funds: this.compareList },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }

  compareListUpdate(ev, item) {
    ev.preventDefault();
    const indexExisting = this.compareList.findIndex(x => x === item);

    if (indexExisting > -1) {
      this.compareList = this.compareList.filter(x => {
        return x != item;
      });
      this.compareChecked[item.uniqueCode] = false;
    } else {
      this.compareList.push(item);
      this.compareChecked[item.uniqueCode] = true;
    }
  }

  changeView(ev) {
    if (ev.value === 'product') {
      this.getGridData();
      this.gridView = 'product';
      this.viewOption = 'product';
      this._location.go('/aw/product-shelf');
      // this.router.navigate([`aw/product-shelf/`]);
    } else if (!this.rateViews.includes(ev.value)) {
      this.gridView = ev.value;
      this.viewOption = ev.value;
      this.getRiderData(ev.value);
      this._location.go(`aw/product-shelf/${ev.value}`);
      // this.router.navigate([`aw/product-shelf/${ev.value}`]);
    } else if (this.rateViews.includes(ev.value)) {
      this.router.navigate([`aw/product-shelf/${ev.value}`]);
    }
  }

  dlFactsheet(data) {
    const fmtdDate = new Date(data.version);

    if (data.annuityType.toLowerCase() == 'fa') {
      this.loading = true;
      this.aws.getFaFactSheet(data.productCode).subscribe(x => {
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const filename = data.productName + '-factsheet.pdf';

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(x);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.loading = false;
        };
      });
    } else {
      this.loading = true;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

      this.rbs
        .getAnnuityFactSheet(
          data.contractCode,
          data.productCode,
          fmtdDate.toISOString().substring(0, 10)
        )
        .subscribe(x => {
          const filename = data.productName + '-factsheet.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(x);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.loading = false;
          };
        });
    }
  }

  createNewCase(data, unified) {
    const calc = this.ss.get('incomeCalc');
    const dialogRef = this.dialog.open(CreateProfileDialogComponent, {
      panelClass: 'compare-dialog',
      width: '70vw',
      maxHeight: '70vh',
      maxWidth: '70vw',
      data: {
        accountPref: this.unitSettings.UnitQuestions.AccountPref,
        annuity: data.productName,
        rider: data.riderName || data.deathRiderName,
        state: calc ? calc.AnnuityWizard.StateOfIssue : null,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.loading = true;

        let riderData = null;

        if (this.gridView == 'income') {
          riderData = `${data.carrierCode}_${data.riderCode}`;
        } else if (this.gridView == 'death') {
          riderData = `${data.carrierCode}_${data.deathRiderCode}`;
        }

        const newProfile = {
          'AnnuityWizard.StateOfIssue': result.state,
          'ClientEstimates.Age': calc ? calc.ClientEstimates.Age : null,
          'AnnuityWizard.AccountPref': result.acctType,
          'AnnuityWizard.Purpose': result.purpose,
          'AnnuityWizard.AgeNeeded': calc ? calc.AnnuityWizard.AgeNeeded : null,
          'AnnuityWizard.InitialPremium': calc
            ? calc.AnnuityWizard.InitialPremium
            : null,
          'AnnuityWizard.InitialPremiumAccumulation': null,
          'ClientPerson.Gender': calc ? calc.ClientPerson.Gender : null,
          'AnnuityWizard.JointProtection': calc
            ? calc.AnnuityWizard.JointProtection
            : null,
          'SpouseEstimates.Age': calc ? calc.SpouseEstimates.Age : null,
          'SpousePerson.Gender': calc ? calc.SpouseEstimates.Gender : null,
          'AnnuityWizard.CarrierGroup': data.carrierGroupId,
          'AnnuityWizard.CusipToValidate': data.productCode,
          'AnnuityWizard.AssessmentType': 2,
          'AnnuityWizard.RiderToValidate': riderData,
          'AnnuityWizard.PurchaseType': 1,
          RiskTolerance: 5,
          SolveType: 'F',
          QualifiedInitialPremium: 'N',
        };

        this.loading = false;
        this.rbs.newProfile(newProfile, false).subscribe(x => {
          if (unified) {
            this.router.navigate([`/case/aw/${x.results}/0`]);
          } else {
            this.router.navigate([`/aw/questionnaire/${x.results}`]);
          }
        });
      }
    });
  }

  calculateIncome() {
    const existingData = this.ss.get('incomeCalc');
    const dialogRef = this.dialog.open(CalculateIncomeDialogComponent, {
      panelClass: 'calculate-dialog',
      width: '50vw',
      maxHeight: '70vh',
      maxWidth: '70vw',
      data: existingData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.solveType = result.AnnuityWizard.SolveType;
        this.compareList = [];
        this.compareChecked = {};
        this.incomeCalculated = true;
        this.ss.set('incomeCalc', result);

        let filters;

        if (result.AnnuityWizard.JointProtection) {
          switch (result.AnnuityWizard.JointProtection) {
            case '1':
              filters = {
                logic: 'or',
                filters: [
                  { field: 'livesType', operator: 'eq', value: 'Single' },
                  {
                    field: 'livesType',
                    operator: 'eq',
                    value: 'Single or Joint',
                  },
                ],
              };
              break;
            case '2':
              filters = {
                logic: 'or',
                filters: [
                  { field: 'livesType', operator: 'eq', value: 'Joint' },
                  {
                    field: 'livesType',
                    operator: 'eq',
                    value: 'Single or Joint',
                  },
                ],
              };
              break;
          }
        }

        // Remove livesType if it exists
        this.filter.filters = this.filter.filters.filter(
          filter =>
            filter['field'] !== 'livesType' ||
            (filter['filters'] &&
              filter['filtes'].find(
                nestedFilter => nestedFilter['field'] !== 'livesType'
              ))
        );
        this.ss.set('incomeCalcFilter', this.filter);
        this.getRiderData('income', result, filters);

        if (this.viewOption === 'calculate') {
          this._location.go('/aw/product-shelf/income');
          this.viewOption = 'income';
        }
      }
    });
  }

  reset(refresh = true) {
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.group = [];
    this.compareList = [];
    this.compareChecked = {};
    this.skip = 0;
    this.buildFilterOptions();
    this.externalFilters = [];
    this.incomeCalculated = false;
    if (this.ogHeaders) {
      this.gridColumns = this.ogHeaders.filter(x => !x.subgroup);
    }
    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;
    if (refresh) {
      this.gridData.data = process(this.ogData, { filter: this.filter }).data;
    } else {
      if (this.viewOption === 'product') {
        this.gridView = 'product';
        this.getGridData();
      } else if (this.viewOption === 'death') {
        this.gridView = 'death';
        this.getRiderData('death');
        this.router.navigate([`aw/product-shelf/death`]);
      } else if (
        this.unitSettings.UnitProductShelf?.DefaultIncomeView === 'Y' ||
        this.viewOption === 'calculate' ||
        this.viewOption === 'income'
      ) {
        this.gridView = 'income';
        this.getRiderData('income');
        this.router.navigate([`aw/product-shelf/income`]);
      } else {
        this.gridView = 'product';
        this.getGridData();
      }
    }

    this.updateExternalFilterOptions(this.ogHeaders);
  }

  updateFilters(ev) {
    this.resetFilters = false;
    this.skip = 0;
    if (this.filter.filters && this.filter.filters.length > 0) {
      this.filter.filters = this.deDupObjectArray([
        ...this.filter.filters,
        ...ev.filters,
      ]);
      this.filter.filters = uniqBy(this.filter.filters, 'field');

      this.filterChange(this.filter);
    } else {
      this.filter = ev;
      this.filterChange(this.filter);
    }

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };
    if (
      this.gridData.data &&
      this.gridData.data.length > 0 &&
      this.productGrid
    ) {
      this.gridData.data.forEach((item, idx) => {
        this.productGrid.collapseRow(idx);
      });

      this.gridData.data = process(this.ogData, { filter: this.filter }).data;
    }

    if (ev.filters.findIndex(x => x.field === 'annuityType') >= 0) {
      this.showFilterMessage = true;
    } else {
      this.showFilterMessage = false;
    }

    if (this.productGrid) {
      this.productGrid.skip = 0;
      this.productGrid.pageChange.emit({ skip: 0, take: this.pageSize });
    }
  }

  formatFilter(formatFilters) {
    const newFilterObj = {};
    formatFilters.map(filter => {
      if (!newFilterObj[filter.field]) {
        newFilterObj[filter.field] = [filter];
      }
    });
  }

  findValue(val, list) {
    if (val || val === 0 || (!val && typeof val == 'boolean')) {
      const display = list.find(x => x.value == val)
        ? list.find(x => x.value == val).label
        : val;
      return display;
    }
    return null;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;

        if (
          this.externalFilters.findIndex(x => x.varName === el.DataField) === -1
        ) {
          this.externalFilters.push(entry);
        }

        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  showNotes(notesObj) {
    let notesExist = false;

    if (notesObj) {
      Object.keys(notesObj).forEach(x => {
        if (notesObj[x].length > 0) {
          notesExist = true;
        }
      });
    }

    return notesExist;
  }

  updateHidden(ev) {
    this.hidden = ev.value;
    this.setStorage();
  }

  clearSingleFilter(varName) {
    const updatedFilterOpts = [];
    this.ogData.map(dataObj => {
      if (dataObj[varName]) {
        updatedFilterOpts.push({
          value: dataObj[varName],
          display: dataObj[varName],
        });
      }
    });

    this.externalFilters.find(filter => filter.varName === varName).options =
      uniqBy(updatedFilterOpts, 'value');
  }

  externalColumnsUpdated(ev) {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  getUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });

    this.prefSrvc.getColumns().subscribe(x => {
      this.loadedUserPrefs = x.shelf && x.shelf.length > 0;
      const hidden = fullList.filter(y => !x.shelf.includes(y));
      this.updateHidden({ value: hidden });
    });
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ shelf: visible }).subscribe();
  }

  public filterValueChange(values, field, filterService: FilterService): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const passedFilterNames = [];
    const passedFilterDatas = {};
    // Extract passed filter data for trickle down column filter option narrowing
    filter.filters.map(filterObj => {
      if (filterObj['filters']) {
        const filterName = filterObj['filters'][0]['field'];
        passedFilterNames.push(filterName);
        passedFilterDatas[filterName] = this.filterData[filterName];
      }
    });

    this.gridData.data = filterBy(this.ogData, filter);
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      filterBy(this.ogData, filter),
      this.gridColumns
    );
    // Replace filter options with trickled down options
    passedFilterNames.map(filterName => {
      this.filterData[filterName] = passedFilterDatas[filterName];
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  originalOrder = (): number => {
    return 0;
  };

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  columnStyleCheck(dataField) {
    if (dataField === 'calculatedIncome') {
      return 'calculated income-column';
    }
    if (
      dataField === 'calculatedWithdrawalPercentage' ||
      dataField === 'calculatedBenefitBase'
    ) {
      return 'income-column';
    }
  }
}
