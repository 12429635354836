import { Component, Inject, OnInit } from '@angular/core';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-network-loading-modal',
  standalone: true,
  imports: [SharedComponentsModule],
  templateUrl: './network-loading-modal.component.html',
  styleUrl: './network-loading-modal.component.scss',
})
export class NetworkLoadingModalComponent implements OnInit {
  networkResponseReceived = false;
  headline: string;
  body: string;
  loadingMessages: string[];
  currentLoadingMessage: string;
  messageDelay: number;
  currentMessageIndex = 0;
  currentInterval: NodeJS.Timeout;
  constructor(
    public dialogRef: MatDialogRef<NetworkLoadingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.headline = data.headline || 'Processing Network Request';
    this.body =
      data.body ||
      'Please wait while we connect to this service.<br><br>Please know this can take some time. Thank you for your patience.';
    this.loadingMessages = data.loadingMessages || [
      'Connecting to service...',
      'Connected to service...',
      'Waiting for response...',
    ];
    this.messageDelay = data.messageDelay || 5;
  }
  ngOnInit() {
    this.currentLoadingMessage = this.loadingMessages[0];
    this.loopMessages();
  }

  loopMessages() {
    this.currentInterval = setInterval(
      () => this.nextMessage(),
      this.messageDelay * 1000
    );
  }

  nextMessage() {
    if (
      // network done.
      // set message to last message.
      this.networkResponseReceived
    ) {
      clearInterval(this.currentInterval);
      this.currentLoadingMessage = 'Response received.';
    } else if (
      // out of messages. stop on last one.
      // wait for network if needed.
      this.currentMessageIndex ===
      this.loadingMessages.length - 1
    ) {
      clearInterval(this.currentInterval);
    } else if (
      // normal. not out of messages.
      this.currentMessageIndex <
      this.loadingMessages.length - 1
    ) {
      this.currentMessageIndex += 1;
      this.currentLoadingMessage =
        this.loadingMessages[this.currentMessageIndex];
    }
  }
}
