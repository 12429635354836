import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { AppGlobalsService } from '../services/app-globals.service';
import { PlanLookupComponent } from './plan-lookup/plan-lookup.component';

const routes: Routes = [
  {
    path: 'pp',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: 'plan-lookup',
        component: PlanLookupComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'PP',
        },
      },
      // THE FOLLOWING ARE OLD ROUTES THAT HAVE BEEN
      // REDIRECTED TO THE LANDING PAGE
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'search',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'results/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'help',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'proxy',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PpRouting {}
