import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  UntypedFormGroup,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss'],
})
export class CopyDialogComponent implements OnInit {
  form: FormGroup;
  type = 'model';
  headline: string;
  content: string;
  confirm: string;
  placeholder: string;
  selectLaunch = false;
  redirects = [];
  required = Validators.required;

  constructor(
    public dialogRef: MatDialogRef<CopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.headline = this.data.headline || 'Copy Profile';
      if (this.data.rights && this.data.item) {
        if (
          this.data.rights.includes('PP') &&
          (this.data.item['ppStatus'] == 'C' ||
            this.data.item['ppStatus'] == 'O')
        ) {
          this.redirects.push({ label: 'Product Profiler', value: 'pp' });
        }
        if (
          this.data.rights.includes('AW') &&
          (this.data.item['awStatus'] == 'C' ||
            this.data.item['awStatus'] == 'O')
        ) {
          this.redirects.push({ label: 'Annuity Wizard', value: 'aw' });
        }
        if (
          this.data.rights.includes('IW') &&
          (this.data.item['iwStatus'] == 'C' ||
            this.data.item['iwStatus'] == 'O')
        ) {
          this.redirects.push({ label: 'Investment Wizard', value: 'iw' });
        }
        if (
          this.data.rights.includes('LI') &&
          (this.data.item['lwStatus'] == 'C' ||
            this.data.item['lwStatus'] == 'O')
        ) {
          this.redirects.push({ label: 'Life Insurance Wizard', value: 'li' });
        }
      }
    }

    const formGroup = {};
    formGroup['reason'] = new FormControl('', this.required);
    if (this.redirects.length > 0) {
      this.selectLaunch = true;
      formGroup['launchto'] = new FormControl(
        this.redirects[0]['value'],
        this.required
      );
    }
    this.form = new UntypedFormGroup(formGroup);
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if (this.form.valid) {
      const result = { data: this.form.get('reason').value };
      if (this.selectLaunch) {
        result['app'] = this.form.get('launchto').value;
      }
      this.dialogRef.close(result);
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }
}
