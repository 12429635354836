<mat-card appearance="outlined" class="col-12 stretch">
    <mat-card-header>
      PPFAs
      <button mat-button (click)='load()' class="header-button download-link"><mat-icon>cloud_download</mat-icon></button>
    </mat-card-header>
    <mat-card-content>
      <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
      <span *ngIf="state=='loaded' || state=='none'">
      <div mat-dialog-content class="dialog-container">
        <div class='row'>
          <mat-form-field class='col-2'>
            <mat-label>Rows</mat-label>
            <select matNativeControl (change)='updateRowCount($event.target.value)'>
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </mat-form-field>
          <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()' class="header-button download-link"><mat-icon>save</mat-icon></button>
        </div>
      </div>
    </span>
      <div class="no-results" *ngIf="state == 'none'">
        <h4>No Annuities Available</h4>
      </div>
        <strong *ngIf="state == 'error'">Error Loading Rates</strong>
        <strong *ngIf="expiring">Expiring Annuity Rates</strong>
        <table *ngIf="expiring">
          <thead>
            <th>Carrier</th>
            <th>Contract</th>
            <th>Carrier Code</th>
            <th>Cusip</th>
            <th>Contract Code</th>
            <th>Version Date</th>
            <th>Active Date</th>
            <th>Deprecate Date</th>
          </thead>
          <tbody>
            <tr *ngFor="let a of expiring;" [className]="GetClass(a.RateEffectiveDate, a.RateDeprecateDate)">
              <td>{{a.Carrier}}</td>
              <td>{{a.Contract}}</td>
              <td>{{a.CarrierCode}}</td>
              <td>{{a.Cusip}}</td>
              <td>{{a.ContractCode}}</td>
              <td>{{a.VersionDate}}</td>
              <td>{{a.ActiveDate}}</td>
              <td>{{a.DeprecateDate}}</td>
            </tr>
          </tbody>
          </table>
          <strong *ngIf="updating">Updating Annuity Rates</strong>
          <table *ngIf="updating">
            <thead>
              <th>Carrier</th>
              <th>Contract</th>
              <th>Carrier Code</th>
              <th>Cusip</th>
              <th>Contract Code</th>
              <th>Version Date</th>
              <th>Active Date</th>
              <th>Deprecate Date</th>
            </thead>
            <tbody>
              <tr *ngFor="let a of updating;" [className]="GetClass(a.RateEffectiveDate, a.RateDeprecateDate)">
                <td>{{a.Carrier}}</td>
                <td>{{a.Contract}}</td>
                <td>{{a.CarrierCode}}</td>
                <td>{{a.Cusip}}</td>
                <td>{{a.ContractCode}}</td>
                <td>{{a.VersionDate}}</td>
                <td>{{a.ActiveDate}}</td>
                <td>{{a.DeprecateDate}}</td>
              </tr>
            </tbody>
            </table>
    </mat-card-content>
  </mat-card>