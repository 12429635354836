import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-reason-text',
  templateUrl: './reason-text.component.html',
  styleUrls: ['./reason-text.component.scss'],
})
export class ReasonTextComponent implements OnInit {
  @Input() data;
  @Input() selected;

  private environment;
  reasons = [];
  viewMode = 'grid';
  path: string;
  org: string;
  yellowNegative = false;

  constructor(
    private envSvc: EnvironmentService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.org = this.environment.org || 'basev2';
    this.path = this.environment.assets ? this.environment.assets : '';

    if (this.data) {
      this.reasons = [...this.data];
    }

    const globals = this.ss.get('globals');

    if (globals && globals.GlobalsGroup.UnitReasonTextSettings) {
      this.yellowNegative =
        globals.GlobalsGroup.UnitReasonTextSettings.TurnNegativeReasonTextYellow;
    }

    if (this.yellowNegative) {
      this.path = 'mass/';
    }
  }

  isOdd(num) {
    return num % 2;
  }
}
