import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  switchMap,
  distinctUntilChanged,
  map,
  startWith,
  debounceTime,
  tap,
  finalize,
} from 'rxjs/operators';
import { Form, FormControl } from '@angular/forms';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { ActivatedRoute } from '@angular/router';
import { FundLookupDialogComponent } from '../fund-lookup-dialog/fund-lookup-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {
  private environment;
  private baseUrl: string;

  loading = false;
  debug = false;
  programControl = new FormControl();
  fundControl = new FormControl({ value: null, disabled: true });
  alternateControl = new FormControl();
  horizonControl = new FormControl();
  amountControl = new FormControl();
  ownedControl = new FormControl();
  selected = { fund: null, fundName: null, program: null };
  filteredPrograms;
  filteredFunds;
  programList;
  familyList;
  fundsDisabled = true;
  filteredFamily;
  dollarMask = this.mask.dollarMaskSpecs();
  dropdownLoading = false;
  dropdownNoResults = false;

  constructor(
    private route: ActivatedRoute,
    private iws: InvestmentWizardService,
    private dialog: MatDialog,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private mask: MaskService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.iws.programSearch().subscribe(x => {
      this.programList = x.results;
      // this.searchPrograms();
      // this.searchFund();
    });

    this.iws.fundFamilyList().subscribe(x => {
      this.familyList = x.results;
    });
  }

  searchPrograms() {
    this.filteredPrograms = this.programControl.valueChanges.pipe(
      startWith(''),
      map(program => {
        if (typeof program === 'string') {
          return this._filterPrograms(program);
        }
      })
    );
  }

  searchFund() {
    this.fundControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.dropdownNoResults = false;
          this.dropdownLoading = true;
          this.changeDetector.detectChanges();
        }),
        distinctUntilChanged(),
        switchMap((fund: string) => {
          if (fund) {
            return this._filterFunds(fund).pipe(
              finalize(() => {
                this.dropdownLoading = false;
                this.changeDetector.detectChanges();
              })
            );
          } else if (!fund) {
            this.dropdownLoading = false;
            this.filteredFunds = null;
            this.changeDetector.detectChanges();
          }
          return [];
        })
      )
      .subscribe(results => {
        if (!results) {
          this.dropdownNoResults = true;
        } else {
          this.filteredFunds = results;
          this.dropdownNoResults = false;
        }
        this.changeDetector.detectChanges();
      });
  }

  getFundInfo() {
    const dialogRef = this.dialog.open(FundLookupDialogComponent, {
      panelClass: 'fund-lookup-dialog',
      data: { fund: this.fundControl.value, mode: 'info' },
    });
    this.loading = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
      }
      this.loading = false;
    });
  }

  getPdf() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    let fams = '';

    if (this.ownedControl.value) {
      fams = this.ownedControl.value.map((x, i) => {
        return i > 0 ? `,${x.ID}` : `${x.ID}`;
      });
    }

    this.iws
      .getFundCompareReport(
        this.fundControl.value.FundID,
        this.programControl.value.ProgramID,
        this.horizonControl.value,
        this.mask.removeMoneyMask(this.amountControl.value),
        this.alternateControl.value,
        fams
      )
      .subscribe(data => {
        const filename = `${this.fundControl.value.FundName}-${this.fundControl.value.FundID}-FundComparison.pdf`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.loading = false;
        };
      });
  }

  allowReport() {
    const enable =
      this.fundControl.value &&
      this.fundControl.value.FundID &&
      this.programControl.value &&
      this.amountControl.value &&
      this.horizonControl.value;
    return !enable;
  }

  displayProg(program): string | undefined {
    return program ? program.DisplayName : undefined;
  }

  displayFund(fund): string | undefined {
    return fund ? `${fund.FundID} - ${fund.FundName}` : undefined;
  }

  private _filterFunds(value: string) {
    const prog = this.programControl.value.ProgramID;
    const families = this.ownedControl.value
      ? this.ownedControl.value.map(x => x.ID.toString())
      : null;
    const family = [...new Set(families)];
    const list = this.iws.fundSearch(value, prog, family).pipe(
      map(x => {
        if (x.results && x.results.length > 0) {
          return x.results;
        }
      })
    );
    return list;
  }

  private _filterPrograms(value): any[] {
    if (value.length < 1) {
      return this.programList;
    }
    return this.programList.filter(x => {
      return x.DisplayName.toLowerCase().includes(value.toLowerCase());
    });
  }

  updateFunds(ev, clearAction?) {
    if (clearAction && clearAction == 'all') {
      this.clearAllData();
    } else if (clearAction && clearAction == 'product') {
      this.clearProductData();
    }

    if (this.programControl.value && this.programControl.value.ProgramID) {
      this.fundControl.enable();
      this.searchFund();
    }
  }

  clearProductData() {
    this.fundControl.setValue(null);
    this.fundControl.disable();
  }

  clearAllData() {
    this.fundControl.setValue(null);
    this.fundControl.disable();
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  alphabetizeGroups(a, b) {
    const groupA = a.display.toUpperCase();
    const groupB = b.display.toUpperCase();

    let comparison = 0;
    if (groupA > groupB) {
      comparison = 1;
    } else if (groupA < groupB) {
      comparison = -1;
    }
    return comparison;
  }

  clearInvestment() {
    this.fundControl.setValue(null);
  }
}
