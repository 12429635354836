import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tech-notes-modal',
  templateUrl: './tech-notes-modal.component.html',
  styleUrls: ['./tech-notes-modal.component.scss']
})
export class TechNotesModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<TechNotesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {}

  close() {
    this.dialogRef.close({ data: 'cancel' });
  }
}
