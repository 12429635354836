import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import {
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
  CdkDragDrop,
} from '@angular/cdk/drag-drop';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-form-rank-order',
  templateUrl: './form-rankOrder.component.html',
  styleUrls: ['form-rankOrder.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormlyRankOrderComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  constructor(
    public cdkDropList: CdkDropList,
    public cdkDrag: CdkDrag
  ) {
    super();
  }

  get orderedListControl(): FormArray {
    return this.form.get('PurposeRankOrder_OrderedSelection') as FormArray;
  }

  unsubscribe = new Subject();
  unordered;
  ordered = [];
  fieldKey;

  ngOnInit() {
    this.unordered = this.props.options;
    if (this.model.PurposeRankOrder_OrderedSelection
      && this.props.options) {
      this.model.PurposeRankOrder_OrderedSelection
      .map(orderObjVal => {
        const foundObjIndex = this.unordered.findIndex(
          orderObj => orderObj && orderObjVal === orderObj.value
        );
        if (foundObjIndex >= 0) {
          this.ordered.push(this.unordered[foundObjIndex]);
          this.unordered.splice(foundObjIndex, 1);
        }
      });
    }
    this.fieldKey = this.field.key;
    this.updateFormControl(false);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.updateFormControl(true);
  }

  addItem(item) {
    const index = this.unordered.findIndex(
      listItem => listItem.value === item.value
    );
    this.unordered.splice(index, 1);
    this.ordered.push(item);
    this.updateFormControl(true);
  }

  removeItem(item) {
    const index = this.ordered.findIndex(
      listItem => listItem.value === item.value
    );
    this.ordered.splice(index, 1);
    this.unordered.push(item);
    this.updateFormControl(true);
  }

  updateFormControl(markAsTouched = false) {
    this.formControl.setValue([...this.ordered]);
    this.form.value[this.fieldKey] = [...this.ordered];
    this.field.parent.model[this.fieldKey] = [...this.ordered];
    if (markAsTouched) {
      this.formControl.markAsTouched();
    }
    if (this.ordered.length > 0) {
      this.formControl.setErrors(null);
    } else {
      this.formControl.setErrors({ rankOrderRequired: true });
      this.form.setErrors({ rankOrderRequired: true });
    }
    this.formControl.updateValueAndValidity()
  }
}
