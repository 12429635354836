import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'warn-dialog',
  templateUrl: './warn-dialog.component.html',
  styleUrls: ['./warn-dialog.component.scss']
})
export class WarnDialogComponent implements OnInit {
  headline: string;
  content: string;
  confirm: string;
  hideCancel: boolean = false;

  constructor(public dialogRef: MatDialogRef<WarnDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data);
    this.headline = data.headline || 'Warning';
    this.content = data.content;
    this.confirm = data.confirm || 'Save';
    this.hideCancel = data.hideCancel || false;
  }

  ngOnInit() {
  }

}
