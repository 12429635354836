import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-life-event-dialog',
  templateUrl: './life-event-dialog.component.html',
  styleUrls: ['./life-event-dialog.component.scss']
})
export class LifeEventComponent implements OnInit {
  lifeEvent = { type: null, date: new Date(), note: ''};

  constructor(public dialogRef: MatDialogRef<LifeEventComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() { }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    // this.ses.takeDailyAction(this.data.id, this.action.type, this.data.bridge).subscribe(x => { });
    // if(this.form.valid) {
      // this.lifeEvent.date = this.lifeEvent.date.toLocaleDateString("en-US")
      this.dialogRef.close({ data: this.lifeEvent });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    // }
  }

}
