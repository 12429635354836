import { Component, OnInit } from '@angular/core';
import { LifeWizardService } from '../../../life-wizard/life-wizard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lifeStats',
  templateUrl: './lifeStats.component.html',
  styleUrls: ['./lifeStats.component.scss']
})
export class LifeStatsComponent implements OnInit {
  state: string = 'loaded';
  data;

  constructor(private lws: LifeWizardService, private router: Router) { }

  ngOnInit() {
    this.lws.getLifeStats().subscribe(x => {
      this.data = x;
    });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }
}
