<div class="page-container unified">
  <div class="login-content-container">
    <div class="login-container col-12 col-md-6 col-lg-5">
      <div class="logo-container mb-5">
        <img
          class="col-8 col-md-6 col-lg-4"
          src="{{ './assets/rb-blue-logo.png' }}"
          alt="RightBridge"
        />
      </div>

      <div class="login-component-container">
        <div class="text-container mb-5">
          <h2>Welcome back!</h2>
          <p>Log in to your account</p>
        </div>

        <div class="login-form-container">
          <form>
            <mat-form-field floatPlaceholder="auto" class="col-12">
              <mat-label>Username</mat-label>
              <input
                matInput
                [placeholder]="'Username'"
                [type]="'text'"
                name="'username'"
                [formControl]="username"
                required
              />
              <mat-error>A username is required</mat-error>
            </mat-form-field>
            <mat-form-field floatPlaceholder="auto" class="col-12">
              <mat-label>Password</mat-label>
              <input
                matInput
                [placeholder]="'Password'"
                [type]="'password'"
                name="'password'"
                [formControl]="password"
                required
              />
              <mat-error>A password is required</mat-error>
            </mat-form-field>
            <div class="login-button-container col-12 mb-2">
              <button
                class="col-12"
                mat-raised-button
                [type]="'submit'"
                color="primary"
                (click)="login()"
                id="login-button"
              >
                Log In<mat-icon>input</mat-icon>
              </button>
            </div>
          </form>
        </div>
        @if (failed) {
          <div class="failed">
            @if (loginError === 'Invalid') {
              <p class="col-12 warning-text">
                Sorry, it looks like either your username or password are
                incorrect. Please double check them and try again.
              </p>
            } @else if (loginError === 'Locked') {
              <p class="col-12 warning-text">
                Your account is locked due to multiple failed log in attempts.
                Please contact your technical support for assistance.
              </p>
            } @else if (
              loginError === 'Bad request' || loginError === 'Unavailable'
            ) {
              <p class="col-12 warning-text">
                The server appears to be having an issue. Our team is on top of
                it! Please try again later.
              </p>
            }
          </div>
        }
        <ngx-loading
          [show]="loading"
          [config]="{ fullScreenBackdrop: false }"
        ></ngx-loading>
      </div>
    </div>

    <div class="login-decoration-container col-12 col-md-6 col-lg-7">
      <div class="content">
        <img
          id="Polygon-1"
          src="{{ './assets/rightbridge-logo.png' }}"
          class="unified-logo"
        />
        <mat-icon
          id="Polygon-2"
          class="app-button-icon"
          svgIcon="aw_icon"
        ></mat-icon>
        <mat-icon
          id="Polygon-3"
          class="app-button-icon"
          svgIcon="pp_icon"
        ></mat-icon>
        <mat-icon
          id="Polygon-4"
          class="app-button-icon"
          svgIcon="iw_icon"
        ></mat-icon>
        <mat-icon
          id="Polygon-5"
          class="app-button-icon"
          svgIcon="lw_icon"
        ></mat-icon>
        <mat-icon
          id="Polygon-6"
          class="app-button-icon"
          svgIcon="cm_icon"
        ></mat-icon>

        <svg
          viewBox="0 0 837 1045"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <path
              d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
              fill="#fff"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
              fill="#7c9cc0"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
              fill="#939597"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
              fill="#e6e7e8"
              sketch:type="MSShapeGroup"
            ></path>
            <path
              d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
              fill="#939597"
              sketch:type="MSShapeGroup"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
