import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { AppLinksComponent } from './widgets/app-links/app-links.component';
import { LegacyAppLinksComponent } from './widgets/legacy-app-links/legacy-app-links.component';
import { CaseListComponent } from './widgets/case-list/case-list.component';

import { DashboardComponent } from './dashboard.component';
import { Top5Component } from './widgets/top5/top5.component';
import { TopOppsComponent } from './widgets/topOpps/topOpps.component';
import { TimeSensitiveComponent } from './widgets/timeSensitive/timeSensitive.component';
import { FollowUpComponent } from './widgets/followUp/followUp.component';
import { MessagesComponent } from './widgets/messages/messages.component';
import { ScorecardComponent } from './widgets/scorecard/scorecard.component';
import { TopBridgesComponent } from './widgets/topBridges/topBridges.component';
import { UploaderComponent } from './widgets/uploader/uploader.component';
import { LifeStatsComponent } from './widgets/lifeStats/lifeStats.component';
import { UnitProductStatsComponent } from './widgets/unitProductStats/unitProductStats.component';
import { HighActivityStatsComponent } from './widgets/highActivityStats/highActivityStats.component';
import { FundComparisonComponent } from './widgets/fund-comparison/fund-comparison.component';
import { CaseStatsComponent } from './widgets/caseStats/caseStats.component';
import { CaseStatsActivityComponent } from './widgets/caseStatsActivity/caseStatsActivity.component';
import { SummaryChartsComponent } from './widgets/summaryCharts/summaryCharts.component';
import { CasesByScoreclassComponent } from './widgets/casesByScoreclass/casesByScoreclass.component';
import { Top5SummaryComponent } from './widgets/top5Summary/top5Summary.component';
import { SystemUsageSummaryComponent } from './widgets/systemUsageSummary/systemUsageSummary.component';

import { DashboardService } from './dashboard.service';

import { DashRouting } from './dashboard-routing.module';
import { AnnuitiesComponent } from './widgets/annuities/annuities.component';
import { FiaRatesComponent } from './widgets/fia-rates/fia-rates.component';
import { ViaRatesComponent } from './widgets/via-rates/via-rates.component';
import { LivingBenefitsComponent } from './widgets/living-benefits/living-benefits.component';
import { DeathBenefitsComponent } from './widgets/death-benefits/death-benefits.component';
import { PpfaComponent } from './widgets/ppfa/ppfa.component';
import { ActivePPfaComponent } from './widgets/activeppfa/activeppfa.component';
import { FiaActiveRatesComponent } from './widgets/fiaactiverates/fiaactiverates.component';
import { SystemUsageComponent } from './widgets/systemUsage/systemUsage.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AnnuitiesByConnectorComponent } from './widgets/annuities-by-connector/annuities-by-connector.component';
import { ViaActiveRatesComponent } from './widgets/via-active-rates/via-active-rates.component';
import { FixedAnnuitiesActiveRatesComponent } from './widgets/fixed-annuities-active-rates/fixed-annuities-active-rates.component';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    DashRouting,
    HighchartsChartModule
  ],
  declarations: [
    AppLinksComponent,
    LegacyAppLinksComponent,
    CaseListComponent,
    DashboardComponent,
    Top5Component,
    TopOppsComponent,
    TimeSensitiveComponent,
    FollowUpComponent,
    MessagesComponent,
    ScorecardComponent,
    TopBridgesComponent,
    UploaderComponent,
    LifeStatsComponent,
    UnitProductStatsComponent,
    HighActivityStatsComponent,
    AnnuitiesComponent,
    FiaRatesComponent,
    ViaRatesComponent,
    LivingBenefitsComponent,
    DeathBenefitsComponent,
    AnnuitiesByConnectorComponent,
    FixedAnnuitiesActiveRatesComponent,
    ViaActiveRatesComponent,
    PpfaComponent,
    FundComparisonComponent,
    CaseStatsComponent,
    CaseStatsActivityComponent,
    ActivePPfaComponent,
    FiaActiveRatesComponent,
    SystemUsageComponent,
    SummaryChartsComponent,
    CasesByScoreclassComponent,
    Top5SummaryComponent,
    SystemUsageSummaryComponent
  ]
})
export class DashboardModule { }
