import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-multiSelect',
  styleUrls: ['form-multiselect.component.scss'],
  template: `
    <mat-form-field class="col-12 p-0"   >
      <mat-label>{{to.label}}</mat-label>
      <mat-select [formControl]="formControl" [placeholder]="props.placeholder" [formlyAttributes]="field" multiple class="w-100" [required]="props.required">
          <mat-option *ngFor="let opt of to.options" [value]="opt.value">{{opt.label}}</mat-option>
      </mat-select>
      <mat-icon *ngIf="props.help" class="help-icon" matTooltip="{{to.help}}" [matTooltipPosition]="'above'">help</mat-icon>
      <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
    </mat-form-field>
  `
})
export class FormlyMultiSelect extends FieldType { }
    // <label class="section col-12" *ngIf="props.label" [innerHTML]="props.label"></label>
    // <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>


// @Component({
//   selector: 'form-multiselect',
//   styleUrls: ['form-multiselect.component.scss'],
//   template: `
//     <div class="dynamic-field form-multiselect" [formGroup]="group">
//     {{vals}}
//       <mat-form-field class="col-12">
//         <mat-select [id]="config.id" [formControlName]="config.varName" [placeholder]="config.placeholder || config.label" multiple [name]="config.varName" [value]="config.rawValue">
//           <mat-option *ngFor="let opt of config.options" [value]="opt.value">{{opt.label}}</mat-option>
//         </mat-select>
//       </mat-form-field>
//     </div>
//   `
// })
// export class FormMultiSelectComponent implements Field {
//   @HostBinding('class') classes = 'col-sm-12 col-md-6 question-container';

//   config;
//   group: FormGroup;
//   vals: any[] = [];

//   ngOnInit() {
//     // this.config.rawValue.array.forEach(x => {
//     //   this.vals.push({value: x});
//     // });
//   }
// }
