import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { states } from '../../../lib/states';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UniversalSearchService } from '../../../universal-search/services/universal-search.service';

@Component({
  selector: 'app-grid-filter-fields',
  templateUrl: './grid-filter-fields.component.html',
})
export class GridFilterFieldsComponent implements OnInit {
  @Input() field;
  @Input() filterForm;
  @Input() groupKey;
  @Output() textChange = new EventEmitter();
  @Output() dateChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  states = states;

  constructor(public usServ: UniversalSearchService) {}

  ngOnInit(): void {
    if (
      this.field.controlType === 'repCode' ||
      this.field.controlType === 'unit' ||
      this.field.controlType === 'user'
    ) {
      this.getFilteredOptions(this.field.varName);
    }
  }

  changeText(ev) {
    this.textChange.emit(ev);
  }

  changeSelection(ev) {
    this.selectionChange.emit(ev);
  }

  changeDate(ev) {
    this.dateChange.emit(ev);
  }

  resetFilter(ev) {
    this.clearFilter.emit(ev);
  }

  getFilteredOptions(varName) {
    this.filterForm.controls[this.groupKey].controls[varName].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (this.field.controlType === 'repCode') {
          this.getRepCodeList(value);
        }
        if (this.field.controlType === 'unit') {
          this.getUnitList(value);
        }
        if (this.field.controlType === 'user') {
          this.getUserList(value);
        }
      });
  }

  getRepCodeList(searchString?: string) {
    this.usServ.repCodeSearch(searchString).subscribe(resp => {
      const newUsersList = resp.names.map(rep => ({
        value: rep.repCode,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newUsersList;
    });
  }

  getUnitList(searchString?: string) {
    this.usServ.unitSearch(searchString).subscribe(resp => {
      const newUsersList = resp.names.map(rep => ({
        value: rep.id,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newUsersList;
    });
  }

  getUserList(searchString?: string) {
    this.usServ.userSearch(searchString).subscribe(resp => {
      const newUsersList = resp.names.map(rep => ({
        value: rep.UserID,
        display: rep.UserName,
      }));
      this.field.fullField.externalFilter['options'] = newUsersList;
    });
  }

  getDropdownOptions() {
    return this.field.fullField.externalFilter['options'] || [];
  }

  showClearButton(fieldName) {
    if (this.filterForm.controls[this.groupKey].controls[fieldName].value) {
      return true;
    }
    return false;
  }
}
