import { Component, OnInit, Input } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input() message;
  state: string = 'loaded';

  constructor(private ses: SuggestionEngineService) { }

  ngOnInit() { }

}
