<div class="grid-component">
  @if (props.label) {
  <label [innerHTML]="props.label"></label>
  }
  <div class="toolbar clearfix w-100">
    @if (showTemplates) {
    <mat-form-field class="col-9">
      <mat-label>{{ label }}</mat-label>
      <mat-select [placeholder]="placeholder" (selectionChange)="applyTemplate($event)" [disabled]="disableTemplates">
        @if (firmTemplates && firmTemplates.length > 0) {
        <mat-optgroup label="Firm Templates">
          @for (tmplt of firmTemplates; track tmplt.id) {
          <mat-option [value]="tmplt">
            {{ tmplt.name }}
          </mat-option>
          }
        </mat-optgroup>
        }
        @if (templates && templates.length > 0) {
        <mat-optgroup label="User Templates">
          @for (tmplt of templates; track tmplt.id) {
          <mat-option [value]="tmplt">
            {{ tmplt.name }}
          </mat-option>
          }
        </mat-optgroup>
        }
      </mat-select>
      <mat-hint></mat-hint>
    </mat-form-field>
    }

    @if (fiveTwoNineSearch) {
    <app-five-two-nine-picker [form]="form" [program]="
          field.parent.parent.parent.model.ValidateProduct
            ?.InvestmentValidation_Program
        " [existingPlan]="field.parent.model?.Selected529PlansArray_PlanID" (selectedPlan)="handle529($event)"
      [clear]="clear529()" (cleared)="clear529.set(false)"></app-five-two-nine-picker>
    }

    @if (searchOptions) {
    <app-fund-search #fundSearch [type]="props.searchType" [options]="searchOptions" [program]="program"
      [fiveTwoNinePlan]="selected529?.PlanId" [search529]="fiveTwoNineSearch" (fundSelected)="handleFund($event)"
      class="search-box mb-3"></app-fund-search>
    @if (buttonOption && this.field['showButton']) {
    <button mat-button class="btn btn-primary pull-right" color="primary" type="button" (click)="add()">
      <mat-icon class="mr-2">add_circle</mat-icon>{{ buttonOption }}
    </button>
    }
    }

    @if (!searchOptions && this.field['showButton'] && !hideDelete) {
    <button mat-button class="btn btn-primary pull-right" color="primary" type="button" (click)="add()">
      <mat-icon class="mr-2">add_circle</mat-icon>Add
    </button>
    }
  </div>
  @if (this.field['showCsvFunctions']) {
  <div>
    <span class="pull-right">
      <button mat-button class="btn btn-primary pull-right" color="primary" (click)="downloadCsvTemplate($event)">
        <mat-icon>download</mat-icon>Download CSV Template
      </button>
    </span>
    <span class="pull-right">
      <input type="file" class="file-input" (change)="onFileSelected($event)" #csvUpload />

      <span class="file-upload">
        @if (fileName) {
        <div>
          {{ fileName }}
        </div>
        <button mat-stroked-button class="pull-right ml-2 mb-2" (click)="csvUpload.click()">
          <mat-icon>upload</mat-icon>Choose a different file
        </button>
        <button mat-raised-button class="pull-right mb-2" color="accent" (click)="completeCsvUpload()">
          <mat-icon class="mr-2">check_circle_outline</mat-icon>Complete
          upload
        </button>
        } @else {
        <button mat-button class="btn btn-primary pull-right" color="primary" (click)="csvUpload.click()">
          <mat-icon class="mr-2">upload</mat-icon>Upload CSV/Excel
        </button>
        }
      </span>
    </span>
  </div>
  }

  @if (field.key === 'SelectedFunds') {
  <h5 class="w-100 pull-right">
    Investment Program: {{ investmentProgramLabel }}
  </h5>
  }

  @if (!loading) {
  <kendo-grid class="col-12" #grid [kendoGridBinding]="model" [selectable]="{ enabled: false }" [navigable]="true">
    @for (column of columns; track column.key) {
    <kendo-grid-column [field]="column.key" [editable]="true"
      [hidden]="column.visuallyHidden || hideColumn(field, column, 0)">
      <ng-template kendoGridHeaderTemplate>
        <div [ngClass]="{ 'has-help': column.help }">
          {{ column.label }}
          @if (column.help) {
          <mat-icon class="help-icon" [matTooltip]="column.help" [matTooltipPosition]="'above'"
            [matTooltipClass]="'rb-tooltip'">
            help
          </mat-icon>
          }
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-value="value" let-row="row" k-grid-ignore-click>
        <formly-field [field]="getField(field, column, rowIndex)" k-grid-ignore-click></formly-field>
      </ng-template>
    </kendo-grid-column>
    }
    @if (!hideDelete) {
    <kendo-grid-column width="60">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-value="value" let-row="row">
        <button mat-icon-button class="delete" (click)="remove(rowIndex, $event)" matToolTip="Delete Entry">
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    }

    <ng-template kendoGridNoRecordsTemplate>{{ noRecords }}</ng-template>
  </kendo-grid>
  }
  <!--  -->
  <!-- TODO: Figure out why form valuechanges doesn't work with new evaluation. Hardcoded conditional for now. -->
  <!--  -->

  @if (
  field.key === 'SelectedFunds' &&
  (field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  1 ||
  field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  '1' ||
  field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  2 ||
  field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  '2' ||
  field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  3 ||
  field.parent.model.ValidateProduct.InvestmentValidation_PurchaseType ===
  '3')
  ) {
  <div class="w-100 totals">
    @if (mode === 'amount') {
    <table class="w-100">
      <tr>
        <td>
          <h5 class="mr-2 text-right">Total Amount to Invest:</h5>
        </td>
        <td style="width: 1%">
          <h5 class="mr-2">
            {{ totalAmount | currency }}
          </h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Amount Allocated:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ totalInvestment | currency: 'USD' }}</h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Amount Left to Allocate:</h5>
        </td>
        <td>
          <h5 class="mr-2">
            {{ totalAmount - totalInvestment | currency: 'USD' }}
          </h5>
        </td>
      </tr>
    </table>
    }

    @if (mode === 'percent') {
    <table class="w-100">
      <tr>
        <td>
          <h5 class="mr-2 text-right">Total Amount to Invest:</h5>
        </td>
        <td style="width: 1%">
          <h5 class="mr-2">
            {{
            (field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount
            ? makeNumber(
            field.parent.model.ValidateProduct
            .InvestmentWizard_AssetAmount
            )
            : 0
            ) | currency
            }}
          </h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Percent Allocated:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ totalPercent | percent }}</h5>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="mr-2 text-right">Percent Left to Allocate:</h5>
        </td>
        <td>
          <h5 class="mr-2">{{ 1 - totalPercent | percent }}</h5>
        </td>
      </tr>
    </table>
    }
  </div>
  }
</div>
<ngx-loading [show]="uploadingFile"></ngx-loading>