import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { FormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-systemUsageSummary',
  templateUrl: './systemUsageSummary.component.html',
  styleUrls: ['./systemUsageSummary.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SystemUsageSummaryComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  // startDate = new FormControl(moment().subtract(30, 'days'));
  // endDate = new FormControl(moment());
  data;
  selectedUnit;
  chart = { data: null, legend: null };
  months = 6;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    const globals = this.ss.get('globals');
    const firm = this.ss.get('firm');
    this.selectedUnit = firm;
    this.getChart();
  }

  getChart() {
    this.loading = true;
    this.dbs.getUsageChart(this.selectedUnit, +this.months).subscribe(x => {
      this.chart.data = x.charts[0].Data;
      this.chart.data.chart.width = null;
      this.chart.data.title.text = null;
      this.chart.data.xAxis.title.text = null;
      this.chart.legend = x.charts[0].Legend;
      this.loading = false;
    });
  }
}
