<div mat-dialog-close class="close-button" (click)="close()">
  <mat-icon>close</mat-icon>
</div>
<div mat-dialog-header>
  <h1 class="mb-0"> New Case Management Case </h1>
</div>

<div mat-dialog-content class="dialog-content">
  <div class="row">
    <div class="col-12 case-info">
      <ng-container *ngIf="!data.useFilteredUsers">
        <div class="mb-3" *ngIf="!loading">
          <mat-form-field class="w-100">
            <mat-label>Rep Code</mat-label>
            <mat-select [(ngModel)]="repCode">
              <ng-container *ngIf="caseUsers && caseUsers.length > 0">
                <mat-option *ngFor="let user of caseUsers" [value]="user.value">
                  {{ user.value }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="data.useFilteredUsers">
        <mat-form-field class="mb-3">
          <mat-label>Rep Code</mat-label>
          <input
            type="text"
            [placeholder]="'Rep Code'"
            matInput
            [matAutocomplete]="auto"
            name="unitsControl"
            [value]="repCodeValue"
            [formControl]="repCodeControl"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="setRepCode($event)"
          >
            <mat-option *ngFor="let opt of caseUsers" value="{{ opt.value }}">
              {{ opt.value }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>

      <div class="mb-3" *ngIf="!loading">
        <mat-form-field class="w-100">
          <mat-label>Case Type</mat-label>
          <mat-select [(ngModel)]="caseType">
            <mat-option
              *ngFor="let caseType of caseTypes"
              [value]="caseType.value"
            >
              {{ caseType.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="mb-3">
        <mat-label>Case Title</mat-label>
        <input
          type="text"
          [placeholder]="'Case Title'"
          matInput
          name="caseTitle"
          [formControl]="caseTitle"
        />
      </mat-form-field>

      <div class="save-case-container">
        <button
          class="w-100"
          mat-raised-button
          aria-label="Create Case"
          color="primary"
          (click)="createCase()"
          [disabled]="!repCode || !caseType"
        >
          <mat-icon>save</mat-icon> Create Case
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
