import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';

@Injectable()
export class CaseManagementService {
  private environment;
  public baseUrl: string;
  public f5500BaseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private rbs: RightBridgeApiService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.f5500BaseUrl = this.environment.f5500Base;
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.org = this.environment.org || 'basev2';
  }

  createCaseManagementRecord(id?, data?) {
    let queryData = {};
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');

    let url = this.baseUrl + `/CaseManagement/Profile`;

    if (id) {
      url = url + `?RBProfileID=${id}`;
    }

    if (data) {
      queryData = data;
    }

    return this.http.post(url, queryData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseManagementData(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = this.baseUrl + '/CaseManagement/Search';

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseManagementProfile(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + `/CaseManagement/Profile?CRID=${id}&groupName=FrontEnd`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  saveCaseManagementRecord(id, data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/CaseManagement/Profile?CRID=${id}`;

    return this.http.patch(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseManagementUsers(repCode) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl +
      `/CaseManagement/Users?repcode=${repCode}&rights=CaseManager`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  listCaseRecordFiles(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/CaseManagement/ListFiles?caseRecordId=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseRecordFile(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/CaseManagement/GetFile?caseRecordFileId=${id}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  saveCaseRecordFile(id, data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/CaseManagement/SaveFile?caseRecordId=${id}`;

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  deleteCaseRecordFile(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + `/CaseManagement/DeleteFile?caseRecordFileId=${id}`;

    return this.http.delete(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseRecordNotes(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/CaseManagement/GetNotes?caseRecordId=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  addCaseRecordNote(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = this.baseUrl + '/CaseManagement/AddNote';

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  editCaseRecordNote(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = this.baseUrl + '/CaseManagement/EditNote';

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCaseManagementHistory(id) {
    const sessId = this.ss.get('session');
    const headers = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/CaseManagement/Profile?CRID=${id}&groupName=AllVars`,
      headers
    );
  }

  getCmUserFilterList(searchString?: string) {
    const sessId = this.ss.get('session');
    const headers = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/CaseManagement/UserFilterList`;

    if (searchString) {
      url = url + `?searchString=${searchString}`;
    }

    return this.rbs.getRequest(url, headers);
  }

  getCmRepCodes(searchString) {
    const sessId = this.ss.get('session');
    const headers = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/Hierarchy/RepCode`;

    if (searchString) {
      url = url + `?searchString=${searchString}`;
    }

    return this.rbs.getRequest(url, headers);
  }
}
