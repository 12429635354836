import { Component, OnInit } from '@angular/core';
import { Form, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService }  from '../../../services/session-storage.service'
import * as moment from 'moment';

@Component({
  selector: 'app-caseStats',
  templateUrl: './caseStats.component.html',
  styleUrls: ['./caseStats.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CaseStatsComponent implements OnInit {
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit = this.unitsManaged && this.unitsManaged.length > 0 ? this.unitsManaged[0].id : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(moment().utc().subtract(30, 'days').startOf('day'));
  endDate = new FormControl(moment().utc().endOf('day'));
  data = [];
  solutions = [];
  solution;
  assessmentType = 'ALL';
  level = 'UNIT_ONLY';
  unitList = [];
  limit30 = true;

  constructor(private dbs: DashboardService, private ss: SessionStorageService, private router: Router) { }

  ngOnInit() {
    this.ss.get('globals')?.Modules.forEach(el => {
        switch (el.toLowerCase()) {
          case 'aw':
            this.solutions.push({label: 'Annuity Wizard', value: el.toLowerCase()});
            break;
          case 'iw':
            this.solutions.push({label: 'Investment Wizard', value: el.toLowerCase()});
            break;
          // case 'rw':
          //   this.solutions.push({label: 'Rollover Wizard', value: el.toLowerCase()});
          //   break;
          case 'li':
            this.solutions.push({label: 'Life Insurance Wizard', value: el.toLowerCase()});
            break;
          case 'pp':
            this.solutions.push({label: 'Product Profiler', value: el.toLowerCase()});
            break;
          case 'rb':
            this.solutions.push({label: 'Control KB (RB)', value: el.toLowerCase()});
            break;
          // case 'cp':
          //   this.solutions.push({label: 'Client Profiler', value: el.toLowerCase()});
          //   break;
          // case 'se':
          //   this.solutions.push({label: 'Suggestion Engine', value: el.toLowerCase()});
          //   break;
          default:
            break;
        }
    });

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.solution = this.solutions[0]?.value;

    this.updateData();
  }

  updateData(clear?) {
    if (clear) {
      this.unitList = [];
    }

    this.loading = true;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    let start = this.limit30 ? back30.toISOString() : moment(this.startDate.value).toISOString();
    let end = this.limit30 ? new Date().toISOString() : moment(this.endDate.value).add(1, 'days').startOf('day').toISOString();

    this.dbs.getCaseStats(this.unit, this.solution, start, end, this.assessmentType, this.level).subscribe(x => {
      this.data = x.Summary;
      this.loading = false;
    });
  }

  handleRowClick(ev) {
    if (ev.column.field == 'UnitName') {
      if (ev.dataItem.Units >= 1) {
        if ((this.unitList.length && this.unitList[this.unitList.length - 1].UnitID != ev.dataItem.UnitID) || !this.unitList.length) {
          this.unitList.push(ev.dataItem);
        }
        this.unit = ev.dataItem.UnitID;
        this.level = 'UNIT_CHILDREN';
        this.updateData();
      }
    } else if (ev.column.field == 'Users' || ev.column.field == 'UsersWithCases') {
      if (!this.unitList.length) {
        this.unitList.push(ev.dataItem);
      }
      this.unit = ev.dataItem.UnitID;
      // this.unitList.push({type: 'USER_LEVEL'});
      this.level = 'UNIT_USERS';
      this.updateData();
    }
    console.log(ev, this.level);
  }

  goBack() {
    // console.log(this.unitList);
    // if (this.unitList.length > 2) {
    //   this.unitList.pop();
    // }

    // this.unit = this.unitList[this.unitList.length - 2].UnitID;
    if (this.unitList.length > 1) {
      this.unit = this.unitList[this.unitList.length - 1].UnitID;
      this.level = 'UNIT_CHILDREN';
      this.updateData();
      this.unitList.pop();
    } else if (this.unitList.length == 1) {
      this.unit = this.unitList[0].UnitID;
      this.level = 'UNIT_ONLY';
      this.updateData(true);
      this.unitList.pop();
    } else {
      this.unit = this.unitList[this.unitList.length - 1].UnitID;
      this.level = 'UNIT_ONLY';
      this.updateData();
      this.unitList.pop();
    }

  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }
}
