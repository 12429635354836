import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingRouting } from './reporting-routing.module';
import { UnifiedReportingComponent } from './unified-reporting/unified-reporting.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ReportingService } from './reporting.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { BridgeDensityComponent } from './reports/bridge-density/bridge-density.component';
import { ReasonTextComponent } from './reports/reason-text/reason-text.component';
import { UnitSettingsComponent } from './reports/unit-settings/unit-settings.component';
import { ActiveUsersComponent } from './reports/active-users/active-users.component';
import { LoadHistoryComponent } from './reports/load-history/load-history.component';
import { UnitProfileHistoryComponent } from './reports/unit-profile-history/unit-profile-history.component';
import { UnitUsageComponent } from './reports/unit-usage/unit-usage.component';
import { BridgeActionComponent } from './reports/bridge-action/bridge-action.component';
import { SalesTrackingComponent } from './reports/sales-tracking/sales-tracking.component';
import { MutualFundsComponent } from './reports/mutual-funds/mutual-funds.component';
import { RepExceptionsComponent } from './reports/rep-exceptions/rep-exceptions.component';
import { MatDatepickerModule } from '@angular/material/datepicker';


@NgModule({
  declarations: [
    UnifiedReportingComponent,
    BridgeDensityComponent,
    ReasonTextComponent,
    UnitSettingsComponent,
    ActiveUsersComponent,
    LoadHistoryComponent,
    UnitProfileHistoryComponent,
    UnitUsageComponent,
    BridgeActionComponent,
    SalesTrackingComponent,
    MutualFundsComponent,
    RepExceptionsComponent
  ],
  imports: [
    CommonModule,
    ReportingRouting,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ExcelModule,
    MatDatepickerModule
  ],
  providers: [
    ReportingService
  ]
})
export class ReportingModule { }
