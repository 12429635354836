<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    1040 Prospector
  </mat-card-header>
  <mat-card-content>
    <img src="https://uat.rightbridge.net/cetera/images/Prospector.png" />
    <p>Click the button below to start uploading your 1040 clients. Remember that you will need to have a completed <a href="https://uat.rightbridge.net/cetera/docs/DisclosureForm.pdf" target="_blank">disclosure</a> for each of your clients.</p>
    <form ngNoForm method="post" action="{{externalUrl}}"
      enctype="multipart/form-data">
      <input type="text" hidden name="X_RB_u" value="{{session}}">
      <button mat-raised-button type="submit" color="primary"><mat-icon>cloud_upload</mat-icon>Start Uploading</button>
    </form>
    <div class="footer w-100">
      <span>Export Instructions:</span>&nbsp;
      <a href="https://uat.rightbridge.net/cetera/docs/Lacerte.pdf" target="_blank">Lacerte</a>&nbsp;
      <a href="https://uat.rightbridge.net/cetera/docs/Drake.pdf" target="_blank">Drake</a>&nbsp;
      <a href="https://uat.rightbridge.net/cetera/docs/UltraTax.pdf" target="_blank">UltraTax</a>&nbsp;
      <a href="https://uat.rightbridge.net/cetera/docs/Proseries.pdf" target="_blank">Proseries</a>
    </div>
  </mat-card-content>
</mat-card>