import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-radioValidationExp',
  styleUrls: ['form-radioValidationExp.component.scss'],
  template: `
    <label
      class="section"
      class="w-100"
      [class.mat-error]="formControl.errors"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <mat-icon
      *ngIf="props.help"
      class="help-icon"
      matTooltip="{{ to.help }}"
      [matTooltipPosition]="'above'"
      [matTooltipClass]="'rb-tooltip'"
      >help</mat-icon
    >
    <p
      *ngIf="props.prompt"
      class="explanation"
      [class.mat-error]="formControl.errors"
      [innerHTML]="props.prompt"
    ></p>
    <p
      *ngIf="props.placeholder"
      class="italic"
      [class.mat-error]="formControl.errors"
      [innerHTML]="props.placeholder"
    ></p>
    <mat-radio-group
      class="radio-group radio-group-validate-exp col-12"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
      (change)="props.change ? to.change(field, formControl) : ''"
    >
      @for (opt of buttonOptions; track $index) {
        <div class="button-container">
        <mat-radio-button class="radio-button" [value]="opt.value">
          <mat-icon class="unchecked">radio_button_unchecked</mat-icon>
          <mat-icon class="checked">radio_button_checked</mat-icon>
          {{ opt.label }}
        </mat-radio-button>
        <span class="explanation">{{ opt.description }}</span>
        </div>
      }
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-radio-group>
  `,
})
export class FormlyRadioValidationExp extends FieldType implements OnInit {
  buttonOptions;
  ngOnInit(): void {
    this.buttonOptions = [...(<[]>this.props.options)];
  }
}
