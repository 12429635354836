import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { AppGlobalsService } from '../services/app-globals.service';
import { DownloadPageComponent } from './download-page/download-page.component';

const routes: Routes = [
  {
    path: 'downloads',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: '',
        component: DownloadPageComponent,
        canActivate: [AuthService],
        data: {
          rightNeeded: 'Sensitive',
        },
        // data: {
        //     breadcrumb: 'Landing'
        // }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloadsRouting {}
