<div mat-dialog-title>
  <h1>Add a Life Event</h1>
</div>
<div mat-dialog-content #scroll class="dialog-container">
    <form>
      <mat-form-field class="col-6">
        <mat-select [(ngModel)]="lifeEvent.type" placeholder="Select Life Event" name="lifeEvent">
          <mat-option value="7010">Moving</mat-option>
          <mat-option value="8000">Military Deployment</mat-option>
          <mat-option value="2040">Disability</mat-option>
          <mat-option value="4010">Job Change</mat-option>
          <mat-option value="5030">New Child</mat-option>
          <mat-option value="4060">Retirement</mat-option>
          <mat-option value="1020">Marriage</mat-option>
          <mat-option value="1030">Divorce</mat-option>
          <mat-option value="6000">Death</mat-option>
          <mat-option value="2070">Inheritance</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-6">
        <input matInput [matDatepicker]="picker" placeholder="Date of Event" [(ngModel)]="lifeEvent.date" name="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-12">
        <textarea matInput placeholder="Notes" [(ngModel)]="lifeEvent.note" name="note" matTextareaAutosize
          matAutosizeMinRows=3 matAutosizeMaxRows=6></textarea>
      </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">Save</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>