<div class="page-container thank-you-container container">
  <div class="thank-you-content">
    <h1 class="text-center">{{thankYouProfile?.Title}}</h1>
    <div class="check-icon-container text-center" *ngIf="thankYouProfile">
      <mat-icon>check</mat-icon>
    </div>
    <h4 class="text-center mb-4" [innerHTML]="thankYouProfile?.Body"></h4>
    <ng-container *ngIf="thankYouProfile?.Image">
      <div class="thank-you-image-container text-center">
        <img src="thankYouProfile.Image" />
      </div>
    </ng-container>
  </div>
</div>
