import { Component, OnInit } from '@angular/core';
import { SessionStorageService }  from '../../../services/session-storage.service'

@Component({
  selector: 'app-links',
  templateUrl: './app-links.component.html',
  styleUrls: ['./app-links.component.scss']
})
export class AppLinksComponent implements OnInit {
  rights = [];

  constructor(private ss: SessionStorageService) { }

  ngOnInit() {
    this.rights = this.ss.get('rights');
  }

}
