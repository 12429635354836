<div class="page-container sso-container">
  <mat-progress-bar *ngIf="valid" mode="indeterminate"></mat-progress-bar>
  <div class="jumbotron mb-0">
    <div class="content row col-12">
      <div class="w-100">
        <img src="./assets/rightbridge-logo.png" alt="RightBridge" />
        <h1>Welcome to RightBridge</h1>
        <p *ngIf="valid">Please wait while we verify your credentials.</p>
        <p *ngIf="!valid" ><mat-icon class="error">error_outline</mat-icon>There was a problem logging you in, please contact your internal helpdesk for further assistance.</p>
      </div>
    </div>
  </div>
</div>