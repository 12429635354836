import { NgModule } from '@angular/core';
import { RixtremaDialogComponent } from './rixtrema-dialog/rixtrema-dialog.component';
import { RixtremaService } from './rixtrema.service';
import { RixtremaLookupComponent } from './rixtrema-lookup/rixtrema-lookup.component';
import { Form5500Component } from './form5500/form5500.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
    imports: [
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        CommonModule,
        FormsModule,
        MatIconModule,
        NgxLoadingModule
    ],
    declarations: [RixtremaDialogComponent, RixtremaLookupComponent, Form5500Component],
    providers: [RixtremaService],
    exports: [RixtremaDialogComponent, RixtremaLookupComponent, Form5500Component]
})
export class RixtremaModule { }
