<div class="container unified-report-container">
  <h1 *ngIf="printParams && !generating">Print a Report</h1>

  <!-- <form> -->
  <mat-form-field
    class="col-6"
    *ngIf="printParams && !generating"
  >
    <input
      matInput
      name="text"
      placeholder="Please Enter a Custom Filename"
      [(ngModel)]="filename"
    />
  </mat-form-field>

  <h4 *ngIf="printParams && !generating">Please Select Reports and Options to Include</h4>
  <div class="report-sections" *ngIf="printParams && !generating">
    <div class="app-fields-container pb-3 no-pp-warning" *ngIf="printParams.ClientProfilingOut.ValidPPRan != 'Y'">
      <span class="warning">
        <mat-icon>warning</mat-icon>Please return to the Product Profiler and run a valid profile to view Consolidated Report.
      </span>
    </div>
    <!-- PP Section -->
    <!-- <div class="app-fields-container pp" *ngIf="appAccess.includes('PP')">
      <mat-checkbox class="col-3 mb-4" (change)="updateApps($event, 'ProductProfiler')" [checked]="enablePrint.ProductProfiler" [disabled]="printParams.ClientProfilingOut.RIPRan != 'Y'">Product Profiler</mat-checkbox>
      <span class="pull-right warning" *ngIf="printParams.ClientProfilingOut.RIPRan != 'Y'"><mat-icon>warning</mat-icon>Report is not available.</span>
      <div class="options col-12" *ngIf="enablePrint.ProductProfiler">
        <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div> -->

    <!-- IW Section -->
    <div class="app-fields-container iw" *ngIf="appAccess.includes('IW')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'InvestmentWizard')"
        [checked]="enablePrint.InvestmentWizard"
        [disabled]="printParams.IWProfilingResults.IWRan != 'Y' || printParams.ClientProfilingOut.ValidPPRan != 'Y'"
      >
        Investment Wizard
      </mat-checkbox>
      <span class="pull-right warning" *ngIf="printParams.IWProfilingResults.IWRan != 'Y'">
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div class="options col-12" *ngIf="enablePrint.InvestmentWizard">
        <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <!-- AW Section -->
    <div class="app-fields-container aw" *ngIf="appAccess.includes('AW')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'AnnuityWizard')"
        [checked]="enablePrint.AnnuityWizard"
        [disabled]="printParams.AWProfilingResults.AWRan != 'Y' || !awProductsSelected || printParams.ClientProfilingOut.ValidPPRan != 'Y'"
      >
        Annuity Wizard
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="printParams.AWProfilingResults.AWRan != 'Y' || !awProductsSelected"
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div class="options col-12" *ngIf="enablePrint.AWClientReport">
        <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  
    <!-- LI Section -->
    <div class="app-fields-container aw" *ngIf="appAccess.includes('LI')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'LifeWizard')"
        [checked]="enablePrint.LifeWizard"
        [disabled]="printParams.LIProfilingResults.LIRan != 'Y' || printParams.ClientProfilingOut.ValidPPRan != 'Y'"
      >
        Life Insurance Wizard
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="printParams.LIProfilingResults.LIRan != 'Y'"
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div class="options col-12" *ngIf="enablePrint.LIClientReport">
        <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- </form> -->

  <div class="buttons row" *ngIf="printParams && !generating">
    <button
      mat-raised-button
      color="accent"
      (click)="generateReport()"
      [disabled]="printParams.ClientProfilingOut.ValidPPRan != 'Y'"
    >
      Generate Report
    </button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>

<div class="message" *ngIf="generating">
  <h1>Please wait while your report is generated</h1>
</div>


<!-- <ngx-loading [show]="loading"></ngx-loading> -->
