import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-followUp',
  templateUrl: './followUp.component.html',
  styleUrls: ['./followUp.component.scss'],
})
export class FollowUpComponent implements OnInit {
  callbackList;
  followupList;
  callListNumber;
  showCallBack = true;
  showCallList = true;
  state = 'loaded';

  constructor(
    private ses: SuggestionEngineService,
    private router: Router
  ) {}

  ngOnInit() {
    this.ses.getFollowUp().subscribe(x => {
      this.followupList = x.data.map(z => {
        return {
          id: z.id,
          name: z.FullName,
          suggestion: z.bridgename,
          detail: z.detailname + ' ' + z.detail,
        };
      });
    });

    this.ses.getCallList().subscribe(x => {
      this.callListNumber = x.data.length;
      this.showCallList = x.data.length > 0;
    });

    this.ses.getCallback().subscribe(x => {
      this.showCallBack = x && x.data && x.data.length > 0;
      this.callbackList = x.data.map(z => {
        return {
          id: z.id,
          name: z.FullName,
          score: z.callbackScore,
          type: z.onType,
          date: z.itemDate,
        };
      });
    });
  }

  callListSearch() {
    this.router.navigate(['/se/search', { type: 'callList' }]);
  }

  callBackSearch() {
    this.router.navigate(['/se/search', { type: 'callBack' }]);
  }

  goToProfile(ev, id) {
    this.router.navigate([`/se/client/${id}`]);
  }
}
