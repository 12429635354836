import { Component, OnInit, Injectable } from '@angular/core';
import { BeaconService } from "../services/beacon.service";
import { RightBridgeApiService } from "../services/right-bridge-api.service";
import * as moment from "moment";
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../services/environment.service';
import { UnifiedFlowService } from '../unified-flow/unified.service';

@Injectable()

export class Factsheet {
  private environment;
  private baseUrl: string;
  selectedBeaconAnnuity;
  constructor(
    private rbs: RightBridgeApiService,
    private bs: BeaconService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService,
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }


  getPdf() {
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.rbs.getHistoricalFactSheet(this.selectedBeaconAnnuity).subscribe(data => {
        let filename = `${this.selectedBeaconAnnuity.carrier_name}-${this.selectedBeaconAnnuity.contract.product_name}-HistoricalFactSheet.pdf`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari
          });
          // this.loading = false;
        };
        this.unfFlowSvc.setLoading(false);
    });
  }

  getShareClass(product) {
    let classes = [];

    if (product.share_class) {
      classes = product.share_class.split(',');
    } else if (product.product_charges.surrender_charges) {
      product.product_charges.surrender_charges.forEach(x => {
        let className = x.share_class_name != 'N/A' ? x.share_class_name.replace('Class ', '') : null;

        if (className) {
          classes.push(className);
        }
      });
      classes = [...new Set(classes)];
    }

    return classes;
  }

  getBeaconProduct(product) {
    // this.exchangeData.AnnuityExchange_PurchaseYear = moment(new Date(this.dateCtrl.value)).format('YYYY');
    // this.showData = true;
    this.selectedBeaconAnnuity = product;

    // only see these 3 types from beacon
    switch (this.selectedBeaconAnnuity['product_type'].toLowerCase()) {
      case 'fixed':
        this.selectedBeaconAnnuity.type = 'FA2'
        break;
      case 'indexed':
        this.selectedBeaconAnnuity.type = 'IA'
        break;
      case 'variable':
        this.selectedBeaconAnnuity.type = 'VA'
        break;
      default:
        break;
    }

    this.selectedBeaconAnnuity['product_type'] = this.selectedBeaconAnnuity['product_type'].charAt(0).toUpperCase() + this.selectedBeaconAnnuity['product_type'].slice(1);


    this.bs.authenticate().subscribe(authResp => {
      if (authResp) {
        this.bs.getToken(authResp).subscribe(resp => {
          this.bs.getProductDetails(this.selectedBeaconAnnuity['product_key'], this.selectedBeaconAnnuity['product_type'].toLowerCase(), resp['token']).subscribe(y => {
            this.selectedBeaconAnnuity.contract = y['contract'][0];

            if (!this.selectedBeaconAnnuity.contract.age || this.selectedBeaconAnnuity.contract.age && this.selectedBeaconAnnuity.contract.age.length < 1) {
              let min = this.selectedBeaconAnnuity.contract.min_issue_age_owner || 0,
                max = this.selectedBeaconAnnuity.contract.max_issue_age_owner || 0;

              this.selectedBeaconAnnuity.contract.age = [{ owner_non_qualified: `${min} - ${max}` }];

              let min2 = this.selectedBeaconAnnuity.contract.min_issue_age_anntutant || 0,
                max2 = this.selectedBeaconAnnuity.contract.max_issue_age_anntutant || 0;

              this.selectedBeaconAnnuity.contract.age = [{ annuitant_non_qualified: `${min2} - ${max2}` }];
            }

            if (!this.selectedBeaconAnnuity.contract.contribution || this.selectedBeaconAnnuity.contract.contribution && this.selectedBeaconAnnuity.contract.contribution.length < 1) {
                this.selectedBeaconAnnuity.contract.contribution = [{
                    minimum_initial_non_qualified: this.selectedBeaconAnnuity.contract.min_nq_premium,
                    minimum_initial_qualified: this.selectedBeaconAnnuity.contract.min_q_premium,
                    minimum_additional_non_qualified: this.selectedBeaconAnnuity.contract.min_nq_subsequent,
                    minimum_additional_qualified: this.selectedBeaconAnnuity.contract.min_q_subsequent,
                    total_contribution_wia: this.selectedBeaconAnnuity.contract.total_contribution_wia
                }];
            }

            if (Array.isArray(this.selectedBeaconAnnuity.contract.share_class)) {
                this.selectedBeaconAnnuity.contract.share_class = this.selectedBeaconAnnuity.contract.share_class.join(', ');
            }

            let shareClass = this.getShareClass(y['contract'][0]);


            let riders;
            let rdCtr = 0;
            this.bs.getRiders({ product_key: this.selectedBeaconAnnuity.product_key, product_type: this.selectedBeaconAnnuity['product_type'].toLowerCase()}).subscribe(x => {
              riders = x['riders'];

              if (riders.length > 0) {
                this.selectedBeaconAnnuity.riders = {living: [], death: [], other: [], gmab: []};
                riders.forEach((rd, i) => {
                  this.bs.getRiderDetails(this.selectedBeaconAnnuity.product_key, this.selectedBeaconAnnuity['product_type'].toLowerCase(), rd.rider_type).subscribe(x => {
                    let riderData = null;
                    riderData = rd;

                    riderData.product_type = this.selectedBeaconAnnuity['product_type'];

                    riderData.details = x['benefit_details'].find(z => z.name == rd.rider_name);
                    if (rd.rider_type.toUpperCase() == 'GMWB' || rd.rider_type.toUpperCase() == 'GMIB') {
                      if (riderData.details.case && riderData.details.case.charge && riderData.details.case.charge.length > 0) {
                        riderData.details.case.charge = this.deDupObjectArray(riderData.details.case.charge);
                      }
                      if (riderData.details.case && riderData.details.case.abp && riderData.details.case.abp.length > 0) {
                        riderData.details.case.abp = this.deDupObjectArray(riderData.details.case.abp);
                      }
                      this.selectedBeaconAnnuity.riders.living.push(riderData);
                    } else if (rd.rider_type.toUpperCase() == 'DEATH BENEFIT') {
                      if (riderData.details && riderData.details.case && riderData.details.case.length > 0) {
                        riderData.details.case.forEach(det => {
                          det.charge = det.charge ? det.charge : det.current_charge || null;
                          det.max_charge = det.max_charge ? det.max_charge : det.maximum || null;
                          if (!det.owner_min_age) {
                            det.owner_min_age = riderData.details.minimum_age || null;
                          }
                          if (!det.owner_max_age) {
                            det.owner_max_age = riderData.details.maximum_age || null;
                          }
                        });
                      }
                      this.selectedBeaconAnnuity.riders.death.push(riderData);
                    } else if (rd.rider_type.toUpperCase() == 'GMAB') {
                      this.selectedBeaconAnnuity.riders.gmab.push(riderData);
                    } else {
                      this.selectedBeaconAnnuity.riders.other.push(riderData);
                    }


                    rdCtr++;
                    if (rdCtr >= riders.length) {
                      let charges = null;
                      let multi = false;
                      if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['share_class'] && this.selectedBeaconAnnuity.contract['share_class'].length > 1) {
                        this.selectedBeaconAnnuity.contract['share_class'] = this.selectedBeaconAnnuity.contract['share_class'].slice(', ');
                        multi = true;
                      }

                      if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['product_charges']) {
                        charges = this.selectedBeaconAnnuity.contract['product_charges']['surrender_charges'];
                      } else if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['surrender_charges']) {
                        charges = this.selectedBeaconAnnuity.contract['surrender_charges'];
                      }
                      if (multi) {
                        this.getCdsc(charges);
                      } else {
                        this.getCdsc(charges, shareClass[0]);
                      }
                      this.getPdf();
                    }
                  });
                });


              } else {
                let charges = null;
                let multi = false;
                if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['share_class'] && this.selectedBeaconAnnuity.contract['share_class'].length > 1) {
                  this.selectedBeaconAnnuity.contract['share_class'] = this.selectedBeaconAnnuity.contract['share_class'].slice(', ');
                  multi = true;
                }

                if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['product_charges']) {
                  charges = this.selectedBeaconAnnuity.contract['product_charges']['surrender_charges'];
                } else if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['surrender_charges']) {
                  charges = this.selectedBeaconAnnuity.contract['surrender_charges'];
                }
                if (multi) {
                  this.getCdsc(charges);
                } else {
                  this.getCdsc(charges, shareClass[0]);
                }
                this.getPdf();
              }
            });
          });
        })
      }
    })

  }

  getCdsc(raw, share?) {
    let cdsc;
    let schedLabel = share ? 'Class ' + share : 'Schedule ';
    let skip = this.selectedBeaconAnnuity['product_type'] === 'IA' || this.selectedBeaconAnnuity['product_type'] === 'FA2' ? 2 : 3;

    if (share) {
      cdsc = raw.filter(x => {
        return x.share_class_name == 'Class ' + share.toUpperCase();
      });
    } else {
      cdsc = raw;
    }

    this.selectedBeaconAnnuity.cdsc = '';
    Object.keys(cdsc).forEach((ind, t) => {
      if(cdsc[ind]['name'].toUpperCase() != 'NO CDSC') {
        let schedArray: string[] = [];
        Object.keys(cdsc[ind]).forEach(entry => {
          if (entry.startsWith('YR')) {
            schedArray.push(cdsc[ind][entry])
          }
        });
        schedArray = schedArray.filter(arg => +arg > 0);

        if (t == 0 && share) {
          this.selectedBeaconAnnuity.cdsc += schedLabel + ': ';
        }
        else if (t > 0 && share) {
          this.selectedBeaconAnnuity.cdsc += '  ' + schedLabel + ': ';
        }
        else if (t > 0 && !share) {
          this.selectedBeaconAnnuity.cdsc += '  Schedule ' + Number(t+1) + ': ';
        }
        else {
          this.selectedBeaconAnnuity.cdsc += 'Schedule ' + Number(t+1) + ': ';
        }

        if (schedArray.length < 1) {
          this.selectedBeaconAnnuity.cdsc += 0;
        } else {
          this.selectedBeaconAnnuity.cdsc += schedArray.join(', ');
        }
      }
    });
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter((object, index) =>
      index === data.findIndex(obj =>
        JSON.stringify(obj) === JSON.stringify(object)
      )
    );
    return uniqueArray;
  }
}
