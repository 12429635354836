<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Top 5
  </mat-card-header>
  <mat-card-content>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
    <div class="no-results" *ngIf="state == 'none'">
      <h4>No Opportunities Available</h4>
    </div>
    <table>
      <thead>
        <th>Name</th>
        <th>Suggestion</th>
        <th>Score</th>
        <!-- <th></th> -->
      </thead>
      <tbody>
        <tr *ngFor="let op of clientOpts;" (click)="goToProfile($event, op.id)">
          <td>{{op.name}}</td>
          <td>{{op.bridgeName}}</td>
          <td>{{op.bridgeScore}}</td>
          <!-- <td class="action-cell">
            <button class="like" mat-icon-button matTooltip="I Like It" matTooltipPosition="above" (click)="dailyAction('like', op.id, op.bridgeId); $event.stopPropagation();" *ngIf="noBridgeAction(op.id)"><mat-icon>thumb_up</mat-icon></button>
            <button class="no-thanks" mat-icon-button matTooltip="No Thank You" matTooltipPosition="above" (click)="dailyAction('noThanks', op.id, op.bridgeId); $event.stopPropagation();" *ngIf="noBridgeAction(op.id)"><mat-icon>thumb_down</mat-icon></button>
            <ng-container *ngIf="op.action == '2290'; else i_like">
              <span class="action-taken">Maybe Next Time</span></ng-container>
            <ng-template #i_like>
              <span *ngIf="op.action > 0" class="action-taken">You Liked It!</span>
            </ng-template>
            <button class="follow-up" mat-icon-button matTooltip="Follow Up" matTooltipPosition="above" (click)="DailyAction()"><mat-icon>update</mat-icon></button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
