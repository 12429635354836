import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router} from '@angular/router';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService }  from '../services/session-storage.service'
import { GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process, State, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProxyComponent implements OnInit {
  globals = {user: {
    id: null,
    username: null,
    firstname: null,
    lastname: null,
    canProxyFor: []
  }};
  columns = [
    {data: 'userID', label: 'User ID'},
    {data: 'username', label: 'User Name'},
    {data: 'name', label: 'Name'}
  ];
  gridData;
  currApp;

  constructor(private rbs: RightBridgeApiService, private ss: SessionStorageService, private route: Router) { }

  ngOnInit() {
    let localGlobals = this.ss.get('globals');
    this.globals = localGlobals && localGlobals.user ? localGlobals : this.globals;
    this.formatList();
  }

  formatList() {
    const proxyList = this.globals?.user.canProxyFor;
    this.gridData = proxyList.map(x => {
      return {'userID': x.id, 'username': x.UserName, 'name': x.FirstName + ' ' + x.LastName}
    });
  }

  updateApp() {
    this.currApp = this.ss.get('currentApp');
  }

  startProxy(data, event) {
    this.updateApp();
    let item = event.selectedRows[0].dataItem;

    this.rbs.startProxy(item).subscribe(x => {
      let sessionId = x.result;

      item['session'] = sessionId;
      this.ss.set('proxySession', item);

      let origSession = {
        name: null,
        session: null,
        userID: null,
        username: null,
        firstname: null,
        lastname: null
      };
      // const globals = this.ss.get('globals');
      origSession.session = this.ss.get('session');
      origSession.userID = this.globals?.user.id || null;
      origSession.username = this.globals?.user.username || null;
      origSession.firstname = this.globals?.user.firstname || null;
      origSession.lastname = this.globals?.user.lastname || null;

      this.ss.set('origSession', origSession);
      this.ss.set('session', sessionId);
      this.rbs.getGlobals(sessionId).subscribe(x => {
        let url = '/' + this.currApp;
        this.route.navigate([url]);
      });

    });
  }

}
