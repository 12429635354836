<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <form [formGroup]="form">
    <mat-form-field class="col-12">
      <textarea
        matInput
        [formControlName]="'reason'"
        [placeholder]="'Copied Profile Description'"
        [required]="required"
      ></textarea>
      <mat-error>This field is required</mat-error>
    </mat-form-field>
    <mat-form-field class="col-6" *ngIf="selectLaunch">
      <mat-label>Launch To</mat-label>
      <mat-select [formControlName]="'launchto'" [required]="required">
        <mat-option
          *ngFor="let redirect of redirects"
          [value]="redirect.value"
          >{{ redirect.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">Copy</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
