<div mat-dialog-header>
  <h1>Unit History Report</h1>
</div>
<div mat-dialog-content class="dialog-content">
  <label class="w-100">Date Range</label>
  <mat-form-field>
    <input matInput [matDatepicker]="picker1" placeholder="Start Date" [(ngModel)]="startDate">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <span class="mx-5">To</span>
  <mat-form-field>
    <input matInput [matDatepicker]="picker2" placeholder="End Date" [(ngModel)]="endDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</div>
<div class="buttons">
  <button
    class="pull-right"
    [disabled]="!startDate || !endDate"
    mat-raised-button
    color="primary"
    (click)="downloadUnitHistoryReport()"
  >
    Download Report
  </button>
  <button
    class="pull-right mr-3"
    mat-button
    color="warn"
    (click)="close()"
  >
    Cancel
  </button>
</div>