<div class="container unified-report-container">
  <h1 *ngIf="printParams && !generating">Print a Report</h1>

  <!-- <form> -->
  <mat-form-field class="col-6" *ngIf="printParams && !generating">
    <input
      matInput
      name="text"
      [(ngModel)]="customFilename"
      placeholder="Please Enter a Custom Filename"
    />
  </mat-form-field>

  <h4 *ngIf="printParams && !generating">
    Please Select Reports and Options to Include
  </h4>
  <div class="report-sections" *ngIf="printParams && !generating">
    <div
      class="app-fields-container pb-3 no-pp-warning"
      *ngIf="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
    >
      <span class="warning"
        ><mat-icon>warning</mat-icon>Please return to the Product Profiler and
        run a valid profile to view Consolidated Report.</span
      >
    </div>

    <!-- Fee Compare Section -->
    <!-- *ngIf="rights.includes('FeeCompareReport')" -->
    <div class="app-fields-container feeCompare" *ngIf="showFeeCompare">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'FeeCompare')"
        [checked]="enablePrint.FeeCompare"
        [disabled]="!enableFeeCompare"
      >
        Fee Compare Report
      </mat-checkbox>
      <span class="pull-right warning" *ngIf="!enableFeeCompare">
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <!-- <span class="pull-right warning" *ngIf="enablePrint.ProductProfiler"><mat-icon>warning</mat-icon>This report is not available separately if the Product Profiler report is included.</span> -->
      <div
        class="options col-12"
        *ngIf="
          enablePrint.FeeCompare &&
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
        <mat-checkbox
          *ngIf="printParams.IWProfilingResults.IWRan === 'Y'"
          class="col-12"
          (change)="updateReportParams($event, 'FeeCompare', 'includeIW')"
          [checked]="reportPayload.FeeCompare.includeIW"
        >
          Include Investment Wizard?
        </mat-checkbox>
        <mat-checkbox
          *ngIf="
            printParams.AWProfilingResults.AWRan === 'Y' && awProductsSelected
          "
          class="col-12"
          (change)="updateReportParams($event, 'FeeCompare', 'includeAW')"
          [checked]="reportPayload.FeeCompare.includeAW"
        >
          Include Annuity Wizard?
        </mat-checkbox>
        <mat-checkbox
          *ngIf="printParams.LIProfilingResults.LIRan === 'Y'"
          class="col-12"
          (change)="updateReportParams($event, 'FeeCompare', 'includeLI')"
          [checked]="reportPayload.FeeCompare.includeLI"
        >
          Include Life Insurance Wizard?
        </mat-checkbox>
      </div>
    </div>

    <!-- PP Section -->
    <div class="app-fields-container pp" *ngIf="appAccess.includes('PP')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'ProductProfiler')"
        [checked]="enablePrint.ProductProfiler"
        [disabled]="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
      >
        Product Profiler
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div class="options col-12" *ngIf="enablePrint.ProductProfiler">
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
        <mat-checkbox
          class="col-12"
          *ngIf="printParams.UnitPrintDefaults.AWShowAlternativesOption === 'Y'"
          (change)="
            updateReportParams($event, 'ProductProfiler', 'includeDetail')
          "
          [checked]="reportPayload.ProductProfiler.includeDetail"
        >
          Include Alternatives ReasonText
        </mat-checkbox>
        <mat-checkbox
          class="col-12"
          *ngIf="
            printParams.UnitPrintDefaults.PPIncludeFeeCompare && showFeeCompare
          "
          (change)="updateApps($event, 'FeeCompare')"
          [checked]="enablePrint.FeeCompare"
        >
          Include Fee Compare Report
        </mat-checkbox>
        <mat-checkbox
          class="col-12"
          *ngIf="printParams.UnitPrintDefaults.PPAllowRolloverReport"
          (change)="updateApps($event, 'ProductProfiler', 'UseRolloverReport')"
          [checked]="enablePrint.Rollover"
        >
          Include Rollover Report
        </mat-checkbox>
      </div>
    </div>

    <!-- IW Section -->
    <div class="app-fields-container iw" *ngIf="appAccess.includes('IW')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'InvestmentWizard')"
        [checked]="enablePrint.InvestmentWizard"
        [disabled]="
          printParams.IWProfilingResults.IWRan !== 'Y' ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        Investment Wizard
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="printParams.IWProfilingResults.IWRan !== 'Y'"
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div
        class="options col-12"
        *ngIf="
          enablePrint.InvestmentWizard &&
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
        <mat-checkbox
          class="col-12 details-check"
          *ngIf="
            printParams.UnitPrintDefaults.IWFundDetails === 1 ||
            printParams.UnitPrintDefaults.IWFundDetails === 2
          "
          [checked]="printParams.UnitPrintDefaults.IWFundDetails === 1"
          (change)="
            updateReportParams($event, 'InvestmentWizard', 'includeDetail')
          "
        >
          Include fund details in report
        </mat-checkbox>
        <mat-checkbox
          class="col-12 details-check"
          *ngIf="
            printParams.UnitPrintDefaults.IWProgramDetails === 1 ||
            printParams.UnitPrintDefaults.IWProgramDetails === 2
          "
          [checked]="printParams.UnitPrintDefaults.IWProgramDetails === 1"
          (change)="
            updateReportParams(
              $event,
              'InvestmentWizard',
              'includeAlternatives'
            )
          "
        >
          Include program alternatives reason text
        </mat-checkbox>
        <mat-checkbox
          class="col-12 details-check"
          *ngIf="
            printParams.UnitPrintDefaults.IWFundAlternatives === 1 ||
            printParams.UnitPrintDefaults.IWFundAlternatives === 2
          "
          [checked]="printParams.UnitPrintDefaults.IWFundAlternatives === 1"
          (change)="
            updateReportParams(
              $event,
              'InvestmentWizard',
              'includeFundAlternativesChart'
            )
          "
        >
          Include Selected fund alternatives charts
        </mat-checkbox>
      </div>
    </div>

    <!-- AW Section -->
    <div
      class="app-fields-container aw"
      *ngIf="appAccess.includes('AW') && rights.includes('AwFullReport')"
    >
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'AWReport')"
        [checked]="enablePrint.AWReport"
        [disabled]="
          printParams.AWProfilingResults.AWRan !== 'Y' ||
          !awProductsSelected ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        Annuity Wizard Full Report
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="
          printParams.AWProfilingResults.AWRan !== 'Y' ||
          !awProductsSelected ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div
        class="options col-12"
        *ngIf="
          enablePrint.AWReport &&
          printParams.AWProfilingResults.AWRan === 'Y' &&
          awProductsSelected &&
          printParams.ClientProfilingOut.ValidPPRan === 'Y'
        "
      >
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
        <mat-checkbox
          *hasPermission="'ExcludeAlternativesReportPages'"
          class="col-12"
          (change)="
            updateReportParams($event, 'AWReport', 'excludeAlternatives')
          "
          [checked]="reportPayload.AWReport.excludeAlternatives"
        >
          Exclude Alternatives ReasonText™
        </mat-checkbox>
        <mat-checkbox
          *hasPermission="'ShowVAFunds'"
          class="col-12"
          (change)="updateReportParams($event, 'AWReport', 'ivao')"
          [checked]="reportPayload.AWReport.ivao"
        >
          Include VA fund options in report
        </mat-checkbox>
      </div>
    </div>

    <div
      class="app-fields-container aw"
      *ngIf="appAccess.includes('AW') && rights.includes('AwCFVReport')"
    >
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'AWClientReport')"
        [checked]="enablePrint.AWClientReport"
        [disabled]="
          printParams.AWProfilingResults.AWRan !== 'Y' ||
          !awProductsSelected ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        Annuity Wizard Client Report
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="
          printParams.AWProfilingResults.AWRan !== 'Y' ||
          !awProductsSelected ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div
        class="options col-12"
        *ngIf="
          enablePrint.AWClientReport &&
          printParams.AWProfilingResults.AWRan === 'Y' &&
          awProductsSelected &&
          printParams.ClientProfilingOut.ValidPPRan === 'Y'
        "
      >
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
        <mat-checkbox
          *hasPermission="'ExcludeAlternativesReportPages'"
          class="col-12"
          (change)="
            updateReportParams($event, 'AWClientReport', 'excludeAlternatives')
          "
          [checked]="reportPayload.AWClientReport.excludeAlternatives"
        >
          Exclude Alternatives ReasonText™
        </mat-checkbox>
        <mat-checkbox
          *hasPermission="'ShowVAFunds'"
          class="col-12"
          (change)="updateReportParams($event, 'AWClientReport', 'ivao')"
          [checked]="reportPayload.AWClientReport.ivao"
        >
          Include VA fund options in report
        </mat-checkbox>
      </div>
    </div>

    <!-- LI Section -->
    <div class="app-fields-container li" *ngIf="appAccess.includes('LI')">
      <mat-checkbox
        class="col-3 mb-4"
        (change)="updateApps($event, 'LifeWizard')"
        [checked]="enablePrint.LifeWizard"
        [disabled]="
          printParams.LIProfilingResults.LIRan !== 'Y' ||
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        Life Insurance Wizard
      </mat-checkbox>
      <span
        class="pull-right warning"
        *ngIf="printParams.LIProfilingResults.LIRan !== 'Y'"
      >
        <mat-icon>warning</mat-icon>Report is not available.
      </span>
      <div
        class="options col-12"
        *ngIf="
          enablePrint.LifeWizard &&
          printParams.ClientProfilingOut.ValidPPRan !== 'Y'
        "
      >
        <!-- <mat-form-field class="w-100" *ngIf="printParams.UnitQuestions.NoteField">
          <textarea
            class="w-100"
            matInput
            placeholder="Application Specfic Notes"
            rows="5"
          ></textarea>
        </mat-form-field> -->
      </div>
    </div>
  </div>
  <!-- </form> -->

  <div class="buttons row" *ngIf="printParams && !generating">
    <button
      mat-raised-button
      color="accent"
      (click)="generateReport()"
      [disabled]="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
    >
      Generate Report
    </button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>

<div class="message mt-5" *ngIf="generating">
  <h1 class="mt-5">
    <ngx-loading
      [show]="generating"
      [config]="{
        fullScreenBackdrop: false,
        backdropBackgroundColour: 'rgba(0,0,0,0)'
      }"
    ></ngx-loading>
    Please wait while your report is generated
  </h1>
</div>

<ngx-loading [show]="loading && !generating"></ngx-loading>
