import { Component, OnInit } from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { BeaconService } from '../../services/beacon.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AnnuityWizardService } from '../services/annuity-wizard-service.service';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';


@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {
  private environment;
  private baseUrl: string;

  searchType: string = 'current';
  annuityType: string;
  carriers: any;
  selectedCarrier: string;
  annuities: any;
  selectedAnnuity;
  selectedBeaconAnnuity;
  selectedDate;
  canDownload: boolean = false;
  historicalAllowed: boolean = false;
  loading: boolean = false;
  debug: boolean = false;
  maxDate = new Date();
  userSelectedDate: string;
  year = new Date().getFullYear();

  beaconToken: string;

  noAnnuities = false;

  constructor(
    private rbs: RightBridgeApiService,
    private aws: AnnuityWizardService,
    private beaconSrc: BeaconService,
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) { }

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    let rights = this.ss.get('rights');
    this.historicalAllowed = rights?.includes('AllowHistoricalFactSheetLookup');
    this.route.params.subscribe(params => {
      this.debug = params.debug || false;
    });

    if (!this.historicalAllowed) {
      this.searchType = 'current';
      // this.getCarriers();
    }
  }

  setDate(ev) {
    this.userSelectedDate = new Date(ev.value).toISOString();
    if (this.selectedBeaconAnnuity && this.selectedBeaconAnnuity['userSelectedDate']) {
      this.selectedBeaconAnnuity['userSelectedDate'] = this.userSelectedDate;
    }
    this.getCarriers();
  }

  getCarriers(ev?) {
    this.clearProductData();
    this.carriers = [];
    switch (this.searchType) {
      case 'current':
        if (this.annuityType === 'fa') {
          this.aws.getFaCarrierGroups().subscribe(x => {
            this.carriers = x['carriers'].map(z => {
              return { value: z.GroupId, display: z.GroupName };
            });
            this.carriers.sort(this.alphabetizeGroups);
          });
        } else {
          this.aws.getCarrierGroups().subscribe(x => {
            this.carriers = x['CarrierGroups'].map(z => {
              return { value: z.GroupID, display: z.GroupName };
            });
            this.carriers.sort(this.alphabetizeGroups);
          });
        }
        break;

      case 'historical':
        this.carriers = this.getBeaconCarriers();
        break;
    }
  }

  getAnnuities(ev) {
    this.canDownload = false;
    // console.log(ev, this.searchType);
    this.annuities = [];
    if (!this.historicalAllowed) {
      this.searchType = 'current';
    }
    switch (this.searchType) {
      case 'current':
        if (this.annuityType == 'fa') {
          this.aws.getFaAnnuities(ev.value).subscribe(x => {
            this.annuities = x['FAProducts'].map(z => {
              return { Cusip: z.ID, CarrierCode: z.CarrierCode, Contract: z.Product }
            });
          });
        } else {
          this.aws.getGroupAnnuities(ev.value).subscribe(x => {
            this.annuities = x['Annuities'];
          });
        }
        break;
      case 'historical':
        this.annuities = this.getBeaconAnnuities(ev.value);
        break;
    }

  }

  getDetails(ev) {
    if (this.searchType == 'current' || !this.historicalAllowed) {
      this.canDownload = true;
    } else if (this.searchType == 'historical') {
      this.selectBeaconProduct(this.selectedAnnuity);
    }

  }

  getPdf() {
    this.loading = true;
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    let fmtdDate = new Date(this.selectedAnnuity.DateOfPPfA);
    if (!this.historicalAllowed) {
      this.searchType = 'current';
    }
    switch (this.searchType) {
      case 'current':
        if (this.annuityType == 'fa') {
          this.aws.getFaFactSheet(this.selectedAnnuity.Cusip).subscribe(data => {
            let filename = `${this.selectedAnnuity.Contract}-factsheet.pdf`;

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari
              });
              this.loading = false;
            };
            this.loading = false;
          });
        } else {
          this.aws.getAnnuityFactSheet(this.selectedAnnuity.ContractCode, this.selectedAnnuity.Cusip, fmtdDate.toISOString().substring(0, 10)).subscribe(data => {
            let filename = `${this.selectedAnnuity.Contract}-${this.selectedAnnuity.DateOfPPfA}-factsheet.pdf`;

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari
              });
              this.loading = false;
            };
            this.loading = false;
          });
        }
        break;

      case 'historical':

        this.selectedBeaconAnnuity.closed_date = new Date(this.selectedBeaconAnnuity.closed_date).toISOString();
        this.selectedBeaconAnnuity.compare_date = new Date(this.selectedBeaconAnnuity.compare_date).toISOString();
        this.selectedBeaconAnnuity.introduction_date = new Date(this.selectedBeaconAnnuity.introduction_date).toISOString();
        this.selectedBeaconAnnuity.prospectus_date = new Date(this.selectedBeaconAnnuity.prospectus_date).toISOString();
        this.selectedBeaconAnnuity.supplement_date = new Date(this.selectedBeaconAnnuity.supplement_date).toISOString();

        this.rbs.getHistoricalFactSheet(this.selectedBeaconAnnuity).subscribe(data => {
          let filename = `${this.selectedBeaconAnnuity.carrier_name}-${this.selectedBeaconAnnuity.contract.product_name}-HistoricalFactSheet.pdf`;
          this.beaconSrc.rbBeaconCounter('Fact Sheet Look Up', this.selectedBeaconAnnuity.product_key, this.selectedBeaconAnnuity.contract.product_name, this.selectedBeaconAnnuity.carrier_name).subscribe(x => { });

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari
            });
            this.loading = false;
          };
          this.loading = false;
        });
        break;
    }
  }

  getBeaconCarriers() {
    let token, list;
    this.beaconSrc.authenticate().subscribe(saml => {
      this.beaconSrc.getToken(saml).subscribe(x => {
        this.beaconToken = x['token'];
        this.beaconSrc.getCompanies(this.beaconToken).subscribe(y => {
          let companies = y['companies'];

          companies.sort((a, b) => {
            var nameA = a.carrier_name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.carrier_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          companies = companies.map(z => {
            return { value: z.company_id, display: z.carrier_name };
          });
          this.carriers = companies;
        });
      });
    });
  }

  getBeaconAnnuities(ant) {
    let variable = [];
    let other = [];
    this.annuities = [];

    this.beaconSrc.getProductData(ant).subscribe(x => {
      this.beaconSrc.processProducts(x['products']).then(y => {
        let allProducts = y;
        let variableProds = allProducts.filter(x => x['product_type'] === 'variable'),
            otherProds = allProducts.filter(x => x['product_type'] !== 'variable');

        variableProds.sort((a, b) => a['product_name'] > b['product_name'] ? 1 : a['product_name'] === b['product_name'] ? (a['compare_date'] > b['compare_date'] ? 1 : -1) : -1);

        variable = this.beaconSrc.filterVariableProducts(variableProds, this.selectedDate);
        other = this.beaconSrc.filterOtherProducts(otherProds, this.selectedDate);

        this.annuities = [...variable, ...other];
        this.noAnnuities = this.annuities.length > 0 ? false : true;
      });
    });
  }

  selectBeaconProduct(product) {
    this.canDownload = false;
    // this.exchangeData.AnnuityExchange_PurchaseYear = moment(new Date(this.dateCtrl.value)).format('YYYY');
    // this.showData = true;
    this.selectedBeaconAnnuity = product;
    this.selectedBeaconAnnuity['userSelectedDate'] = this.userSelectedDate;

    // only see these 3 types from beacon
    switch (this.selectedBeaconAnnuity['product_type'].toLowerCase()) {
      case 'fixed':
        this.selectedBeaconAnnuity.type = 'FA2'
        break;
      case 'indexed':
        this.selectedBeaconAnnuity.type = 'IA'
        break;
      case 'variable':
        this.selectedBeaconAnnuity.type = 'VA'
        break;
      default:
        break;
    }

    this.selectedBeaconAnnuity['product_type'] = this.selectedBeaconAnnuity['product_type'].charAt(0).toUpperCase() + this.selectedBeaconAnnuity['product_type'].slice(1);

    this.beaconSrc.getProductDetails(this.selectedBeaconAnnuity['product_key'], this.selectedBeaconAnnuity['product_type'].toLowerCase()).subscribe(y => {
      this.selectedBeaconAnnuity.contract = y['contract'][0];
      if (!this.selectedBeaconAnnuity.contract.age || this.selectedBeaconAnnuity.contract.age && this.selectedBeaconAnnuity.contract.age.length < 1) {
        let min = this.selectedBeaconAnnuity.contract.min_issue_age_owner || 0,
          max = this.selectedBeaconAnnuity.contract.max_issue_age_owner || 0;

        this.selectedBeaconAnnuity.contract.age = [{ owner_non_qualified: `${min} - ${max}` }];

        let min2 = this.selectedBeaconAnnuity.contract.min_issue_age_anntutant || 0,
          max2 = this.selectedBeaconAnnuity.contract.max_issue_age_anntutant || 0;

        this.selectedBeaconAnnuity.contract.age = [{ annuitant_non_qualified: `${min2} - ${max2}` }];
      }


      if (!this.selectedBeaconAnnuity.contract.contribution || this.selectedBeaconAnnuity.contract.contribution && this.selectedBeaconAnnuity.contract.contribution.length < 1) {
        this.selectedBeaconAnnuity.contract.contribution = [{
          minimum_initial_non_qualified: this.selectedBeaconAnnuity.contract.min_nq_premium,
          minimum_initial_qualified: this.selectedBeaconAnnuity.contract.min_q_premium,
          minimum_additional_non_qualified: this.selectedBeaconAnnuity.contract.min_nq_subsequent,
          minimum_additional_qualified: this.selectedBeaconAnnuity.contract.min_q_subsequent,
          total_contribution_wia: this.selectedBeaconAnnuity.contract.total_contribution_wia
        }];
      }

      if (Array.isArray(this.selectedBeaconAnnuity.contract.share_class)) {
        this.selectedBeaconAnnuity.contract.share_class = this.selectedBeaconAnnuity.contract.share_class.join(', ');
      }


      let riders;
      this.beaconSrc.getRiders({ product_key: this.selectedBeaconAnnuity.product_key, product_type: this.selectedBeaconAnnuity['product_type'].toLowerCase() }).subscribe(x => {
        riders = x['riders'];

        if (riders.length > 0) {
          this.selectedBeaconAnnuity.riders = { living: [], death: [], gmab: [], other: [] };
          riders.forEach((rd, i) => {
            this.beaconSrc.getRiderDetails(this.selectedBeaconAnnuity.product_key, this.selectedBeaconAnnuity['product_type'].toLowerCase(), rd.rider_type).subscribe(x => {
              let riderData = null;
              riderData = rd;
              riderData.details = x['benefit_details'].find(z => z.name == rd.rider_name);
              riderData.product_rider_type = this.selectedBeaconAnnuity.product_type;

              if (rd.rider_type.toUpperCase() == 'GMWB' || rd.rider_type.toUpperCase() == 'GMIB') {
                if (riderData.details.case && riderData.details.case.charge && riderData.details.case.charge.length > 0) {
                  riderData.details.case.charge = this.deDupObjectArray(riderData.details.case.charge);
                }
                if (riderData.details.case && riderData.details.case.abp && riderData.details.case.abp.length > 0) {
                  riderData.details.case.abp = this.deDupObjectArray(riderData.details.case.abp);
                }
                this.selectedBeaconAnnuity.riders.living.push(riderData);
              } else if (rd.rider_type.toUpperCase() == 'DEATH BENEFIT') {
                if (riderData.details && riderData.details.case && riderData.details.case.length > 0) {
                  riderData.details.case.forEach(det => {
                    det.charge = det.charge ? det.charge : det.current_charge || null;
                    det.max_charge = det.max_charge ? det.max_charge : det.maximum || null;
                    if (!det.owner_min_age) {
                      det.owner_min_age = riderData.details.minimum_age || null;
                    }
                    if (!det.owner_max_age) {
                      det.owner_max_age = riderData.details.maximum_age || null;
                    }
                  });
                }
                this.selectedBeaconAnnuity.riders.death.push(riderData);
              } else if (rd.rider_type.toUpperCase() == 'GMAB') {
                this.selectedBeaconAnnuity.riders.gmab.push(riderData);
              } else {
                this.selectedBeaconAnnuity.riders.other.push(riderData);
              }

              if (i >= (riders.length - 1)) {
                setTimeout(() => { this.canDownload = true; }, 500);
              }
            });
          });
        }

        let charges = null;
        let multi = false;
        if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['share_class'] && this.selectedBeaconAnnuity.contract['share_class'].length > 1) {
          this.selectedBeaconAnnuity.contract['share_class'] = this.selectedBeaconAnnuity.contract['share_class'].slice(', ');
          multi = true;
        }

        // if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['product_charges']) {
          charges = this.selectedBeaconAnnuity.contract['product_charges'] ? this.selectedBeaconAnnuity.contract['product_charges']['surrender_charges'] : this.selectedBeaconAnnuity.contract['surrender_charges'];
          // charges = this.selectedBeaconAnnuity.contract['product_charges']['surrender_charges'];
        // } else if (this.selectedBeaconAnnuity.contract && this.selectedBeaconAnnuity.contract['surrender_charges']) {
        //   charges = this.selectedBeaconAnnuity.contract['surrender_charges'];
        // }
        if (multi) {
          this.getCdsc(charges);
        } else {
          this.getCdsc(charges, this.selectedBeaconAnnuity.contract['share_class']);
        }

        if (riders.length < 1) {
          setTimeout(() => { this.canDownload = true; }, 500);
        }
      });
    });

  }

  getCdsc(raw, share?) {
    let cdsc;
    let schedLabel = share ? 'Class ' + share : 'Schedule ';

    if (share) {
      cdsc = raw.filter(x => {
        return x.share_class_name == 'Class ' + share.toUpperCase();
      });
    } else {
      cdsc = raw;
    }

    this.selectedBeaconAnnuity.cdsc = '';
    Object.keys(cdsc).forEach((ind, t) => {
      if(cdsc[ind]['name'].toUpperCase() != 'NO CDSC') {
        let schedArray: string[] = [];
        Object.keys(cdsc[ind]).forEach(entry => {
          if (entry.startsWith('YR')) {
            schedArray.push(cdsc[ind][entry])
          }
        });
        schedArray = schedArray.filter(arg => +arg > 0);

        if (t == 0 && share) {
          this.selectedBeaconAnnuity.cdsc += schedLabel + ': ';
        }
        else if (t > 0 && share) {
          this.selectedBeaconAnnuity.cdsc += '  ' + schedLabel + ': ';
        }
        else if (t > 0 && !share) {
          this.selectedBeaconAnnuity.cdsc += '  Schedule ' + Number(t+1) + ': ';
        }
        else {
          this.selectedBeaconAnnuity.cdsc += 'Schedule ' + Number(t+1) + ': ';
        }

        if (schedArray.length < 1) {
          this.selectedBeaconAnnuity.cdsc += 0;
        } else {
          this.selectedBeaconAnnuity.cdsc += schedArray.join(', ');
        }
      }
    });
  }

  clearProductData() {
    this.canDownload = false;
    this.selectedBeaconAnnuity = null;
    this.selectedAnnuity = null;
    this.selectedCarrier = null;
    this.carriers = null;
    this.annuities = null;
    this.noAnnuities = false;
  }

  clearAllData() {
    this.canDownload = false;
    this.selectedBeaconAnnuity = null;
    this.selectedAnnuity = null;
    this.selectedCarrier = null;
    this.selectedDate = null;
    this.carriers = null;
    this.annuities = null;
    this.annuityType = null;
    this.noAnnuities = false;
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter((object, index) =>
      index === data.findIndex(obj =>
        JSON.stringify(obj) === JSON.stringify(object)
      )
    );
    return uniqueArray;
  }

  alphabetizeGroups(a, b) {
    const groupA = a.display.toUpperCase();
    const groupB = b.display.toUpperCase();

    let comparison = 0;
    if (groupA > groupB) {
      comparison = 1;
    } else if (groupA < groupB) {
      comparison = -1;
    }
    return comparison;
  }
}
