import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss']
})
export class ScorecardComponent implements OnInit {
  scData;
  state: string = 'loaded';

  constructor(private ses: SuggestionEngineService) { }

  ngOnInit() {
    this.ses.getScorecard().subscribe(x => {
      this.scData = x.result;
    });
  }
}
