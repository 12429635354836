<kendo-grid
  *ngIf="loadHistoryData"
  class="col-12 mt-2 load-history-grid"
  [kendoGridBinding]="loadHistoryData.data"
  [height]="600"
>
  <ng-template ngFor [ngForOf]="loadHistoryData.headers" let-column>
    <kendo-grid-column [field]="column.DataField" [title]="column.Label">
    </kendo-grid-column>
  </ng-template>
</kendo-grid>
