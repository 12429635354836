import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {
  action = { type: '2335', notes: null, futureLikes: false, date: null};
  header = 'I Like It';
  follow_up_type = '2120';

  constructor(public dialogRef: MatDialogRef<ActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    console.log(this.data);
    // const now = new Date();
    let future = new Date();
    future.setDate(future.getDate() + 30);
    // const plusThirty = 30 * 24 * 60 * 60 * 1000;
    this.action.date = future;
    if (this.data.action === 'followUp') {
      this.header = 'Follow Up';
      this.action.type = '2155';
    }
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    // this.ses.takeDailyAction(this.data.id, this.action.type, this.data.bridge).subscribe(x => { });
    // if(this.form.valid) {
      // if (this.action.type === 'followUp') {
      //   this.action.type = this.follow_up_type;
      // }
      console.log(this.action);
      this.dialogRef.close({ data: this.action });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    // }
  }

}
