import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FilterService,
  SinglePopupService,
  PopupCloseEvent,
} from '@progress/kendo-angular-grid';
import { PopupSettings } from '@progress/kendo-angular-dateinputs';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { addDays } from '@progress/kendo-date-math';
import { Subscription } from 'rxjs';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

const closest = (
  node: HTMLElement,
  predicate: (node: HTMLElement) => boolean
): HTMLElement => {
  while (node && !predicate(node)) {
    node = node.parentNode as HTMLElement;
  }

  return node;
};

@Component({
  selector: 'app-date-range-filter',
  template: `
    <div class="k-form">
      <label class="k-form-field">
        <span>After</span>
        <kendo-datepicker
          (valueChange)="onStartChange($event)"
          [(ngModel)]="start"
          [max]="max"
          [popupSettings]="popupSettings"
          [value]="start"
        >
        </kendo-datepicker>
      </label>
      <label class="k-form-field">
        <span>Before</span>
        <kendo-datepicker
          (valueChange)="onEndChange($event)"
          [(ngModel)]="end"
          [min]="min"
          [popupSettings]="popupSettings"
          [value]="end"
        >
        </kendo-datepicker>
      </label>
    </div>
  `,
  styles: [
    `
      .k-form {
        padding: 5px;
      }
    `,
  ],
})
export class DateRangeFilterComponent implements OnInit, OnDestroy {
  @Input() public filter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() updateFilter = new EventEmitter();

  public start: Date;
  public end: Date;

  public get min(): Date {
    return this.start ? addDays(this.start, 1) : null;
  }

  public get max(): Date {
    return this.end ? addDays(this.end, -1) : null;
  }

  public popupSettings: PopupSettings = {
    popupClass: 'date-range-filter',
  };

  private popupSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private popupService: SinglePopupService
  ) {
    // Handle the service onClose event and prevent the menu from closing when the datepickers are still active.
    this.popupSubscription = popupService.onClose.subscribe(
      (e: PopupCloseEvent) => {
        if (
          document.activeElement &&
          closest(
            document.activeElement as HTMLElement,
            node =>
              node === this.element.nativeElement ||
              String(node.className).indexOf('date-range-filter') >= 0
          )
        ) {
          e.preventDefault();
        }
      }
    );
  }

  public ngOnInit(): void {
    this.start = this.findValue('start');
    this.end = this.findValue('end');
  }

  public ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }

  public onStartChange(value: Date): void {
    this.filterRange(value, this.end);
  }

  public onEndChange(value: Date): void {
    this.filterRange(this.start, value);
  }

  private findValue(operator) {
    const filter = this.filter.filters.filter(
      x =>
        (x as FilterDescriptor).field === this.field &&
        (x as FilterDescriptor).operator === operator
    )[0];
    return filter ? (filter as FilterDescriptor).value : null;
  }

  private filterRange(start, end) {
    const filters = [];

    if (start && (!end || start < end)) {
      filters.push({
        field: this.field,
        operator: 'start',
        value: start,
      });
      this.start = start;
    }

    if (end && (!start || start < end)) {
      filters.push({
        field: this.field,
        operator: 'end',
        value: end,
      });
      this.end = end;
    }

    this.filterService.filter({
      logic: 'and',
      filters: filters,
    });
    this.updateFilter.emit(this.field);
  }
}
