<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Your Case Statistics
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-6">
      <h2>Current Case Stats</h2>
      <table class="content col-12 mb-2 cases" *ngIf="data">
        <thead>
          <th class="large positive">
            <mat-icon class="positive">check_circle</mat-icon>
            {{data.summary.positive}} Positive
          </th>
          <th class="large neutral">
            <mat-icon class="neutral">error</mat-icon>
            {{data.summary.neutral}} Neutral
          </th>
          <th class="large negative">
            <mat-icon class="negative">remove_circle</mat-icon>
            {{data.summary.negative}} Negative
          </th>
        </thead>
      </table>
      <table class="content col-12" *ngIf="data">
        <tbody>
          <tr class="case" *ngFor="let case of data.cases" routerLink="/li/questionnaire/{{case.profileID}}">
            <td>

              <mat-icon *ngIf="case.products[0].scoreClass == 'positive'" class="positive">check_circle</mat-icon>
              <mat-icon *ngIf="case.products[0].scoreClass == 'neutral'" class="neutral">error</mat-icon>
              <mat-icon *ngIf="case.products[0].scoreClass == 'negative'" class="negative">remove_circle</mat-icon>
              <strong>{{case.fullName}}</strong>
            </td>
            <td class="" *ngFor="let prod of case.products">{{prod.productName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6">
      <h2>Last 30 Days</h2>
      <table class="content col-12 products" *ngIf="data">
        <thead class="mb-2">
          <th>Product</th>
          <th class="text-center">Total</th>
          <th class="large text-center">
            <mat-icon class="positive">check_circle</mat-icon>
          </th>
          <th class="large text-center">
            <mat-icon class="neutral">error</mat-icon>
          </th>
          <th class="large text-center">
            <mat-icon class="negative">remove_circle</mat-icon>
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let prdct of data.products">
            <td>{{prdct.productName}}</td>
            <td class="text-center"><span>{{prdct.total}}</span> <span>{{prdct.total / data.overall.total | percent}}</span></td>
            <td class="text-center"><span>{{prdct.positive}}</span> <span>{{prdct.positive > 0 ? (prdct.positive / prdct.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{prdct.neutral}}</span> <span>{{prdct.neutral > 0 ? (prdct.neutral / prdct.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{prdct.negative}}</span> <span>{{prdct.negative > 0 ? (prdct.negative / prdct.total).toFixed(2) * 100 : 0}}%</span></td>
          </tr>
          <tr>
            <td>Totals</td>
            <td class="text-center"><span>{{data.overall.total}}</span> <span>100%</span></td>
            <td class="text-center"><span>{{data.overall.positive}}</span> <span>{{data.overall.positive > 0 ? (data.overall.positive / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{data.overall.neutral}}</span> <span>{{data.overall.neutral > 0 ? (data.overall.neutral / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{data.overall.negative}}</span> <span>{{data.overall.negative > 0 ? (data.overall.negative / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
  </mat-card-content>
</mat-card>