<div class="page-container unified container">
  <div class="content">
    <div class="row">
      <svg
        class="col-6"
        version="1.1"
        id="Layer_1"
        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 380 500"
        style="enable-background: new 0 0 380 500"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #e6e7e8;
          }
          .st1 {
            fill: #ffffff;
          }
          .st2 {
            fill: #939597;
          }
          .st3 {
            fill: #7c9cc0;
          }
        </style>
        <g id="Polygon-1">
          <g>
            <polygon
              class="st0"
              points="258.6,330.9 268.7,330.9 263.7,314.6 		"
            />
            <path
              class="st0"
              d="M290.1,265.7c-33.6,0-60.8,27.2-60.8,60.8s27.2,60.8,60.8,60.8s60.8-27.2,60.8-60.8S323.7,265.7,290.1,265.7z
                  M274.2,348.5l-2.7-8.8h-15.6l-2.7,8.8H241l17.3-47.1H269l17.4,47.1H274.2z M329.1,348.5h-11.8l-6.3-26.9l-6.2,26.9h-11.7
                  l-10-47.1h11.3l5.4,29.5l6.5-29.5h9.6l6.6,29.5l5.4-29.5h11.3L329.1,348.5z"
            />
          </g>
          <g id="Polygon-2">
            <path
              class="st1"
              d="M209.4,132.4H194V160h15.1c4.5,0,7.9-1.1,10.2-3.3c2.3-2.2,3.5-5.4,3.5-9.5c0-4.5-1.2-8-3.6-10.7
                C216.8,133.8,213.5,132.4,209.4,132.4z"
            />
            <path
              class="st1"
              d="M127.2,132.4h-15.4V160h15.1c4.5,0,7.9-1.1,10.2-3.3s3.5-5.4,3.5-9.5c0-4.5-1.2-8-3.6-10.7
                C134.6,133.8,131.3,132.4,127.2,132.4z"
            />
            <path
              class="st1"
              d="M159.5,38.9C91.3,38.9,36,94.2,36,162.4s55.3,123.5,123.5,123.5S283,230.6,283,162.4S227.7,38.9,159.5,38.9z
                M154,169.4c-6.7,5.6-15.8,8.4-27.5,8.4h-14.7v32.5H88.7v-95.7h38.2c7.3,0,13.8,1.4,19.4,4c5.6,2.7,10,6.5,13.1,11.5
                c3.1,5,4.6,10.6,4.6,16.9C164,156.4,160.7,163.8,154,169.4z M236.2,169.4c-6.7,5.6-15.8,8.4-27.5,8.4H194v32.5h-23.1v-95.7h38.2
                c7.3,0,13.8,1.4,19.4,4c5.6,2.7,10,6.5,13.1,11.5c3.1,5,4.6,10.6,4.6,16.9C246.2,156.4,242.9,163.8,236.2,169.4z"
            />
          </g>
          <path
            id="Polygon-3"
            class="st2"
            d="M351.1,102.9c-13.4,0-24.3,10.9-24.3,24.3s10.9,24.3,24.3,24.3c13.4,0,24.3-10.9,24.3-24.3
                S364.5,102.9,351.1,102.9z M337.5,132.1c0.6,0.8,1.4,1.3,2.6,1.3c1.1,0,1.9-0.3,2.4-0.7c0.5-0.5,0.8-1.3,0.9-2.3l0-0.3h4.5l0,0.3
                c-0.2,2.1-0.9,3.7-2.3,4.9c-1.4,1.2-3.2,1.8-5.5,1.8c-2.5,0-4.4-0.8-5.8-2.5c-1.4-1.7-2.1-4-2.1-6.8v-1.1c0-1.8,0.3-3.5,1-4.8
                c0.7-1.4,1.6-2.5,2.8-3.3c1.2-0.8,2.6-1.1,4.2-1.1c2.2,0,4,0.6,5.4,1.8c1.4,1.2,2.2,2.9,2.4,5l0,0.3h-4.5l0-0.3
                c-0.1-1.1-0.4-1.9-0.9-2.4c-0.5-0.5-1.3-0.7-2.4-0.7c-1.2,0-2,0.4-2.6,1.2c-0.6,0.8-0.9,2.2-0.9,4.1v1.4
                C336.7,129.8,337,131.2,337.5,132.1z M365.5,136.8v-5.4l0.3-6.7l-4.4,12.1h-3.1l-4.4-12.1l0.3,6.7v5.4h-4.4v-19.2h5.5l4.6,12.8
                l4.5-12.8h5.6v19.2L365.5,136.8L365.5,136.8z"
          />
          <path
            id="Polygon-4"
            class="st2"
            d="M127.5,385.5c-25.6,0-46.3,20.7-46.3,46.3c0,25.6,20.7,46.3,46.3,46.3s46.3-20.7,46.3-46.3
              C173.8,406.3,153.1,385.5,127.5,385.5z M111.4,449.8h-8.6v-35.9h8.6C111.4,413.9,111.4,449.8,111.4,449.8z M148.3,449.8h-9
              l-4.8-20.4l-4.7,20.4h-8.9l-7.6-35.9h8.6l4.1,22.5l4.9-22.5h7.3l5,22.5l4.1-22.5h8.6L148.3,449.8z"
          />
          <path
            id="Polygon-5"
            class="st3"
            d="M35.6,260.4c-17.2,0-31.1,13.9-31.1,31.1c0,17.2,13.9,31.1,31.1,31.1s31.1-13.9,31.1-31.1
              C66.7,274.3,52.8,260.4,35.6,260.4z M30.6,304.2H14.7v-24.1h5.8v19.6h10.1C30.6,299.8,30.6,304.2,30.6,304.2z M52.2,304.2h-6
              L43,290.5l-3.2,13.7h-6l-5.1-24.1h5.8l2.8,15.1l3.3-15.1h4.9l3.4,15.1l2.8-15.1h5.8L52.2,304.2z"
          />
        </g>
      </svg>
      <div class="display-content col-6">
        <h1>404 - Page Not Found</h1>
        <h3
          >The page you were looking for either doesn't exist or has been moved
          somewhere else.</h3
        >
        <div>
          <button
            mat-raised-button
            color="primary"
            (click)="notFoundNavigation('home')"
            ><mat-icon>home</mat-icon>Home</button
          >
          <button
            mat-raised-button
            color="primary"
            (click)="notFoundNavigation('back')"
            ><mat-icon>arrow_back</mat-icon>Go back</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
