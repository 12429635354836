import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ES6Parser, ES6StaticEval } from 'espression';
@Component({
  selector: 'app-form-number',
  styleUrls: ['form-number.component.scss'],
  template: `
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.label }}</mat-label>
      <input
        matInput
        type="number"
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
      />
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      >
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyNumberComponent extends FieldType {
  stringEval(str) {
    const parser = new ES6Parser();
    const staticEval = new ES6StaticEval();

    const ast = parser.parse(str.replace('.', '_'));
    return staticEval.evaluate(ast);
  }
}
