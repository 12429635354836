import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service'
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-legacy-profile-history',
  templateUrl: './legacy-profile-history.component.html',
  styleUrls: ['./legacy-profile-history.component.scss']
})

export class LegacyProfileHistoryComponent implements OnInit {
  profile;
  changeSummary;
  loading = false;
  solution;
  rights = [];

  constructor(
    private admnSrvc: AdminService,
    private route: ActivatedRoute,
    private ss: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.profile = params['params']['profileId'];
      this.solution = params['params']['solution']?.toLowerCase();
    });
    this.getProfileHistory();
    this.rights = this.ss.get('rights') ? this.ss.get('rights') : [];
  }


  getProfileHistory() {
    this.loading = true;
    this.admnSrvc.getProfileHistory(this.profile, this.solution).subscribe(x => {
      this.changeSummary = x.results;
      this.loading = false;
    })
  }

  report() {}
}