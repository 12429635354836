<div class="page-container product-shelf-container container">
  <form #rixtremaForm>
      <h1 class="w-100">Lookup Plan</h1>
      <div class='row'>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="searchTerm" id="rixtrema-search" placeholder="Enter Plan Name"
                  name="searchTerm" />
          </mat-form-field>
          <div class='col-6'>
        </div>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="zip" id="zip" placeholder="ZIP" name="zip" />
          </mat-form-field>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="distance" id="distance" placeholder="Distance" name="distance" />
          </mat-form-field>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="participantMin" id="participantMin" placeholder="Participant Min" name="Participant Min" />
          </mat-form-field>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="participantMax" id="participantMax" placeholder="Participant Max" name="Participant Max" />
          </mat-form-field>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="feeMin" id="feeMin" placeholder="Fee Min" name="Total Fee Min" />
          </mat-form-field>
          <mat-form-field class='col-6'>
              <input matInput [(ngModel)]="feeMax" id="feeMax" placeholder="Fee Max" name="Total Fee Max" />
          </mat-form-field>
          <div mat-dialog-actions class="buttons col-6 leftButton">
              <button mat-raised-button (click)="search(); $event.preventDefault();"
                  [disabled]="!searchValid()">Search</button>
          </div>
      </div>
  </form>
    <h1 class="header col-12">Product Shelf</h1>
    <kendo-grid class="col-12" #productGrid="kendoGrid"
      [kendoGridBinding]="gridData.data"
      (pageChange)="pageChange($event)" 
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="'menu'"
      [columnMenu]="true"
      [filter]="state.filter"
      [groupable]="true"
      [group]="state.group"
    >
    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem>
        <button mat-icon-button class="fs-button" (click)='dlFactsheet(dataItem)'>
          <mat-icon>insert_drive_file</mat-icon>
        </button>
        <button mat-icon-button class="fs-button" (click)='createNewCase(dataItem)'>
          <mat-icon>open_in_new</mat-icon>
        </button>
        <mat-checkbox
  
        [ngModelOptions]="{standalone: true}"
        (click)='compare(dataItem);'>
      </mat-checkbox>
      </ng-template>
      <!-- [(ngModel)]="compareChecked[x.name]" -->
    </kendo-grid-column>
    <ng-template ngFor [ngForOf]="gridData.headers" let-column>
        <kendo-grid-column [field]="column.DataField" [hidden]="isHidden(column.DataField)" [title]="column.Label" [sortable]="column.Sort"
          [filterable]="column.Filter" [groupable]="column.Group" [media]="column.RespVis">
          </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoGridDetailTemplate
        let-dataItem
        *ngIf="gridData['subHead']"
      >
  
      <!--  -->
      <!-- Have to make specific tabs to force order and not have multiple rate summary sections show up and teh ability to show messages for empty datasets -->
      <!-- Good code is commented out below... -->
      <!--  -->
        <mat-tab-group mat-stretch-tabs class="w-100">
          <ng-container *ngFor="let tab of gridData.subHead | keyvalue">
            <mat-tab *ngIf="tab.key == 'riders'" label="Income Riders">
              <table class="w-100 detail-table" *ngIf="dataItem[tab.key].length > 0">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No riders were found for this annuity based on your firm's product offering</h4>
            </mat-tab>
            <mat-tab *ngIf="tab.key == 'deathRiders'" label="Death Benefit Riders">
              <table class="w-100 detail-table" *ngIf="dataItem[tab.key].length > 0">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No death benefit riders were found for this annuity based on your firm's product offering</h4>
            </mat-tab>
            <mat-tab *ngIf="tab.key == 'fiaRates' && dataItem.annuityType.toLowerCase() == 'ia'" label="Rates Summary">
              <table class="w-100 detail-table" *ngIf="dataItem[tab.key].length > 0">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No rates were found for this annuity based on your firm's product offering</h4>
            </mat-tab>
            <mat-tab *ngIf="tab.key == 'faRates' && dataItem.annuityType.toLowerCase() == 'fa'" label="Rates Summary">
              <table class="w-100 detail-table" *ngIf="dataItem[tab.key].length > 0">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No rates were found for this annuity based on your firm's product offering</h4>
            </mat-tab>
            <mat-tab *ngIf="tab.key == 'viaRates' && dataItem.annuityType.toLowerCase() == 'rila'" label="Rates Summary">
              <table class="w-100 detail-table" *ngIf="dataItem[tab.key].length > 0">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No rates were found for this annuity based on your firm's product offering</h4>
            </mat-tab>
            <!-- <mat-tab *ngIf="dataItem.annuityType.toLowerCase() == 'va'" label="Rates Summary">
              <h4 class="w-100 text-center my-4" *ngIf="dataItem[tab.key].length < 1">No rates were found for this annuity based on your firm's product offering</h4>
            </mat-tab> -->
            <mat-tab *ngIf="tab.key == 'funds' && dataItem[tab.key].length > 0" label="Investment Options">
              <table class="w-100 detail-table">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table>
            </mat-tab>
              <!-- <ng-template mat-tab-label [ngSwitch]="tab.key">
                <ng-container *ngSwitchCase="'riders'">Income Riders</ng-container>
                <ng-container *ngSwitchCase="'deathRiders'">Death Benefit Riders</ng-container>
                <ng-container *ngSwitchCase="'faRates'">Rate Summary</ng-container>
                <ng-container *ngSwitchCase="'fiaRates'">Rate Summary</ng-container>
                <ng-container *ngSwitchCase="'viaRates'">Rate Summary</ng-container>
                <ng-container *ngSwitchCase="'funds'">Investment Options</ng-container>
              </ng-template>
              <table class="w-100 detail-table">
                <thead>
                  <ng-container *ngFor="let header of tab.value">
                    <th *ngIf="!header.Hidden">
                      {{ header.Label }}
                    </th>
                  </ng-container>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataItem[tab.key]">
                    <ng-container *ngFor="let header of tab.value">
                    <td *ngIf="!header.Hidden">
                      <ng-container
                        *ngIf="header.DataField == 'ScoreClass'; else normal"
                      >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'positive'
                          "
                          class="positive"
                          >check_circle</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'neutral'
                          "
                          class="neutral"
                          >error</mat-icon
                        >
                        <mat-icon
                          *ngIf="
                            product.ScoreClass &&
                            product.ScoreClass.toLowerCase() == 'negative'
                          "
                          class="negative"
                          >remove_circle</mat-icon
                        >
                      </ng-container>
                      <ng-template #normal>
                        {{ product[header.DataField] }}
                      </ng-template>
                    </td>
                  </ng-container>
                  </tr>
                </tbody>
              </table> -->
          </ng-container>
        </mat-tab-group>
      </ng-template>
    </kendo-grid>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
  