import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-message',
  styleUrls: ['form-message.component.scss'],
  template: `
    <label class="section col-12 {{to.messageType}}" *ngIf="props.label" [innerHtml]="props.label"></label>
  `
})
export class FormlyMessage extends FieldType { }
