import { Injectable } from '@angular/core';

@Injectable()
export class TransferService {
  private cpNeedsData;
  private seProfList;

  constructor() { }

  setNeedsData(data) {
    this.cpNeedsData = data;
  }

  getNeedsData() {
    return this.cpNeedsData;
  }

  setProfList(data) {
    this.seProfList = data;
  }

  getProfList() {
    return this.seProfList;
  }
}
