import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  Form,
  FormControl,
  UntypedFormGroup,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-amount-modal',
  templateUrl: './amount-modal.component.html',
  styleUrls: ['./amount-modal.component.scss'],
})
export class AmountModalComponent implements OnInit {
  form: FormGroup;
  dollarMask;
  finalAssetsRemaining = 0;
  initialAmount = 0;
  available = 0;

  constructor(
    private dialogRef: MatDialogRef<AmountModalComponent>,
    private msk: MaskService,
    public changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dollarMask = this.msk.dollarMaskSpecs();
  }

  ngOnInit() {
    const formGroup = {};
    this.initialAmount =
      this.data.amount === '' ? null : this.currencyToFloat(this.data.amount);
    this.finalAssetsRemaining = this.data.finalAssetsRemaining;
    this.available = this.finalAssetsRemaining;
    formGroup['amount'] = new FormControl(
      this.initialAmount,
      Validators.required
    );
    this.form = new UntypedFormGroup(formGroup);
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  currencyToFloat(value): number {
    if (typeof value !== 'number') {
      if (
        value === '' ||
        value === '$' ||
        value === null ||
        value === undefined
      ) {
        return 0;
      }
      const stripped = value.replace(/\$/gi, '').replace(/,/gi, '');
      return Number.parseFloat(stripped);
    }
    return value;
  }

  onChange() {
    const amount = this.currencyToFloat(this.form.controls['amount'].value);
    this.updateAvailableAssets(amount);
  }

  updateAvailableAssets(amount: number) {
    this.available = this.finalAssetsRemaining + this.initialAmount - amount;
    if (this.available < 0) {
      this.form.controls['amount'].setErrors({ max: true });
    }
    this.changeDetector.detectChanges();
  }

  save() {
    const amount = this.currencyToFloat(this.form.controls['amount'].value);
    this.updateAvailableAssets(amount);
    if (this.form.valid) {
      this.dialogRef.close({ data: this.form.get('amount').value });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }
}
