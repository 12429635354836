import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordDialogComponent } from '../password-dialog/password-dialog.component';
import { ErrorReportingService } from '../services/error-reporting.service';
import { AttestDialogComponent } from '../attest-dialog/attest-dialog.component';
import { EnvironmentService } from '../services/environment.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  failed = false;
  loginError: string;
  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  rights;
  hasAttested = true;
  env;
  loading = false;

  constructor(
    private dialog: MatDialog,
    private rbs: RightBridgeApiService,
    public route: Router,
    private ss: SessionStorageService,
    private error: ErrorReportingService,
    private envSvc: EnvironmentService,
    private zone: NgZone
  ) {
    this.envSvc.loadSettings();
    this.env = this.envSvc.get();
  }

  login() {
    this.loading = true;
    let modalState;

    if (!this.env) {
      this.envSvc.loadSettings();
      this.env = this.envSvc.get();
    }

    if (this.username.valid && this.password.valid) {
      // this.rbs.setParams();
      this.rbs
        .login({ name: this.username.value, pass: this.password.value })
        .subscribe(
          data => {
            this.rbs.getGlobals().subscribe(z => {
              this.loading = false;
              this.rights = this.ss.get('rights');
              this.hasAttested = this.ss.get('globals').user.hasAttested;
              if (data.passwordChangeRequired) {
                modalState = 'passwordChange';
              } else if (
                !this.hasAttested &&
                this.rights.includes('LicenseAttest')
              ) {
                modalState = 'attestRequired';
              }

              if (modalState === 'passwordChange') {
                this.resetPassword();
              } else if (modalState === 'attestRequired') {
                this.attestRequired();
              } else {
                const app =
                  this.ss.get('startPage') || this.ss.get('currentApp') || 'pp';
                const dashboard = this.rights.includes('NewDashboardTab');
                this.failed = false;

                if (dashboard) {
                  this.zone.run(() => {
                    this.route.navigate([`/dashboard`]);
                  });
                } else {
                  this.route.navigate([``]);
                }
              }
            });
          },
          error => {
            this.loading = false;
            this.failed = true;
            this.loginError = error.result;
          }
        );
    }
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      panelClass: 'password-dialog',
      data: { headline: 'Forgot Password', forgot: true, reset: false },
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('@@@@',result);
      // if (result.data != 'cancel') {
      //   let data = { "InvestmentWizard.ShareClassNote": result.data };
      // }
    });
  }

  resetPassword() {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      panelClass: 'password-dialog',
      data: { headline: 'Reset Password', forgot: false, reset: true },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data != 'cancel') {
        const app =
          this.ss.get('startPage') || this.ss.get('currentApp') || 'pp';
        const dashboard = this.rights.includes('NewDashboardTab');
        this.failed = false;

        this.failed = false;
        if (!this.hasAttested && this.rights.includes('LicenseAttest')) {
          this.attestRequired();
        } else {
          if (dashboard) {
            this.route.navigate([`/dashboard`]);
          } else if (app.toLowerCase() === 'rb') {
            this.route.navigate([``]);
          } else {
            this.route.navigate([`/${app}`]);
          }
        }
      } else {
        this.rbs.endSession().subscribe();
      }
    });
  }

  attestRequired() {
    const dialogRef = this.dialog.open(AttestDialogComponent, {
      panelClass: 'attest-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data != 'cancel') {
        const app =
          this.ss.get('startPage') || this.ss.get('currentApp') || 'pp';
        const dashboard = this.rights.includes('NewDashboardTab');
        this.failed = false;
        if (dashboard) {
          this.route.navigate([`/dashboard`]);
        } else {
          this.route.navigate([`/${app}`]);
        }
      } else {
        this.rbs.endSession().subscribe();
      }
    });
  }
}
