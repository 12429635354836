export const tradeInfoKey = [
  { label: 'Client Name', varName: 'ClientFullName' },
  { label: 'Rep Code', varName: 'RepCode' },
  { label: 'Unit', varName: 'UnitID' },
  { label: 'Trade Date', varName: 'TradeDate' },
  {
    label: 'Side',
    varName: 'Side',
    key: {
      B: 'Buy',
      S: 'Sell',
    },
  },
  {
    label: 'Status',
    varName: 'ApprovalStatus',
    key: {
      PEN: 'Pending',
      APP: 'Approved',
      SYS: 'System Approved',
      BLK: 'Bulk Approved',
      INP: 'In Process',
      REJ: 'Rejected',
    },
  },
];
export const productInfoKey = [
  { label: 'Product Type', varName: 'ProductType' },
  { label: 'Product Code', varName: 'Symbol' },
  { label: 'Name', varName: 'ProductName' },
  { label: 'Initial Premium', varName: 'Amount', valueMask: 'currency' },
  {
    label: 'Commission ($)',
    varName: 'CommissionDollars',
    valueMask: 'currency',
  },
];
export const suitabilityKey = [
  { label: 'Entity Type', varSet: 'Client', varName: 'Type' },
  { label: 'Age', varSet: 'ClientPerson', varName: 'Age' },
  {
    label: 'Employment Status',
    varSet: 'ClientPerson',
    varName: 'EmploymentStatus',
  },
  { label: 'Gender', varSet: 'ClientPerson', varName: 'Gender' },
  {
    label: 'NetWorth',
    varSet: 'ClientFinancial',
    varName: 'NetWorth',
    valueMask: 'currency',
  },
  {
    label: 'Tax Bracket',
    varSet: 'ClientFinancial',
    varName: 'TaxBracket',
    valueMask: 'percent',
  },
  { label: 'Risk Tolerance', varSet: 'Client', varName: 'RiskTolerance' },
  { label: 'Time Horizon', varSet: 'Client', varName: 'TimeHorizon' },
];
export const accountKey = [
  { label: 'Account #', varName: 'AccountNumber' },
  { label: 'Balance', varName: 'Balance' },
  { label: 'Money Market', varName: 'MoneyMarket' },
  { label: 'Name', varName: 'Name' },
  { label: 'Nature Source', varName: 'NatureSource' },
  { label: 'Plan Type', varName: 'PlanType' },
  { label: 'Registration', varName: 'Registration' },
];
export const tradePositionKey = [
  { label: 'Account ID', varName: 'AccountIDDisplay' },
  { label: 'Symbol', varName: 'Symbol' },
  { label: 'Name', varName: 'Name' },
  { label: 'Share Class', varName: 'ShareClass' },
  { label: '# Shares', varName: 'Quantity' },
  { label: 'Total Market Value', varName: 'MarketValue' },
  { label: 'Carrier', varName: 'Carrier' },
  { label: 'Last Purchase Date', varName: 'AcquisitionDate' },
];
export const annuityPositionKey = [
  { label: 'Carrier', varName: 'Carrier' },
  { label: 'Product Code', varName: 'ContractCode' },
  { label: 'Name', varName: 'Name' },
  { label: 'Type', varName: 'Type' },
  { label: 'Share Class', varName: 'ShareClass' },
  { label: 'Term', varName: 'Term' },
  { label: 'Purchase Date', varName: 'PurchaseDate' },
  { label: 'Current Value', varName: 'CurrentValue' },
];
export const associatedCaseKey = [
  { label: 'Case ID', varName: 'CaseID' },
  { label: 'Score Class', varName: 'ScoreClass' },
  { label: 'Date Generated', varName: 'DateGenerated' },
];
export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
