import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportingService } from '../../../../reporting/reporting.service';
import * as moment from 'moment';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-unit-history-download-modal',
  templateUrl: './unit-history-download-modal.component.html',
  styleUrls: ['./unit-history-download-modal.component.scss'],
})
export class UnitHistoryDownloadModalComponent {
  @Input() baseUrl;

  startDate = new Date(moment().subtract(30, 'days').format('MM/DD/YYYY'));
  endDate = new Date();
  loading = false;

  constructor(
    private rptSvc: ReportingService,
    private pltfrm: Platform,
    private dialogRef: MatDialogRef<UnitHistoryDownloadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  downloadUnitHistoryReport() {
    this.endDate.setDate(this.endDate.getDate() + 1);
    // Adding one day to the endDate to get records on the original endDate itself.
    // (the generated data previously would not include data from the original endDate)
    // This may need to move to the backend but this is a quick fix for now.
    const start = moment.utc(this.startDate).toISOString();
    const end = moment.utc(this.endDate).toISOString();
    if (this.data.unitManagement) {
      this.rptSvc
        .getUnitManagementChanges(this.data.unit, start, end)
        .subscribe(resp => {
          this.saveReport(resp, start, end, 'management');
        });
    } else {
      this.rptSvc
        .getUnitProfileChanges(this.data.app, this.data.unit, start, end)
        .subscribe(resp => {
          this.saveReport(resp, start, end, 'history');
        });
    }
  }

  saveReport(resp, start, end, fileCategory) {
    const filename = `${this.data.unit}_${start}_${end}_${fileCategory}_report.pdf`;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    let fileData;
    const reader = new FileReader();

    reader.readAsDataURL(resp);
    reader.onloadend = () => {
      fileData = reader.result;
      saveAs(fileData, filename, {
        proxyURL: `${this.baseUrl}/util/proxy`,
        proxyTarget: '_self',
        forceProxy: iosSafari,
      });
      this.close();
    };
  }

  close() {
    this.dialogRef.close({ data: 'cancel' });
  }
}
