<mat-form-field class="col-12 col-md-6">
  <!-- <mat-icon matPrefix>library_books</mat-icon> -->
  <mat-select [placeholder]="placeholder" (selectionChange)="templateChosen($event);" [disabled]="!firmTemplates && !templates  || (firmTemplates && firmTemplates.length > 1) && (templates && templates.length > 1)">
    <ng-container *ngIf="firmTemplates">
      <mat-optgroup label="Firm Templates">
        <mat-option *ngFor="let tmplt of firmTemplates" [value]="tmplt">
          {{tmplt.name}}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-container *ngIf="templates">
      <mat-optgroup label="User Templates">
        <mat-option *ngFor="let tmplt of templates" [value]="tmplt">
          {{tmplt.name}}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
  <mat-hint></mat-hint>
</mat-form-field>
