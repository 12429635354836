<div class="reports-container page-container mt-5">
  <app-utility-toolbar
    [current]="'reporting'"
    [vertical]="true"
    class="w-10 float-left vertical"
  ></app-utility-toolbar>
  <mat-tab-group
    class="w-90 float-left"
    #tabGroup
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Unit Settings Report">
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Unit Settings Report</h4>
        <div class="mt-2 ml-3">
          <app-unit-settings [baseUrl]="baseUrl"></app-unit-settings>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Active Users">
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Active Users</h4>
        <div class="mt-2 ml-3">
          <app-active-users [baseUrl]="baseUrl"></app-active-users>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Load History" *hasPermission="'LoadHistory'">
      <h4 class="col-12 mt-5">Load History</h4>
      <ng-template matTabContent>
        <app-load-history></app-load-history>
      </ng-template>
    </mat-tab>
    <mat-tab label="Unit Profile History" *hasPermission="'UnitProfileHistory'">
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Unit Profile History</h4>
        <div class="mt-2 ml-3">
          <app-unit-profile-history
            [baseUrl]="baseUrl"
          ></app-unit-profile-history>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Unit Usage" *hasPermission="'systemUnitUsageReport'">
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Usage Report</h4>
        <div class="mt-2 ml-3">
          <app-unit-usage></app-unit-usage>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Bridge Action" *hasPermission="'BridgeActionDetailReport'">
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Bridge Action Report</h4>
        <div class="mt-2 ml-3">
          <app-bridge-action></app-bridge-action>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab
      label="Bridge Density Report"
      *hasPermission="'BridgeDensityReport'"
    >
      <ng-template matTabContent>
        <h4 class="col-12 mt-5">Bridge Density Report</h4>
        <div class="mt-2 ml-3">
          <app-bridge-density></app-bridge-density>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab
      label="Sales Tracking Report"
      *hasPermission="'SalesTrackingReport'"
    >
      <ng-template matTabContent>
        <h4 class="mt-5 col-12">Sales Tracking Report</h4>
        <div class="mt-2 ml-3">
          <app-sales-tracking></app-sales-tracking>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Mutual Fund Report" *hasPermission="'MutualFundReport'">
      <ng-template matTabContent>
        <h4 class="mt-5 col-12">Mutual Fund Report</h4>
        <div class="mt-2 ml-3">
          <app-mutual-funds [baseUrl]="baseUrl"></app-mutual-funds>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="ReasonText™ Report" *hasPermission="'ReasonTextReport'">
      <ng-template matTabContent>
        <div class="row col-12">
          <h4 class="col-12 mt-5">ReasonText™ Report</h4>
          <div class="mt-2 ml-3">
            <app-reason-text-report
              [baseUrl]="baseUrl"
            ></app-reason-text-report>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      label="Rep Exceptions Report"
      *hasPermission="'RepExceptionsReport'"
    >
      <ng-template matTabContent>
        <div class="row col-12">
          <h4 class="col-12 mt-5">Rep Exceptions Report</h4>
          <div class="mt-2 ml-3">
            <app-rep-exceptions-report
              [baseUrl]="baseUrl"
            ></app-rep-exceptions-report>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
