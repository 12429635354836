import { Component, ViewContainerRef, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-inputExp',
  styleUrls: ['form-inputExp.component.scss'],
  template: `
    <label class="section col-12" *ngIf="props.label" [innerHTML]="props.label"></label>
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field floatPlaceholder="auto" class="w-100"  >
    <mat-label>{{to.placeholder}}</mat-label>
      <input matInput
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        (change)="props.change ? to.change(field, formControl):''"
        type="{{to.number == true ? 'number' : ''}}"
        min="{{to.min}}" max="{{to.max}}"
      >
      <mat-icon *ngIf="props.help" class="help-icon" matTooltip="{{to.help}}" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'">help</mat-icon>
      <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
    </mat-form-field>
  `
})
export class FormlyCustomInputExp extends FieldType {
}
