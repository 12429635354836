import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RmdCalculationComponent } from './rmd-calculation/rmd-calculation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { HighchartsChartModule } from 'highcharts-angular';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  declarations: [RmdCalculationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    HighchartsChartModule,
    GridModule,
    NgxLoadingModule,
    SharedComponentsModule,
    MaskitoDirective,
  ],
})
export class RmdCalculationModule {}
