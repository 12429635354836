<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Callbacks / Follow Up </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    <div class="no-results" *ngIf="state === 'none'">
      <h4>No Opportunities Available</h4>
    </div>
    <!-- <div class="row list-links" >
      <button mat-button class="call-list lists" *ngIf="showCallList" (click)="callListSearch()">
        <mat-icon>contact_phone</mat-icon>{{callListNumber}} clients in your call list.
      </button>
      <button mat-button class="call-back lists" *ngIf="showCallBack">
        <mat-icon>phone_callback</mat-icon>{{callListNumber}} callbacks in the next 7 days.
      </button>
    </div> -->
    <div class="title-buttons justify-content-between">
      <strong *ngIf="showCallBack">Callbacks</strong>
      <button
        mat-button
        class="call-back lists"
        *ngIf="showCallBack"
        (click)="callBackSearch()"
      >
        <mat-icon>phone_callback</mat-icon>See your callbacks (Last 7 days)
      </button>
    </div>
    <table *ngIf="showCallBack">
      <thead>
        <th>Name</th>
        <th>Call Score</th>
        <th>Call Type</th>
        <th>Call Date</th>
      </thead>
      <tbody>
        <tr *ngFor="let c of callbackList" (click)="goToProfile($event, c.id)">
          <td>{{ c.name }}</td>
          <td>{{ c.score }}</td>
          <td>{{ c.type }}</td>
          <td>{{ c.date }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title-buttons justify-content-between">
      <strong>Follow Ups</strong>
      <button
        mat-button
        class="call-list lists"
        *ngIf="showCallList"
        (click)="callListSearch()"
      >
        <mat-icon>contact_phone</mat-icon>See your call list
      </button>
    </div>
    <table>
      <thead>
        <th>Name</th>
        <th>Suggestion</th>
        <th>Detail</th>
      </thead>
      <tbody>
        <tr *ngFor="let c of followupList" (click)="goToProfile($event, c.id)">
          <td>{{ c.name }}</td>
          <td>{{ c.suggestion }}</td>
          <td>{{ c.detail }}</td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
