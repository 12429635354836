<mat-card appearance="outlined" class="col-12 stretch case-activity">
  <mat-card-header>
    Case Statistics Summary
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12 mb-3 toolbar">
      <mat-form-field>
        <mat-label>Solution</mat-label>
        <mat-select [(ngModel)]="solution" (selectionChange)="updateData()">
          <mat-option *ngFor="let sltn of solutions" [value]="sltn.value">{{sltn.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group [(ngModel)]="assessmentType" (change)="updateData()" class="ml-2">
        <mat-button-toggle value="VALIDATE">Validate</mat-button-toggle>
        <mat-button-toggle value="SELECT">Select</mat-button-toggle>
        <mat-button-toggle value="ALL">Both</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field class="pull-right" *ngIf="!limit30">
        <input matInput [matDatepicker]="endPicker" placeholder="Choose an end date" [formControl]="endDate" (dateChange)="updateData()">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pull-right mr-2" *ngIf="!limit30">
        <input matInput [matDatepicker]="startPicker" placeholder="Choose a start date" [formControl]="startDate" (dateChange)="updateData()">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="button-row">
      <button mat-button (click)="goBack()" *ngIf="unitList && unitList.length >= 1">
        <mat-icon>arrow_back</mat-icon> Go back to

        <ng-container *ngIf="unitList.length > 1; else base_user">{{unitList[unitList.length - 1].UnitName}}</ng-container>
        <ng-template #base_user>{{unitList[0].UnitName}}</ng-template>

      </button>
    </div>
    <div class="col-12 data-container">
      <!-- <pre>{{data | json}}</pre> -->
      <kendo-grid class="col-12 case-activity-grid" #resultsGrid="kendoGrid"
        [kendoGridBinding]="data"
        scrollable="none"
        (cellClick)="handleRowClick($event)"

        >
        <kendo-grid-column field="UserName" title="User" *ngIf="level == 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.UserName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="UnitName" title="Unit" *ngIf="level != 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="{'active': dataItem.Units >= 1}">
              {{dataItem.UnitName}}
              <mat-icon *ngIf="dataItem.Units >= 1">trending_flat</mat-icon>
          </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="UsersWithCases" title="# of Users" *ngIf="level != 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="{'active': dataItem.Users >= 1}">
              {{dataItem.UsersWithCases}}
              <mat-icon *ngIf="dataItem.Users >= 1">trending_flat</mat-icon>
          </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Cases" title="# of Cases"  [headerClass]="'header'"></kendo-grid-column>
        <kendo-grid-column field="Products" title="# of Products"  [headerClass]="'header'"></kendo-grid-column>
        <kendo-grid-column field="PositivePct" title="Greens" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.Positive}}   |   {{dataItem.PositivePct}}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="NeutralPct" title="Yellows" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.Neutral}}   |   {{dataItem.NeutralPct}}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="NegativePct" title="Reds"  [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.Negative}}   |   {{dataItem.NegativePct}}%
          </ng-template>
        </kendo-grid-column>


      </kendo-grid>
    </div>

  </mat-card-content>
  <ngx-loading [show]="loading" [config]="{fullScreenBackdrop: false}"></ngx-loading>
</mat-card>
