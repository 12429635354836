import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ConditionalFormModule } from '../conditional-form/conditional-form.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

//Kendo Imports
import { GridModule } from '@progress/kendo-angular-grid';

import { ProductResultComponent } from './product-result/product-result.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HighchartsChartModule } from 'highcharts-angular';

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';

import { PpRouting } from './product-profiler-routing.module';
import { CopyDialogComponent } from '../copy-dialog/copy-dialog.component';
import { PlanLookupComponent } from './plan-lookup/plan-lookup.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PpRouting,
        FormsModule,
        ReactiveFormsModule,
        ConditionalFormModule,
        MatButtonModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatCardModule,
        MatRadioModule,
        MatInputModule,
        MatCheckboxModule,
        MatListModule,
        MatDialogModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatTooltipModule,
        MatAutocompleteModule,
        NgxDatatableModule,
        GridModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
            fullScreenBackdrop: true,
            backdropBorderRadius: '0',
            primaryColour: 'rgb(38, 91, 151)',
            secondaryColour: 'rgba(38, 91, 151, 0.2)',
            tertiaryColour: '#265b97'
        }),
        SharedComponentsModule,
        HighchartsChartModule,
        MatDatepickerModule
    ],
    declarations: [
        ProductResultComponent,
        PlanLookupComponent,
    ],
    providers: [
        RightBridgeApiService,
        AuthService,
        // { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class ProductProfilerModule { }
