<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Top Sales/Service Opportunities
  </mat-card-header>
  <mat-card-content>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
    <div class="no-results" *ngIf="state == 'none'">
      <h4>No Opportunities Available</h4>
    </div>
    <table *ngIf="state == 'loaded'">
      <thead>
        <th>Suggestion</th>
        <th class="text-center"># Relevant</th>
        <th class="text-center">Avg Score</th>
        <th class="text-center">Avg Rank</th>
      </thead>
      <tbody>
        <tr *ngFor="let brd of bridges;" (click)="goToSearch($event, brd.id)">
          <td>{{brd.name}}</td>
          <td class="text-center">{{brd.relevant}}</td>
          <td class="text-center">{{brd.score}}</td>
          <td class="text-center">{{brd.rank}}</td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>