<div mat-dialog-header>
  <h1>Generate Printed Report</h1>
</div>
<mat-dialog-content class="dialog-container row">
  <form [formGroup]="form" class="col-12">
    <mat-form-field class="w-100">
      <input
        matInput
        formControlName="filename"
        [placeholder]="'Custom Filename'"
      />
      <mat-hint align="start">{{ hintText }}</mat-hint>
    </mat-form-field>
    <ng-container *ngIf="!hideDetails">
      <mat-checkbox class="mr-5" formControlName="details">
        <span *ngIf="app !== 'aw' && app !== 'pp'"
          >Include fund details in report</span
        >
        <span *ngIf="app === 'aw' || app === 'pp'"
          >Include Alternatives ReasonText</span
        >
      </mat-checkbox>
    </ng-container>
    <ng-container *ngIf="!hideVaDetails && app === 'aw'">
      <mat-checkbox *hasPermission="'ShowVAFunds'" formControlName="vaDetails"
        >Include VA fund options in report</mat-checkbox
      >
    </ng-container>
    <ng-container
      *ngIf="includeNotes && (app === 'li' || app === 'rw' || app === 'pp')"
    >
      <mat-form-field class="w-100">
        <textarea
          class="w-100"
          matInput
          formControlName="notes"
          [placeholder]="notesPlaceholder"
          rows="5"
        ></textarea>
        <mat-hint align="start" [innerHTML]="beforePrintPrompt"></mat-hint>
      </mat-form-field>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()"
    >Generate Report</button
  >
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
