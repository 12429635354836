import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { Router} from '@angular/router';
import { FormsModule, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { SessionStorageService }  from '../services/session-storage.service'
import { CopyDialogComponent  } from "../copy-dialog/copy-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { UniversalSearchModule } from "../universal-search/universal-search.module";


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  dataSource = [];
  public search = '';
  searchStr: string;
  loaded = false;
  currApp;
  loading: boolean = false;
  showCopy: boolean = false;
  searchControl = new FormControl();
  filteredOptions;
  temp = [];
  searchValue: string = null;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private rbs: RightBridgeApiService, public router: Router, private ss: SessionStorageService, public dialog: MatDialog) {}

  ngOnInit() {
    // this.getData();
  }

  private _filter(value): string[] {
    const filterValue = typeof value === 'string' || value instanceof String ? value.toLowerCase() : value;
    let results = [];

    for(let i=0; i<this.dataSource.length; i++) {
      if(this.dataSource[i].Name.toLowerCase().includes(filterValue) || this.dataSource[i].caseNumber.includes(filterValue) || this.dataSource[i].caseNumber.toLowerCase().includes(filterValue)) {
        results.push(this.dataSource[i])
      }
    }

    return results;
  }

  getData() {
    this.rbs.profileSearch('%').subscribe(data => {
      let parsed = data,
        rowsRaw = parsed.Data,
        columnsRaw = parsed.Columns;
      this.rows = rowsRaw;

      // console.log(this.rows);
      this.dataSource = rowsRaw;


      this.filteredOptions = rowsRaw.length < 200 ? this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        ) : [];

      // rowsRaw.forEach(e => {
      //   this.filteredOptions.push(e.Name);
      // });
      this.temp = rowsRaw;
      this.columns = columnsRaw.filter(x => {
        if (x.trim() == 'FirstName' || x.trim() == 'LastName') {
          return false
        } else {
          return true
        }
      }).map((d, i) => {
        // let key = i === 0 ? 'prop' : 'name',
        // let key = 'name',
        let entry = { 'name': '', 'prop': '' };

        entry.name = d == 'LastInteractionDate' ? 'Last Interaction Date' : d;
        entry.prop = d;

        return entry;
      });

      // console.log(this.columns, this.rows);

    });
    let rights = this.ss.get('rights');
    this.showCopy = rights.includes('CopyProfile', 'SwapProfile','DeleteProfile')

    this.loaded = true;
  }

  handleSelect(result) {
    if (result.option.value) {
      this.search = result.option.value.Name;
      this.rows = this.rows.filter(x => { return x.Name == result.option.value.Name || x.caseNumber == result.option.value.caseNumber });
    } else {
      this.rows = this.dataSource;
      this.searchControl.setValue('')
    }
  }

  displayFn(option?) {
    if(option && option.caseNumber) {
      return option.caseNumber + ' - ' + option.Name;
    } else if (option && !option.caseNumber) {
      return option.Name
    } else {
      return null;
    }

  }

  searchClosed() {
    if(!this.searchControl || !this.searchControl.value) {
      this.rows = this.dataSource;
    }
  }

  onSelect(e) {
    let id = e.id;
    this.currApp = this.ss.get('currentApp');
    this.router.navigate(['/' + this.currApp + '/questionnaire/' + id]);
  }

  delete(id) {
    this.rbs.deleteProfile(id).subscribe(x => {
      // console.log(x);
      this.getData();
    });
    event.stopPropagation();
  }

  copy(id) {
    let dialogRef = this.dialog.open(CopyDialogComponent, { panelClass: 'copy-dialog', data: {headline: 'Copy Profile'} });
    this.loading = true;
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result.data && result.data != "cancel") {
        let desc = result.data;
        this.rbs.copyProfile(id, desc).subscribe(x => {
          // console.log(x);
          this.getData();
        });
      }
    });
    event.stopPropagation();
  }

  swap(id) {
    let dialogRef = this.dialog.open(CopyDialogComponent, { panelClass: 'copy-dialog', data: { headline: 'Copy Profile and Reverse Spouses' } });
    this.loading = true;
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      if (result.data && result.data != "cancel") {
        let desc = result.data;
        this.rbs.swapProfile(id, desc).subscribe(x => {
          // console.log(x);
          this.getData();
        });
      }
    });
    event.stopPropagation();
  }

  clearSearch() {
    this.searchValue = null;
    this.rows = this.dataSource;
  }

  updateFilter(event, input) {
    const val = input.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(d => {
      return d.Name.toLowerCase().indexOf(val) !== -1 || d.caseNumber.includes(val) || d.caseNumber.toLowerCase().includes(val) || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
