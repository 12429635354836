<div mat-dialog-header class="header">
  <h2 class="w-100">You may not access or use the RightBridge Software unless you have read and accepted the license
    agreement.</h2>
  <p class="sub">The License Agreement can be read in window below. You may download a copy of the Agreement for your review and for your files.</p>
</div>
<div mat-dialog-content class="dialog-container">

  <div class="license w-100">
    <p class="top"><strong>RightBridge</strong><br><strong>License Agreement</strong><br>CapitalRock, LLC<br>45 East Center Street<br>North Salt Lake, Utah 84054</p>
    <p>CapitalRock, LLC ("CapitalRock") is engaged in the business of providing online software solutions to its customers.
    Customer desires a license to access and use the Licensed Software described below. This license is for the term agreed
    to in the license agreement and begins on the date this Agreement was first accepted by Customer or the date that
    Customer first accessed or used the Licensed Software. Number of Users: 1 individual person (the "Customer")</p>
    <h4>Terms and Conditions</h4>

    <ol>
      <li>
        <strong>Section 1 - Use of Licensed Software</strong>
        <ol>
          <li>Licensed Software. The "Licensed Software" will mean the Annuity Wizard version of CapitalRock's RightBridge computer
          program.</li>
          <li>License. Subject to the terms and conditions of this Agreement and Customer's compliance with them, CapitalRock grants
          to Customer a license to access and use the Licensed Software (the "License"). The Licensed Software will be resident on
          CapitalRock computers. Customer will access and use the Licensed Software from customer's computers through compatible
          web browsing software resident on customer's computers.</li>
          <li>License Term. The License is effective only for License Term. The License will terminate at the end of Licensing Term.
          Upon termination of the License, all licenses and rights of Customer under this Agreement will terminate and all access
          to and use of the Licensed Software, and Documentation by Customer will terminate.</li>
          <li>Licensed Software Updates and New Versions. The License includes any maintenance fixes, patches, and updates to and new
          versions of Licensed Software that CapitalRock elects to install on the CapitalRock computers.</li>
          <li>No Rights to Code. Customer is not entitled to receive any copy of any of the Licensed Software in any form (source
          code, object code or other form). The License is strictly limited to remote access via the Internet as described above.</li>
          <li>Security. Customer shall be responsible to maintain adequate controls and system security requirements and devices to
          ensure that there is no unauthorized or improper access or use of Licensed Software or CapitalRock computers or
          violation of data privacy or confidentiality from, by or through any equipment, computers, networks, communication links
          or devices, offices, facilities or employees, of Customer. CapitalRock will not be responsible or liable for any
          unauthorized or improper access to or use of the Licensed Software or any Customer Data where such access or use
          originates outside of CapitalRock computers. Customer will also be responsible for implementing and maintaining virus
          detection, quarantine, and eradication capabilities and other similar protections for its computers and systems. These
          capabilities and protections are not provided by CapitalRock.</li>
          <li>No Services or Support. CapitalRock is not obligated to provide any services or support to Customer. However, if and to
          the extent that CapitalRock elects to do so, CapitalRock may make telephone support and e-mail support available to
          Customer to support Customer's licensed use of the Licensed Software.</li>
          <li>License Fee. The license fee charged for use of RightBridge has been paid by Raymond James for the Licensing Term agreed
          to by the parties.</li>
        </ol>
      </li>
      <li>
        <strong>Section 2 - Data and Confidentiality</strong>
        <ol>
          <li>Customer Data. "Customer Data" means the data of Customer that are uploaded by Customer to CapitalRock's Computers as
          part of the licensed use of Licensed Software. Such Customer Data will be kept confidential by CapitalRock . It is
          Customer's responsibility to maintain its own adequate back-ups of such Customer Data. Customer is responsible for the
          accuracy, integrity, completeness and content of Customer Data.</li>
          <li>Confidentiality of CapitalRock Information. Customer will keep confidential, and will not use for any purpose other than
          this Agreement, any information disclosed by CapitalRock to Customer about, or that is learned or observed by Customer
          from, the technologies, methodologies, equipment, software and processes used by CapitalRock as well as the Licensed
          Software, Client Software, and Documentation. Customer does not have any obligation of confidentiality under this
          paragraph with respect to information that is publicly known or becomes publicly known through no disclosure, act or
          negligence of Customer.</li>
        </ol>
      </li>
      <li>
        <strong>Section 3 - Disclaimers and Limitations</strong>
        <ol>
          <li>Disclaimer. CAPITALROCK MAKES NO WARRANTY, REPRESENTATION OR PROMISE NOT EXPRESSLY SET FORTH IN THIS AGREEMENT. EXCEPT
          AS EXPRESSLY WARRANTED IN THIS AGREEMENT, THE LICENSED SOFTWARE, DOCUMENTATION, AND SERVICES ARE PROVIDED ON AN "AS IS"
          BASIS. CAPITALROCK DISCLAIMS AND EXCLUDES ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NONINFRINGEMENT.</li>
          <li>Limitation on Liability. CAPITALROCK'S AGGREGATE LIABILITY ARISING FROM OR RELATING TO THIS AGREEMENT OR THE LICENSED
          SOFTWARE, LICENSE, OR DOCUMENTATION (REGARDLESS OF THE FORM OF ACTION OR CLAIM E.G. CONTRACT, WARRANTY, TORT,
          MALPRACTICE, AND/OR OTHERWISE) WILL NOT EXCEED $100. CAPITALROCK WILL NOT IN ANY CASE BE LIABLE FOR ANY SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, INDIRECT, EXEMPLARY, OR PUNITIVE DAMAGES OR FOR LOSS OF PROFITS. REVENUE, OR BUSINESS, EVEN
          IF CAPITALROCK HAS BEEN ADVISED OF THE POSSIBILITY THEREOF. CAPITALROCK IS NOT RESPONSIBLE FOR LOSS OF USE OF ANY
          WEBSITE, INTERNET ACCESS, HARDWARE OR SOFTWARE, LOSS OF DATA, COSTS OF RE CREATING LOST DATA, THE COST OF ANY SUBSTITUTE
          PERFORMANCE, EQUIPMENT, SOFTWARE, OR SYSTEM, OR CLAIMS BY ANY PARTY OTHER THAN CUSTOMER. THIS AGREEMENT, AND SECTION 3
          IN PARTICULAR, DEFINES A MUTUALLY AGREED UPON ALLOCATION OF RISK.</li>
          <li>Conditions of Use. Customer acknowledges that the Licensed Software is a complex software application and that Customer
          is cautioned and expected to verify any results or work product obtained through use of the Licensed Software.
          CapitalRock will not have any liability for any representation, warranty or condition, express or implied, with respect
          to any products or services offered, sold or provided to others, directly or indirectly by Customer. The Licensed
          Software is intended to be used as an analytical tool by competent and qualified professionals and are not a substitute
          for or intended for use independent of professional advice.</li>
          <li>Advice and Data Exclusion. CAPITALROCK DOES NOT OFFER INVESTMENT OR FINANCIAL ADVICE OR RECOMMENDATIONS. IF CUSTOMER
          DOES OFFER ADVICE OR RECOMMENDATION IT IS CUSTOMER'S SOLE RESPONSIBILITY, NOT THAT OF CAPITALROCK. CUSTOMER AGREES THAT
          CAPITALROCK IS NOT A "FINANCIAL INSTITUTION" UNDER THE GRAMM-LEACH-BLILEY ACT. THE LICENSED SOFTWARE IS INTENDED TO BE
          USED AS AN ANALYTICAL TOOL AND IS NOT A SUBSTITUTE FOR OR INTENDED FOR USE INDEPENDENT OF PROFESSIONAL ADVICE.
          CAPITALROCK MAKES NO REPRESENTATION OR WARRANTY REGARDING ANY PARAMETER, RULE OR SCORING (OR SETTINGS OR VALUES THEREOF)
          MADE BY CUSTOMER OR ANY USER. UNDER NO CIRCUMSTANCES SHALL CAPITALROCK BE LIABLE TO CUSTOMER OR ANY OTHER PERSON FOR THE
          SUITABILITY, ACCURACY OR COMPLETENESS OF THE RESULTS OF, OR WORK PRODUCT OBTAINED FROM, USE OF THE LICENSED SOFTWARE, OR
          DOCUMENTATION. UNDER NO CIRCUMSTANCES SHALL CAPITALROCK BE LIABLE FOR THE SUITABILITY, ACCURACY, SEQUENCE, TIMELINESS OR
          COMPLETENESS OF ANY INFORMATION (INCLUDING, BUT NOT LIMITED TO, THIRD PARTY DATA) PROVIDED BY OR PROCESSED BY THE
          LICENSED SOFTWARE, FOR ANY INCONVENIENCE, EXPENSE OR DAMAGE CAUSED BY THE LOSS OF SUCH INFORMATION OR THIRD PARTY DATA,
          OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN.</li>
          <li>Other Limitations. The warranties made by CapitalRock in this Agreement, and the obligations of CapitalRock under this
          Agreement, run only to Customer and not to any other persons. Under no circumstances shall any other person be
          considered a third party beneficiary of this Agreement or otherwise entitled to any rights or remedies under this
          Agreement. Customer shall have no rights or remedies against CapitalRock except as specifically provided in this
          Agreement.</li>
          <li>No Unlawful or Disreputable Purpose. Customer warrants that Customer will not access or use any of the Licensed
          Software, Documentation, for any unlawful, dishonest, disreputable, illegitimate, or immoral purpose. Any breach of this
          warranty will entitle CapitalRock to terminate this Agreement immediately.</li>
          <li>No Obligation to Indemnify. CapitalRock has no obligation to indemnify or hold harmless.</li>
        </ol>
      </li>
      <li>
        <strong>Section 4 - General Provisions</strong>
        <ol>
          <li>Governing Law and Forum. This Agreement will be governed by the laws of the state of Utah without giving effect to
          conflict or choice of law principles. Any litigation between the Parties will be conducted exclusively in a state or
          federal court of competent jurisdiction within Utah and such court's appellate courts. The Parties agree and submit to
          such exclusive jurisdiction and venue.</li>
          <li>Force Majeure. Neither Party will be deemed in breach of this Agreement or liable for any failure to perform an
          obligation where such failure is caused by an Act of God, fire, flood, earthquake, storm, terrorism, war, crime, change
          in law or regulation, any disruption, outage or malfunction of or interference in communications, network, equipment or
          software, act of any military, civil or regulatory authority, the Internet, any third party, any disruption or delay in
          supplies, power, or other utilities, any labor dispute or shortage, or circumstances beyond the control of that Party.
          It is also understood that downtime of CapitalRock computers and Licensed Software for maintenance, re-location, and
          other purposes will be necessary from time to time and that unintended interruptions and unscheduled downtime may also
          occur and are not a breach of this Agreement.</li>
          <li>Waiver. Any waiver under this Agreement must be in writing and any waiver of one event will not be construed as a waiver
          of subsequent events.</li>
          <li>Construction. This Agreement represents the wording selected by the Parties to define their agreement and no rule of
          strict construction will apply against or in favor of either Party.</li>
          <li>Relationship. Neither Party is the partner, joint venturer, agent or representative of the other Party.</li>
          <li>USA Use Only. The License, all use by Customer of the Licensed Software, Documentation, and Client Software, and all
          rights of Customer, are limited to the United States and may only be exercised within the United States.</li>
          <li>Injunctive and other Equitable Relief. Each party acknowledges that the restrictions and protections in this Agreement
          relating to the protection of Licensed Software, Documentation, Customer Data and confidential information are
          reasonable and necessary to protect the other Party's legitimate business and intellectual property interests. Each
          party acknowledges that any breach of any such restrictions will result in irreparable injury to the other Party for
          which money damages could not adequately compensate. If there is a breach, then the injured party shall be entitled, in
          addition to all other rights and remedies which it may have at law or in equity, to have a decree of specific
          performance and preliminary and permanent injunctions issued by any court of competent jurisdiction, requiring the
          breach to be cured and enjoining all persons involved from continuing the breach. The existence of any claim or cause of
          action that a Party or any other person may have against the other Party shall not constitute a defense or bar to the
          enforcement of any of the provisions of this Section 4.8.</li>
          <li>Entire Agreement. This Agreement: (i) represents the entire agreement between the Parties relating to the subject matter
          of this Agreement.</li>
        </ol>
      </li>
    </ol>
  </div>
  <form [formGroup]="form" class="col-12">
    <mat-form-field class="w-30">
      <input matInput type="text" [(ngModel)]="usersName" placeholder="Please enter your name" required formControlName="nameInput"/>
      <mat-error>This field is required</mat-error>
    </mat-form-field>
  </form>
  <p>
      If you accept this Agreement, click or activate the ACCEPT button below. By doing
      so, you are representing to us that you are over 21 years of age and have the capacity
      to enter into this Agreement. By accepting this Agreement, a binding contract is
      formed between you and CapitalRock, LLC.
  </p>
  <p>
      If you do not accept this Agreement, click or activate the NO THANKS button below.
  </p>
</div>
<mat-dialog-actions class="row buttons w-100 pb-3">
  <a mat-button class="print-button" color="primary" target="_blank" [href]="docUrl" download><mat-icon>insert_drive_file</mat-icon>Download license agreement</a>
  <button mat-raised-button (click)="close()">No Thanks</button>
  <button mat-raised-button color="accent" (click)="accept()">Accept</button>
</mat-dialog-actions>
