import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { EnvironmentService } from '../../services/environment.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

@Component({
  selector: 'program-selection',
  templateUrl: './program-selection.component.html',
  styleUrls: ['./program-selection.component.scss'],
})
export class ProgramSelectionComponent implements OnInit {
  @Output() navigate = new EventEmitter();
  @Output() progSelected = new EventEmitter();

  private environment;
  id: string;
  chartData;
  tableData = [];
  columns = [];
  rows = [];
  catData = {};
  programs = [];
  modelData = { name: '' };
  clientName;
  selProgram: string;
  scoring = { neutral: [], negative: [], positive: [] };
  path: string;
  assmntTypes = [];
  loading = false;
  breadcrumb = 'program';
  selectionType;
  validationCase = false;
  internalLoading = false;
  globals;

  constructor(
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService,
    private iws: InvestmentWizardService,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.path = this.environment.assets ? this.environment.assets : '';

    this.params.params.subscribe(params => {
      this.id = params.id;
    });

    this.globals = this.ss.get('globals') || {};

    this.scoring = this.globals.scoring ? this.globals.scoring : this.scoring;

    if (this.scoring['neutral'][0] != this.scoring['negative'][1]) {
      this.scoring['negative'][1] = this.scoring['neutral'][0];
    }
    if (this.scoring['neutral'][1] != this.scoring['positive'][0]) {
      this.scoring['positive'][0] = this.scoring['neutral'][1];
    }

    this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: true });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initData();
    }, 250);
  }

  initData() {
    const save = this.unfFlowSvc.getSaving();
    this.unfFlowSvc.setLoading(true);
    if (save) {
      setTimeout(() => {
        this.initData();
      }, 250);
    } else {
      this.setLoading(true);
      this.getCats(this.id);
    }
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'fund');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  selectFund(name, program) {
    // console.log(this.selProgram, '@@@@', programId, programId != this.selProgram, this.selProgram != 'none');
    if (program.id != this.selProgram && this.selProgram != 'none') {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'If you choose to switch fund programs all your previous fund selections will be cleared.',
          confirm: 'OK',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          this.progSelected.emit({
            program: program.id,
            selectionType: program.selectionType,
          });
          this.clearSelectedFunds(program);
        }
      });
    } else {
      this.rbs
        .saveProfile(this.id, {
          'InvestmentWizard.SelectedProgram': program.id,
        })
        .subscribe(x => {
          this.progSelected.emit({
            program: program.id,
            selectionType: program.selectionType,
          });
          this.pageNavigate(program);
        });
    }
  }

  clearSelectedFunds(program) {
    this.internalLoading = true;
    this.rbs.selectFunds(this.id, null, true).subscribe(data => {
      this.ss.remove('selectedFunds');
      this.rbs
        .saveProfile(this.id, {
          'InvestmentWizard.SelectedProgram': program.id,
          'InvestmentWizard.IsFinalized': false,
          'InvestmentWizard.FilteredFundFamilies': null,
        })
        .subscribe(x => {
          this.internalLoading = false;
          this.pageNavigate(program);
        });
    });
  }

  getCats(profile): any {
    this.setLoading(true);
    this.iws.getPrograms(profile).subscribe(data => {
      const iwVars = data.InvestmentWizard,
        finalData = [];

      this.selProgram = iwVars.SelectedProgram || 'none';
      this.programs = data.bridges.map(d => {
        const bullets = d.outline.sections.DescriptionBullets
          ? d.outline.sections.DescriptionBullets.split('~')
          : [];
        const desc = d.outline.sections.ShortDescription || '';
        if (!this.assmntTypes.includes(d.assessmenttype)) {
          this.assmntTypes.push(d.assessmenttype);
        }

        return {
          id: d.ProgramID,
          name: d.outline.sections.ShortName || d.name,
          score: d.score / 10,
          selected: d.ProgramID == this.selProgram,
          reasons: d.trueRules,
          description: desc || null,
          bulletList: bullets || null,
          selectionType: d.FundSelectionType,
          assessmentType: d.assessmenttype,
        };
      });
      this.assmntTypes.sort().reverse();
      this.breadcrumb = this.assmntTypes.includes('validate')
        ? 'program-validate'
        : this.breadcrumb;
      this.validationCase = this.assmntTypes.includes('validate');

      this.clientName = `${data.ClientPerson.FirstName} ${data.ClientPerson.LastName}`;

      if (iwVars.SelectedProgram) {
        this.progSelected.emit({
          program: iwVars.SelectedProgram,
          selectionType: data.SelectedProgramData.FundSelectionType,
        });
      }

      this.setLoading(false);
    });
  }

  pageNavigate(program) {
    // let url;
    let step;

    if (program.selectionType.toLowerCase() == 'advisory') {
      // url = `/case/iw/${this.id}/advisory`;
      step = 'validate';
    } else if (program.assessmentType == 'validate') {
      // url = `/case/iw/${this.id}/fund-allocations`;
      step = 'fund-allocations';
    } else {
      // url = `/case/iw/${this.id}/investment-model`;
      step = 'investment-model';
    }

    // this.route.navigate([url]);
    this.navigate.emit(step);
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }
}
