import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-radio-exp',
  styleUrls: ['form-radioExp.component.scss'],
  template: `
    <label
      class="section"
      class="w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
    >
      <span [innerHTML]="props.label"></span>
      <sup *ngIf="props.required">*</sup>
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
    </label>
    <p
      *ngIf="props.prompt"
      class="explanation"
      [class.mat-error]="formControl.errors && formControl.touched"
    >
      <span [innerHTML]="props.prompt"></span>
      <mat-icon
        *ngIf="props.help && !to.label && !to.placeholder"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
    </p>
    <p
      *ngIf="props.placeholder"
      class="italic"
      [class.mat-error]="formControl.errors && formControl.touched"
    >
      <span [innerHTML]="props.placeholder"></span>
      <span *ngIf="props.required && !to.label"> *</span>
      <mat-icon
        *ngIf="props.help && !to.label && !to.prompt"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
    </p>

    <mat-radio-group
      class="radio-group radio-group-explanation col-12 questionnaire"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
      (change)="props.change ? to.change(field, formControl) : ''"
    >
      <mat-radio-button
        class="radio-button"
        *ngFor="let opt of to.options"
        [value]="buttonValue(opt)"
      >
        {{ opt.label }}
      </mat-radio-button>
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          formControl.touched &&
          !formControl.hasError('regRequired')
        "
      >
        This field is required
      </mat-error>
      <mat-error
        *ngIf="formControl.hasError('regRequired') && formControl.touched"
      >
        This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.
      </mat-error>
    </mat-radio-group>
  `,
})
export class FormlyRadioExpComponent extends FieldType {
  @HostBinding('class') classes = 'bottom-margin';
  buttonValue(option) {
    if (this.formControl.value === null && option.value === false) {
      return null;
    } else if (option.value === false) {
      return false;
    } else if (option.value === true) {
      return true;
    } else {
      return option.value;
    }
  }
}
