import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
// import { RmdStore } from './rmd.store';
// import { Rmd } from './rmd.model';

@Injectable({ providedIn: 'root' })
export class RmdService {

  constructor(private rbs: RightBridgeApiService,
              private http: HttpClient) {
  }

  getRmdCalc(data) {
    this.rbs.setApp('aw');
    return this.rbs.postRequest(`${this.rbs.baseUrl}/GroupProfile?groupName=RMDCalc`, data);
  }

  // get() {
  //   this.http.get('https://akita.com').subscribe((entities) => this.rmdStore.set(entities));
  // }

  // add(rmd: Rmd) {
  //   this.rmdStore.add(rmd);
  // }

  // update(id, rmd: Partial<Rmd>) {
  //   this.rmdStore.update(id, rmd);
  // }

  // remove(id: ID) {
  //   this.rmdStore.remove(id);
  // }
}
