<div class="page-container summary-container container">
  <breadcrumb [crumb]="'summary'"></breadcrumb>
  <h1 class="header col-12">Client Summary for {{data.client.name}}</h1>
  <!-- <div class="w-100 toolbar">
    <button mat-raised-button class="report" (click)="report()" *ngIf="!hidePrint">
      <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon>Printable report
    </button>
  </div> -->
  <!-- {{realData.ShortName}} -->
  <div class="buttons row top">
    <!-- <a mat-raised-button *ngIf="externalLink" target="_blank" href="{{externalLink?.link}}" color="primary">
      <mat-icon>link</mat-icon>{{externalLink?.label}}
    </a> -->
    <button mat-raised-button class="combined-button">
      <span class="mat-button-wrapper">
        <mat-icon>insert_drive_file</mat-icon>Download Combined Report
      </span>
    </button>
  </div>
  <div class="summary">
    <ng-container *ngIf="data.applications.aw">
      <h1>Annuity Wizard</h1>
      <div class="app-container">
        <div class="w-100 product" *ngFor="let x of data.applications.aw.tracks">
          <h2 [className]="x.status">{{x.type}}
            <button mat-button *ngIf="x.status == 'completed'"><mat-icon>insert_drive_file</mat-icon>Download Report</button>
            <div class="status" *ngIf="x.status == 'partial'"><mat-icon>av_timer</mat-icon>Needs to be Completed<span>|</span><button mat-button>Complete <mat-icon>arrow_forward</mat-icon></button></div>
            <div class="status" *ngIf="x.status == 'notStarted'"><mat-icon>error</mat-icon>Not Started<span>|</span><button mat-button>Begin <mat-icon>arrow_forward</mat-icon></button></div>
          </h2>
          <ul class="aw col-12 row">
            <li *ngFor="let prod of x.selectedProducts; let idx = index;">
              <span class="type-indicator {{prod.productType.toLowerCase()}}">{{prod.productType}}</span> {{prod.carrier}} {{prod.product}} | {{prod.premium | currency}}
            <!-- <div class="rating-line {{x.scoreClass}}" [style.width.%]="x.score/10"></div> -->
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.applications.iw">
      <h1>Investment Wizard</h1>
      <div class="app-container">
        <div class="row">
          <div class="info-container col-10">
            <h2 class="info"><mat-icon>assessment</mat-icon>Investment Model: <span>{{data.applications.iw.investmentModel}}</span></h2>
            <h2 class="info"><mat-icon>assignment</mat-icon>Program: <span>{{data.applications.iw.investmentProgram}}</span></h2>
            <h2 class="info"><mat-icon>account_balance_wallet</mat-icon>Investment Amount: <span>{{data.applications.iw.investmentAmount | currency}}</span></h2>
          </div>
          <div class="action-container col-2">
            <button mat-button>
              <mat-icon>insert_drive_file</mat-icon>Download Report
            </button>
          </div>
        </div>
        <ng-container *ngFor="let fundClass of data.applications.iw.selectedFunds">
          <div class="fundClass">
            <h2>{{fundClass.assetClass}}<span class="amount pr-3">Amount Allocated: {{fundClass.amount | currency}}</span></h2>
            <ul class="iw col-12 row">
              <li *ngFor="let prod of fundClass.funds; let idx = index;">
                <div class="w-100 pr-3 pl-3">
                  <div class="w-100" *ngIf="!fundClass.postponed; else postponed">
                    {{prod.name}}<span class="amount">{{prod.amount | currency}}</span>
                  </div>
                  <ng-template #postponed>Funds to Selected at a Later Date</ng-template>
                  <!-- <span *hasPermission="'!RelevanceHideBridgeScore'"> | {{x.score / 10}}%</span> -->
                </div>
              </li>
            </ul>
          </div>
        </ng-container>

      </div>
    </ng-container>
    <ng-container *ngIf="data.applications.li">
      <h1>Life Insurance Wizard</h1>
      <div class="app-container">
        <div class="row">
          <div class="info-container col-10">
            <h2 class="info">
              <mat-icon>supervisor_account</mat-icon>Total Need: <span>{{data.applications.li.totalNeed | currency}}</span>
            </h2>
            <h2 class="info">
              <mat-icon>pie_chart</mat-icon>Product Mix: <span>{{data.applications.li.productMix.perm | percent}}</span> Permanent,
              <span>{{data.applications.li.productMix.temp | percent}}</span> Temporary
            </h2>
          </div>
          <div class="action-container col-2">
            <button mat-button>
              <mat-icon>insert_drive_file</mat-icon>Download Report
            </button>
          </div>
        </div>
        <div class="product">
          <h2>Products Selected</h2>
            <ul class="li col-12 row">
              <li *ngFor="let product of data.applications.li.selectedProducts">
                <div class="w-100 pr-3">
                  <span class="type-indicator {{product.type.toLowerCase()}}">{{product.type.toUpperCase()}}</span>{{product.name}}<span class="amount">{{product.amount | currency}}</span>
                </div>
              </li>
            </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="buttons row bottom">
    <!-- <a mat-raised-button *ngIf="externalLink" target="_blank" href="{{externalLink?.link}}" color="primary"><mat-icon>link</mat-icon>{{externalLink?.label}}</a> -->
    <button mat-raised-button class="combined-button">
      <span class="mat-button-wrapper">
        <mat-icon>insert_drive_file</mat-icon>Download Combined Report
      </span>
    </button>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div> 