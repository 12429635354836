import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { CaseManagementService } from '../case-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionStorageService } from '../../services/session-storage.service';
import { EnvironmentService } from '../../services/environment.service';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-case-management-modal',
  templateUrl: './case-management-modal.component.html',
  styleUrls: ['./case-management-modal.component.scss'],
})
export class CaseManagementModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CaseManagementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cms: CaseManagementService,
    private snacky: MatSnackBar,
    private ss: SessionStorageService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private dialog: MatDialog
  ) {}

  editCaseTitle = false;
  caseTitle = '';
  fileName;
  fileNameDisplay;
  file;
  fileType;
  caseDetails = {};
  message = '';
  messages = [];
  editMessageId = null;
  editMessage = '';
  selectedCaseFile = null;
  caseFiles = null;
  statusOptions = [];
  caseProfile = {};
  loading = false;
  caseUsers = [];
  noteTypes = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
  ];
  noteType = 1;
  editNoteType = 1;
  isCaseManager = false;
  canDeleteFiles = false;
  baseUrl: string;
  environment;
  caseHistoryData = [];
  caseHistoryHeaders = [
    { fieldName: 'AssignedToName', label: 'Assigned To' },
    { fieldName: 'CreatedDate', label: 'Created Date' },
    { fieldName: 'DateLastUpdated', label: 'Updated' },
    { fieldName: 'SavedByName', label: 'Saved By' },
    { fieldName: 'StatusName', label: 'Status Name' },
    { fieldName: 'Title', label: 'Title' },
    { fieldName: 'Type', label: 'Type' },
  ];
  showCaseHistory = false;
  changesMade = false;

  ngOnInit() {
    this.loading = true;
    this.getCaseManagementProfile(this.data.CRID);
    this.getCaseRecordNotes(this.data.CRID);
    this.getCaseRecordFilesList(this.data.CRID);
    this.getCaseManagementHistory(this.data.CRID);
    const rights = this.ss.get('rights');
    this.isCaseManager = rights && rights.includes('CaseManager');
    this.canDeleteFiles = rights && rights.includes('DeleteCMDocument');
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  getCaseManagementProfile(id) {
    this.cms.getCaseManagementProfile(id).subscribe(resp => {
      this.caseProfile = resp;
      this.caseTitle = resp['CaseManagement']['Title'];
      this.caseDetails = resp['CaseManagement'];
      this.statusOptions = resp['ActiveStatuses'];
      if (resp['Client_Rep']['RepCode'][0]) {
        this.getCaseManagementUsers(resp['Client_Rep']['RepCode'][0]);
      }
      this.loading = false;
    });
  }

  getCaseManagementUsers(repCode) {
    this.cms.getCaseManagementUsers(repCode).subscribe(resp => {
      this.caseUsers = resp['Users'];
    });
  }

  getCaseRecordFilesList(id) {
    this.cms.listCaseRecordFiles(id).subscribe(resp => {
      this.caseFiles = resp['data'];
    });
  }

  getCaseManagementHistory(id) {
    this.cms.getCaseManagementHistory(id).subscribe(resp => {
      this.caseHistoryData = resp['CaseManagementHistory'];
      this.showCaseHistory = true;
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  getCaseRecordFile(caseFile) {
    this.loading = true;
    this.cms.getCaseRecordFile(caseFile.id).subscribe(resp => {
      const filename = `${caseFile.Name}.${caseFile.Type}`;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        this.loading = false;
      };
    });
  }

  deleteCaseRecordFile(caseFile) {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content: `Are you sure you want to delete ${caseFile.Name}.${caseFile.Type}?`,
        confirm: 'Yes',
        hideCancel: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'continue') {
        this.cms.deleteCaseRecordFile(caseFile.id).subscribe(() => {
          this.getCaseRecordFilesList(this.data.CRID);
        });
      }
      dialogRef = null;
    });
  }

  getCaseRecordNotes(id) {
    this.cms.getCaseRecordNotes(id).subscribe(resp => {
      this.messages = resp['data'];
      this.scrollToBottom();
    });
  }

  submitNote() {
    const newNote = {
      CaseRecordId: this.data['CRID'],
      NoteText: this.message,
      NoteTypeId: this.noteType === 1 ? 1 : this.noteType,
    };

    this.cms.addCaseRecordNote(newNote).subscribe(() => {
      this.getCaseRecordNotes(this.data.CRID);
      this.message = '';
    });
  }

  editNote(note) {
    this.editMessageId = note.NoteId;
    this.editMessage = note.NoteText;
    this.editNoteType = note.NoteTypeId * 1;
  }

  submitEdit() {
    const saveNote = {
      CaseRecordId: this.data['CRID'],
      NoteText: this.editMessage,
      NoteTypeId: this.editNoteType,
      NoteId: this.editMessageId,
    };
    this.cms.editCaseRecordNote(saveNote).subscribe(() => {
      this.getCaseRecordNotes(this.data.CRID);
      this.message = '';
    });
    this.editMessageId = null;
  }

  scrollToBottom() {
    const chatContainer = document.querySelector('.chat-messages-container');
    const oldScrollPostion = chatContainer.scrollHeight;
    setTimeout(() => {
      chatContainer.scrollTop = oldScrollPostion;
    });
  }

  changeValue() {
    this.changesMade = true;
  }

  isArray(value) {
    return Array.isArray(value);
  }

  saveCaseChanges() {
    this.loading = true;
    const caseDetails = this.caseProfile;

    const assignedToName =
      this.caseUsers.find(
        user => user.UserID === this.caseProfile['CaseManagement']['AssignedTo']
      )?.UserName || null;

    caseDetails['CaseManagement']['AssignedToName'] = assignedToName;

    this.cms
      .saveCaseManagementRecord(this.data.CRID, caseDetails)
      .subscribe(() => {
        this.snacky.open('Saved!', 'Close', {
          duration: 3000,
        });
        this.message = '';
        this.getCaseRecordNotes(this.data.CRID);
        this.changesMade = false;
        this.loading = false;
      });
  }

  completeFileUpload() {
    this.loading = true;
    const formData = new FormData();
    formData.append('File1', this.file);
    this.fileName = '';

    this.cms.saveCaseRecordFile(this.data.CRID, formData).subscribe(() => {
      this.getCaseRecordNotes(this.data.CRID);
      this.getCaseRecordFilesList(this.data.CRID);
      this.message = '';
      this.snacky.open(
        'File uploaded, please allow some time for it to appear in your new Case Management record.',
        'Close',
        {
          duration: 3000,
        }
      );

      this.loading = false;
    });
  }

  saveCaseTitle() {
    this.caseProfile['CaseManagement']['Title'] = this.caseTitle;
    this.saveCaseChanges();
    this.toggleEditCaseTitle(false);
  }

  cancelEditCaseTitle() {
    this.caseTitle = this.caseProfile['CaseManagement']['Title'];
    this.toggleEditCaseTitle(false);
  }

  toggleEditCaseTitle(value) {
    this.editCaseTitle = value;
  }

  close() {
    if (!this.changesMade) {
      this.dialogRef.close({ data: 'cancel' });
    } else {
      let warnDialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: `Leave without saving your changes?`,
          confirm: 'Yes',
          hideCancel: false,
        },
      });

      warnDialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          this.dialogRef.close({ data: 'cancel' });
        }
        warnDialogRef = null;
      });
    }
  }
}
