import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { AwComparisonComponent } from '../unified-flow/aw-comparison/aw-comparison.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { AppGlobalsService } from '../services/app-globals.service';
import { RmdCalculationComponent } from '../rmd-calculation/rmd-calculation/rmd-calculation.component';
import { ProductShelfComponent } from './product-shelf/product-shelf.component';

const routes: Routes = [
  {
    path: 'aw',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      {
        path: 'product-shelf',
        component: ProductShelfComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'product-shelf/:viewOption',
        component: ProductShelfComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'product-shelf/:viewOption/:cusip',
        component: ProductShelfComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'product-shelf/:viewOption/:session/:org',
        component: ProductShelfComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'product-shelf/:viewOption/:session/:org/:cusip',
        component: ProductShelfComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'rila',
        redirectTo: '/aw/product-shelf/rila',
        pathMatch: 'full',
      },
      {
        path: 'rila/:cusip',
        redirectTo: '/aw/product-shelf/rila/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'rila/:session/:org',
        redirectTo: '/aw/product-shelf/rila',
        pathMatch: 'full',
      },
      {
        path: 'rila/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/rila/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fia',
        redirectTo: '/aw/product-shelf/fia',
        pathMatch: 'full',
      },
      {
        path: 'fia/:cusip',
        redirectTo: '/aw/product-shelf/fia/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fia/:session/:org',
        redirectTo: '/aw/product-shelf/fia',
        pathMatch: 'full',
      },
      {
        path: 'fia/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/fia/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fa',
        redirectTo: '/aw/product-shelf/fa',
        pathMatch: 'full',
      },
      {
        path: 'fa/:cusip',
        redirectTo: '/aw/product-shelf/fa/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'fa/:session/:org',
        redirectTo: '/aw/product-shelf/fa',
        pathMatch: 'full',
      },
      {
        path: 'fa/:session/:org/:cusip',
        redirectTo: '/aw/product-shelf/fa/:cusip',
        pathMatch: 'full',
      },
      {
        path: 'compare-results',
        component: AwComparisonComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
          breadcrumb: 'Compare Results',
        },
      },
      {
        path: 'lookup',
        component: ProductSearchComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      {
        path: 'factsheets',
        redirectTo: '/aw/lookup',
        pathMatch: 'full',
      },
      {
        path: 'rmd-calculation',
        component: RmdCalculationComponent,
        canActivate: [AuthService],
        data: {
          appNeeded: 'AW',
        },
      },
      // THE FOLLOWING ARE OLD ROUTES THAT HAVE BEEN
      // REDIRECTED TO THE LANDING PAGE
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'search',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'results/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'help',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'proxy',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AwRouting {}
