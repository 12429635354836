import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  template: `
    <mat-checkbox [formControl]="formControl" [formlyAttributes]="field" (change)="change()" [required]="props.required"><span [innerHTML]="props.label"></span></mat-checkbox>
    <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
  `
})
export class FormlyCheckbox extends FieldType {
  change() {
    //   this.field.model[this.field.key] = !this.field.model[this.field.key];
  }
}
