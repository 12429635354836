<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    System Usage
    <button
      mat-button
      color="white"
      class="download"
      (click)="exportExcel()"
      [disabled]="!selectedUnit"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12">
      <mat-form-field>
        <input
          matInput
          placeholder="Select a Unit"
          [matAutocomplete]="unitAuto"
          [formControl]="unitCtrl"
          [(ngModel)]="selectedUnit"
        />
        <mat-autocomplete
          #unitAuto="matAutocomplete"
          (optionSelected)="selectUnit($event)"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let unit of filteredUnits | async"
            [value]="unit"
            >{{ unit.name }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <mat-button-toggle-group
        [(value)]="viewMode"
        name="toggle"
        class="ml-2"
        (change)="getChart()"
      >
        <mat-button-toggle value="chart">Chart</mat-button-toggle>
        <mat-button-toggle value="data">Data</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field
        class="pull-right"
        *ngIf="viewMode === 'data' && !limit30"
      >
        <input
          matInput
          [matDatepicker]="endPicker"
          placeholder="Choose an end date"
          [formControl]="endDate"
          (dateChange)="getData(true)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        class="pull-right mr-2"
        *ngIf="viewMode === 'data' && !limit30"
      >
        <input
          matInput
          [matDatepicker]="startPicker"
          placeholder="Choose a start date"
          [formControl]="startDate"
          (dateChange)="getData()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pull-right" *ngIf="viewMode === 'chart'">
        <mat-label>Select a Time Period</mat-label>
        <mat-select [(ngModel)]="months" (selectionChange)="getChart()">
          <mat-option [value]="0">1 Month</mat-option>
          <mat-option [value]="2">3 Months</mat-option>
          <mat-option [value]="5">6 Months</mat-option>
          <mat-option [value]="11">12 Months</mat-option>
          <mat-option [value]="17">18 Months</mat-option>
          <mat-option [value]="23">24 Months</mat-option>
          <mat-option [value]="29">30 Months</mat-option>
          <mat-option [value]="35">36 Months</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!this.selectedUnit">Please Select a Unit to to See Data.</div>

    <div
      class="col-12 chart-container mt-3"
      *ngIf="viewMode === 'chart' && this.selectedUnit"
    >
      <highcharts-chart
        *ngIf="chart.data"
        [Highcharts]="Highcharts"
        [options]="chart.data"
        class="col-6 pull-left"
      ></highcharts-chart>
      <div class="chart-legend col-6 pull-left">
        <ng-container *ngIf="chart.legend">
          <table class="col-12">
            <thead>
              <tr>
                <ng-container *ngFor="let th of chart.legend.header">
                  <th *ngIf="th.toLowerCase() !== 'color'">
                    {{ th.replace('_', ' ') }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tr of chart.legend.data">
                <ng-container *ngFor="let key of chart.legend.header">
                  <td>
                    {{ tr[key] }}
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
    <div
      class="col-12 data-container mt-3"
      [hidden]="viewMode === 'chart' && this.selectedUnit"
    >
      <kendo-grid
        class="w-100 case-stats-activity-grid"
        #usageGrid="kendoGrid"
        [kendoGridBinding]="data.data"
        [style.maxHeight.px]="500"
        *ngIf="data"
      >
        <kendo-grid-column
          *ngFor="let col of data.header"
          [field]="col.DataField"
          [title]="col.Label"
        ></kendo-grid-column>
        <kendo-grid-excel fileName="system-usage.xlsx"></kendo-grid-excel>
      </kendo-grid>
    </div>
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
