<div class="client-info-container">
  <h1 class="header col-12">
    <mat-icon
      class="status-indicator"
      *ngIf="isProspect"
      matTooltip="Is Prospect"
    >
      person_outline</mat-icon
    >
    {{ name }}
    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" class="profile-menu">
      <mat-icon>more_vert</mat-icon>
    </button> -->
    <!-- <mat-menu #menu="matMenu" [xPosition]="'before'"> -->
    <button
      mat-button
      class="header-button"
      (click)="dlReport()"
      *ngIf="clientData"
    >
      <mat-icon>insert_drive_file</mat-icon>
      <span>Download Report</span>
    </button>
    <ng-container *ngIf="clientData">
      <button
        mat-button
        class="header-button"
        (click)="removeFromCallList()"
        *ngIf="!addToList"
      >
        <mat-icon>delete</mat-icon>
        <span>Remove From Call List</span>
      </button>
    </ng-container>
    <ng-container *ngIf="clientData">
      <button
        mat-button
        class="header-button"
        (click)="addToCallList()"
        *ngIf="addToList"
      >
        <mat-icon>call</mat-icon>
        <span>Add to Call List</span>
      </button>
    </ng-container>
    <ng-container *ngIf="clientData">
      <button
        mat-button
        class="header-button"
        (click)="editProfile()"
        *hasPermission="'ShowEditLink'"
      >
        <mat-icon>edit</mat-icon>
        <span>Edit Profile</span>
      </button>
    </ng-container>
    <button
      mat-button
      class="header-button"
      (click)="addLifeEvent()"
      *ngIf="clientData"
    >
      <mat-icon>person_add</mat-icon>
      <span>Add Life Event</span>
    </button>
  </h1>
  <!-- These are arrows for navigation between clients. Might be needed in the future. -->
  <!-- <div class="profile-nav col-12">
    <button
      mat-icon-button
      class="profile-prev"
      (click)="profNav(client, 'prev')"
      matTooltip="Previous Profile"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button
      mat-icon-button
      class="profile-next"
      (click)="profNav(client, 'next')"
      matTooltip="Next Profile"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div> -->
  <mat-tab-group
    mat-stretch-tabs
    *ngIf="info"
    [(selectedIndex)]="tabIndex"
    [ngClass]="{ open: tabsOpen }"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="w-100 inner" (click)="tabClick($event, 0)">
          <mat-icon>assignment_ind</mat-icon>
          Client Summary
        </div>
      </ng-template>
      <ul class="w-100 info-summary row">
        <!-- <li *ngFor="let n of info;"> -->
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientPerson^Age'] &&
            info['ClientPerson^Age'].display &&
            info['ClientPerson^Age'].display.length
          "
        >
          <strong> <mat-icon>cake</mat-icon> Age: </strong>
          {{ info['ClientPerson^Age'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientPerson^MaritalStatus'] &&
            info['ClientPerson^MaritalStatus'].display &&
            info['ClientPerson^MaritalStatus'].display.length
          "
        >
          <strong> <mat-icon>wc</mat-icon> Marital Status: </strong>
          {{ info['ClientPerson^MaritalStatus'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['SpousePerson^FirstName'] &&
            info['SpousePerson^FirstName'].display &&
            info['SpousePerson^FirstName'].display.length
          "
        >
          <strong> <mat-icon>face</mat-icon> Spouse Name: </strong>
          {{ info['SpousePerson^FirstName'].display }}
          {{ info['SpousePerson^LastName'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientPerson^NumberOfChidren'] &&
            info['ClientPerson^NumberOfChidren'].display &&
            info['ClientPerson^NumberOfChidren'].display.length
          "
        >
          <strong> <mat-icon>child_friendly</mat-icon> # Children: </strong>
          {{ info['ClientPerson^NumberOfChidren'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientPerson^Occupation'] &&
            info['ClientPerson^Occupation'].display &&
            info['ClientPerson^Occupation'].display.length
          "
        >
          <strong> <mat-icon>work</mat-icon> Occupation: </strong>
          {{ info['ClientPerson^Occupation'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['Client^ClientSince'] &&
            info['Client^ClientSince'].display &&
            info['Client^ClientSince'].display.length
          "
        >
          <strong> <mat-icon>people</mat-icon> Client Since: </strong>
          {{ info['Client^ClientSince'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientDN^ClientRating'] &&
            info['ClientDN^ClientRating'].display &&
            info['ClientDN^ClientRating'].display.length
          "
        >
          <strong> <mat-icon>rate_review</mat-icon> Rating: </strong>
          {{ info['ClientDN^ClientRating'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientDN^CallBackScore'] &&
            info['ClientDN^CallBackScore'].display &&
            info['ClientDN^CallBackScore'].display.length
          "
        >
          <strong>
            <mat-icon>phone_callback</mat-icon> Call Back Score:
          </strong>
          {{ info['ClientDN^CallBackScore'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['Client^ProfileCompleteness'] &&
            info['Client^ProfileCompleteness'].display &&
            info['Client^ProfileCompleteness'].display.length
          "
        >
          <strong> <mat-icon>data_usage</mat-icon> Data Completeness: </strong>
          {{ info['Client^ProfileCompleteness'].display }}
        </li>
        <!-- <li class="col-12 col-md-4" *ngIf="info['ClientLegalAddress^FullAddress'] && info['ClientLegalAddress^FullAddress'].display && info['ClientLegalAddress^FullAddress'].display.length">
          <strong>
            <mat-icon>place</mat-icon> Address:
          </strong> {{info['ClientLegalAddress^FullAddress'].display}} <br *ngIf="info['ClientLegalAddress^FullAddress2'] && info['ClientLegalAddress^FullAddress2'].display && info['ClientLegalAddress^FullAddress2'].display.length">{{info['ClientLegalAddress^FullAddress2'].display}}          
        </li> -->
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['FinancialCalc^FirmHeldAssets'] &&
            info['FinancialCalc^FirmHeldAssets'].display &&
            info['FinancialCalc^FirmHeldAssets'].display.length
          "
        >
          <strong>
            <mat-icon>account_balance</mat-icon> Firm Held Assets:
          </strong>
          {{ info['FinancialCalc^FirmHeldAssets.display'] }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['FinancialCalc^TotalAssets'] &&
            info['FinancialCalc^TotalAssets.display'] &&
            info['FinancialCalc^TotalAssets.display'].length
          "
        >
          <strong> <mat-icon>local_atm</mat-icon> Known Assets: </strong>
          {{ info['FinancialCalc^TotalAssets'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['LifeInsuranceCalc^TotalFaceAmountClient'] &&
            info['LifeInsuranceCalc^TotalFaceAmountClient'].display &&
            info['LifeInsuranceCalc^TotalFaceAmountClient'].display.length
          "
        >
          <strong> <mat-icon>security</mat-icon> Life Insurance: </strong>
          {{ info['LifeInsuranceCalc^TotalFaceAmountClient'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientFinancial^Income'] &&
            info['ClientFinancial^Income'].display &&
            info['ClientFinancial^Income'].display.length
          "
        >
          <strong> <mat-icon>money</mat-icon> Income: </strong>
          {{ info['ClientFinancial^Income'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['Client^RiskTolerance'] &&
            info['Client^RiskTolerance'].display &&
            info['Client^RiskTolerance'].display.length
          "
        >
          <strong> <mat-icon>assessment</mat-icon> Risk Tolerance: </strong>
          {{ info['Client^RiskTolerance'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['ClientFinancial^TaxBracket'] &&
            info['ClientFinancial^TaxBracket'].display &&
            info['ClientFinancial^TaxBracket'].display.length
          "
        >
          <strong> <mat-icon>pie_chart</mat-icon> Tax Bracket: </strong>
          {{ info['ClientFinancial^TaxBracket'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['Client_Rep^RepCode'] &&
            info['Client_Rep^RepCode'].display &&
            info['Client_Rep^RepCode'].display.length
          "
        >
          <strong>
            <mat-icon>perm_contact_calendar</mat-icon> Rep Code:
          </strong>
          {{ info['Client_Rep^RepCode.display'] }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['MarketCategories^Category'] &&
            info['MarketCategories^Category'].display &&
            info['MarketCategories^Category'].display.length
          "
        >
          <strong> <mat-icon>table_chart</mat-icon> Market Categories: </strong>
          {{ info['MarketCategories^Category'].display }}
        </li>
        <li
          class="col-12 col-md-4"
          *ngIf="
            info['Client^ID'] &&
            info['Client^ID'].display &&
            info['Client^ID'].display.length
          "
        >
          <strong> <mat-icon>contacts</mat-icon> Reference #: </strong>
          {{ info['Client^ID'].display }}
        </li>
      </ul>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="w-100 inner" (click)="tabClick($event, 1)">
          <mat-icon class="example-tab-icon">account_balance</mat-icon>
          Accounts
        </div>
      </ng-template>
      <!-- <pre>{{this.accounts.investment['data'] | json}}</pre> -->
      <ng-container *ngIf="this.accounts.investment['data'].length">
        <strong>Investment Accounts</strong>
        <table class="account-table">
          <thead>
            <th
              *ngFor="
                let label of this.accounts.investment['labels'] | keyvalue
              "
              >{{ label.value }}</th
            >
          </thead>
          <tbody>
            <tr *ngFor="let acct of this.accounts.investment['data']">
              <td *ngFor="let data of acct | keyvalue">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="this.accounts.annuity['data'].length">
        <strong>Annuity Holdings</strong>
        <table class="account-table">
          <thead>
            <th
              *ngFor="let label of this.accounts.annuity['labels'] | keyvalue"
              >{{ label.value }}</th
            >
          </thead>
          <tbody>
            <tr *ngFor="let acct of this.accounts.annuity['data']">
              <td *ngFor="let data of acct | keyvalue">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="this.accounts.lifeInsurance['data'].length">
        <strong>Insurance Policies</strong>
        <table class="account-table">
          <thead>
            <th
              *ngFor="
                let label of this.accounts.lifeInsurance['labels'] | keyvalue
              "
              >{{ label.value }}</th
            >
          </thead>
          <tbody>
            <tr *ngFor="let acct of this.accounts.lifeInsurance['data']">
              <td *ngFor="let data of acct | keyvalue">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="this.accounts.longTerm['data'].length">
        <strong>Long Term Care Insurance</strong>
        <table class="account-table">
          <thead>
            <th
              *ngFor="let label of this.accounts.longTerm['labels'] | keyvalue"
              >{{ label.value }}</th
            >
          </thead>
          <tbody>
            <tr *ngFor="let acct of this.accounts.longTerm['data']">
              <td *ngFor="let data of acct | keyvalue">{{ data.value }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <h4 *ngIf="noAccounts">No Accounts Have Been Entered For This Client</h4>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="w-100 inner" (click)="tabClick($event, 2)">
          <mat-icon class="example-tab-icon">description</mat-icon>
          Notes
        </div>
      </ng-template>
      <button
        mat-button
        (click)="addNote()"
        class="add-note"
        color="primary"
        *hasPermission="'SaveNotes'"
        ><mat-icon>note_add</mat-icon>Add New Note</button
      >
      <ng-container *ngIf="notes && notes.noteCount > 0; else no_notes">
        <table>
          <thead>
            <th class="date-column">Date</th>
            <th class="note-column">Note</th>
          </thead>
          <tbody>
            <tr *ngFor="let nt of notes.notes">
              <td>
                <div class="client-table-data">
                  <button
                    mat-icon-button
                    (click)="togglePin(nt)"
                    matTooltip="Note Pinned?"
                  >
                    <mat-icon
                      class="pin-icon"
                      [ngClass]="{ pinned: nt.isPinned }"
                      >star</mat-icon
                    >
                  </button>
                  <span>{{ nt.createDate | date: 'shortDate' }}</span>
                </div>
              </td>
              <td
                ><div class="client-table-data">{{ nt.noteText }}</div></td
              >
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #no_notes>
        <h4>No Notes Have Been Entered For This Client</h4>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<ngx-loading [show]="loading"></ngx-loading>
