import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { PostTradeService } from '../post-trade.service';
import {
  SortDescriptor,
  State,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { Router } from '@angular/router';
import { FormControl, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { scoreClassKey } from '../post-trade-detail/post-trade-detail-keys';

@Component({
  selector: 'app-post-trade-grid',
  templateUrl: './post-trade-grid.component.html',
  styleUrls: ['./post-trade-grid.component.scss'],
})
export class PostTradeGridComponent implements OnInit, OnDestroy {
  @ViewChild('postTradeGrid', { static: false }) postTradeGrid;

  constructor(
    public fb: FormBuilder,
    private cstmFltrSrvc: CustomFiltersService,
    public ptSvc: PostTradeService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  unsubscribe: Subject<any> = new Subject();
  loading = false;
  public hidden: string[] = [];
  public ogHidden: string[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  ogData;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
    total: 0,
  };
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  hideGrid = true;
  externalFilters = [];
  dropdownFilters = [];
  searchFilters = [];
  dateFilters = [];
  filterOptions = {};
  filterData = {};
  resetFilters = false;
  bulkActionOptions = [
    { display: 'Select All', value: 'all' },
    { display: 'Select Green', value: 'Positive' },
    { display: 'Select Yellow', value: 'Warning' },
    { display: 'Select Red', value: 'Negative' },
  ];
  selectedTradeList = [];
  selectedChecked = {};
  bulkAction = new FormControl();
  scoreClassKey = scoreClassKey;

  ngOnInit(): void {
    this.getGridSetup();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getGridSetup() {
    this.loading = true;
    this.ptSvc.getPostGridSetup().subscribe(
      resp => {
        this.gridData.headers = resp['headers'];
        this.updateExternalFilterOptions(this.gridData.headers);
        setTimeout(() => {
          this.getGridData();
          this.setColumnVisiblity();
        }, 1000);
      },
      error => {
        console.log('GRID SETUP ERROR: ', error);
        this.loading = false;
      }
    );
  }

  getGridData() {
    const queryData = {
      take: this.state.take,
      skip: this.state.skip,
      search: {},
      sort: {},
    };

    this.filter.filters.map(filter => {
      if (filter['filters']) {
        filter['filters'].map(nextFilter => {
          console.log('lwekfjelkj', nextFilter);
          if (
            queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].selected.push(nextFilter.value);
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              selected: [nextFilter['value']],
            };
          }
          if (
            queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].searchString = nextFilter.value;
          } else if (
            !queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              searchString: nextFilter['value'],
            };
          }
          if (
            queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field][nextFilter.operator] = moment
              .utc(nextFilter.value)
              .toISOString();
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {};
            queryData.search[nextFilter['field']][nextFilter.operator] = moment
              .utc(nextFilter.value)
              .toISOString();
          }
        });
      } else {
        if (
          queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']].searchString = filter['value'];
        } else if (
          !queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {
            searchString: filter['value'],
          };
        }

        if (
          !queryData.search[filter['field']] &&
          this.dateFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {};
          queryData.search[filter['field']][filter['operator']] =
            filter['value'];
        } else if (
          queryData.search[filter['field']] &&
          this.dateFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']][filter['operator']] =
            filter['value'];
        }
        // else if (
        //   queryData.search[filter['field']] &&
        //   queryData.search[filter['field']].selected
        // ) {
        // queryData.search[filter['field']].selected.push(filter['value']);
        // }
        else if (
          !queryData.search[filter['field']] &&
          typeof filter['value'] === 'object'
        ) {
          queryData.search[filter['field']] = {
            selected: filter['value'],
          };
        } else if (
          !queryData.search[filter['field']] &&
          typeof filter['value'] !== 'object'
        ) {
          queryData.search[filter['field']] = {
            selected: [filter['value']],
          };
        }
      }
    });

    this.sort.map(sortItem => {
      if (sortItem.dir !== undefined) {
        queryData.sort[sortItem.field] = sortItem.dir.toUpperCase();
      } else {
        delete queryData.sort[sortItem.field];
      }
    });

    if (isEmpty(queryData.sort)) {
      queryData.sort = {
        TradeID: 'ASC',
      };
    }

    if (isEmpty(queryData.search)) {
      delete queryData.search;
    }

    this.ptSvc.getPostTradeGridData(JSON.stringify(queryData)).subscribe(
      resp => {
        this.gridData.data = resp['results']['data'];
        this.ogData = this.gridData.data;
        this.state.skip = resp['results']['skip'];
        this.state.take = resp['results']['take'];
        this.gridData.total = resp['results']['total'];
        setTimeout(() => {
          this.buildFilterOptions();
          this.hideGrid = false;
          this.loading = false;
        }, 1000);
      },
      error => {
        this.loading = false;
        console.log('DATA ERROR: ', error);
      }
    );
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;
        this.externalFilters.push(entry);
        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        if (el.FilterType === 'Dropdown') {
          this.dropdownFilters.push(entry.fullField.DataField);
        } else if (el.FilterType === 'Search') {
          this.searchFilters.push(entry.fullField.DataField);
        } else if (el.FilterType === 'Range') {
          this.dateFilters.push(entry.fullField.DataField);
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  buildDropdownFilterOptions(el) {
    let options;
    options = this.filterOptions[el.DataField]
      ? this.filterOptions[el.DataField].map(x => {
          return { value: x, display: x };
        })
      : [];
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = el.Validation.map(x => {
        const label = x.label ? x.label : x.value;

        return { value: x.value, display: label };
      });
    } else if (!el.Validation && el.externalFilter.options) {
      options = el.externalFilter.options.map(x => {
        const label = x.display ? x.display : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridData.headers
    );

    if (this.filterOptions) {
      for (const [key, value] of Object.entries(this.filterOptions)) {
        this.filterData[key] = value;
      }
    }
  }

  public dataStateChange(state): void {
    this.loading = true;
    this.pageSize = state.take;
    this.state = state;
    this.filter = this.state.filter;
    this.sort = this.state.sort;
    this.getGridData();
  }

  updateFilters(ev) {
    this.resetFilters = false;
    this.skip = 0;
    this.filter = ev;

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.getGridData();
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridData.headers.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
  }

  setColumnVisiblity() {
    this.hidden = [];
    this.gridData.headers.forEach(x => {
      if (x.subgroup) {
        let tempGroup = [];
        if (this.gridData.subHead.has(x.subgroup)) {
          tempGroup = this.gridData.subHead.get(x.subgroup);
          tempGroup.push(x);
        } else {
          this.gridData.subHead.set(x.subgroup, []);
          tempGroup.push(x);
        }

        this.gridData.subHead.set(x.subgroup, tempGroup);
        this.gridData.headers = this.gridData.headers.filter(z => z != x);
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
        this.ogHidden.push(x.DataField);
      }
    });
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  reset() {
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.skip = 0;
    this.buildFilterOptions();
    this.externalFilters = [];

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;

    this.getGridSetup();
  }

  viewTrade(ev) {
    this.route.navigate([`/post-trade/${ev.TradeID}`]);
  }

  bulkSelect() {
    const { value } = this.bulkAction;
    this.selectedTradeList = [];
    this.selectedChecked = {};

    if (value !== 'all' && value !== '') {
      this.gridData.data
        .filter(dataObj => dataObj.ScoreClass === value)
        .map(dataObj => {
          this.toggleTradeSelect(null, dataObj);
        });
    } else {
      this.gridData.data.map(dataObj => this.toggleTradeSelect(null, dataObj));
    }
  }

  toggleTradeSelect(ev = null, item) {
    if (ev) {
      ev.preventDefault();
    }
    const indexExisting = this.selectedTradeList.findIndex(x => x === item);

    if (indexExisting > -1) {
      this.selectedTradeList = this.selectedTradeList.filter(x => {
        return x != item;
      });
      this.selectedChecked[item.TradeID] = false;
    } else {
      this.selectedTradeList.push(item);
      this.selectedChecked[item.TradeID] = true;
    }
  }

  resetBulkAction(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.selectedTradeList = [];
    this.selectedChecked = {};
    this.bulkAction.setValue('');
  }

  bulkApprove() {
    const { value } = this.bulkAction;
    const bulkApproveIds = [];
    if (value === '' || value === null) {
      this.selectedTradeList.map(trade => {
        bulkApproveIds.push(trade.TradeID);
      });
    } else if (value !== 'all' && value !== '') {
      this.gridData.data
        .filter(dataObj => dataObj.ScoreClass === value)
        .map(dataObj => bulkApproveIds.push(dataObj.TradeID));
    } else {
      this.gridData.data.map(dataObj => bulkApproveIds.push(dataObj.TradeID));
    }
    this.ptSvc
      .updateTrade({ TradeID: bulkApproveIds, ApprovalStatus: 'APP' })
      .subscribe(() => {
        this.getGridData();
        this.snacky.open(`Selected trades was updated!`, 'Close', {
          duration: 4000,
        });
      });
  }

  displayValue(datafield, value) {
    const hasDisplayValue =
      datafield.Validation &&
      datafield.Validation.find(valOption => valOption.value === value);
    if (hasDisplayValue) {
      return datafield.Validation.find(valOption => valOption.value === value)
        .label;
    } else {
      return value;
    }
  }

  alertListDisplay(value) {
    if (value.includes(',')) {
      const newList = [];
      value.split(',').map(alertId => {
        newList.push(
          this.gridData.headers
            .find(header => header.DataField === 'AlertIDs')
            .Validation.find(alert => alert.value === alertId).label
        );
      });
      return newList.join(', ');
    } else if (value !== '') {
      return this.gridData.headers
        .find(header => header.DataField === 'AlertIDs')
        .Validation.find(alert => alert.value === value).label;
    }

    return value;
  }
}
