import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    @if (props.buttonType === 'beacon') {
      <app-annuity-lookup
        [formlyAttributes]="field"
        [index]="field.parent.key"
        [fieldData]="field"
      ></app-annuity-lookup>
    } @else if (rixtremaButtonTypes.includes(props.buttonType)) {
      <app-rixtrema-lookup
        [formlyAttributes]="field"
        [fieldData]="field"
        [buttonType]="props.buttonType"
        [type]="props.model.UnitRolloverSettings_ExternalSource"
      ></app-rixtrema-lookup>
    } @else if (props.buttonType === 'fundSearch') {
      <app-fund-fee-handler
        [fieldData]="field"
        [varSet]="varSet"
        [group]="props.group"
      ></app-fund-fee-handler>
    } @else {
      <button
        mat-button
        color="primary"
        type="info"
        (click)="onClick($event)"
        [formlyAttributes]="field"
        type="button"
        >{{ to.label }}</button
      >
    }
  `,
})
export class FormlyButtonComponent extends FieldType implements OnInit {
  @Output() returnData = new EventEmitter();

  varSet = 'CurrentPlanDetailsArray';
  rixtremaButtonTypes = [
    'rixtrema',
    'newPlanSearch',
    'search401k',
    'search403b',
    'searchMP',
    'searchPension',
  ];

  ngOnInit() {
    if (this.props.buttonType == 'fundSearch') {
      this.varSet = (this.key as string).split('_')[0];
    }
  }

  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }
}
