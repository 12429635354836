<div class="page-container program-container container unified">
  <div class="program-container col-12" *ngIf="selProgram">
    <ng-container *ngFor="let grp of assmntTypes">
      <h3 *ngIf="grp === 'validate'">Program to Validate</h3>
      <h3 *ngIf="grp === 'select'">Programs to Consider</h3>
      <ul class="funds col-12">
        <ng-container *ngFor="let x of programs">
          <li
            *ngIf="x.assessmentType == grp"
            class="fund col-12"
            [ngClass]="{
              expanded: x.assessmentType === 'validate',
              positive: x.score > scoring.positive[0],
              neutral:
                x.score >= scoring.neutral[0] && x.score <= scoring.neutral[1],
              negative: x.score < scoring.negative[1],
              selected: x.selected
            }"
          >
            <div class="col-12 row">
              <div
                class="col-2"
                *ngIf="
                  (validationCase && grp === 'validate') || !validationCase
                "
              >
                <button
                  mat-button
                  class="select"
                  (click)="selectFund(x.name, x)"
                >
                  <span *ngIf="!x.selected"
                    ><mat-icon>check_circle</mat-icon> Select</span
                  >
                  <span *ngIf="x.selected"
                    ><mat-icon>update</mat-icon> Review</span
                  >
                </button>
              </div>
              <div
                class="center"
                [ngClass]="{
                  'col-7 col-lg-8':
                    (validationCase && grp === 'validate') || !validationCase,
                  'col-9 col-lg-10': validationCase && grp !== 'validate'
                }"
              >
                <h4 class="content">
                  <ng-container *hasPermission="'RelevanceBridgeIcon'">
                    <mat-icon *ngIf="x.score > scoring.positive[0]"
                      >check_circle</mat-icon
                    >
                    <mat-icon
                      *ngIf="
                        x.score >= scoring.neutral[0] &&
                        x.score <= scoring.neutral[1]
                      "
                      >error</mat-icon
                    >
                    <mat-icon *ngIf="x.score < scoring.negative[1]"
                      >remove_circle</mat-icon
                    >
                  </ng-container>
                  {{ x.name }}
                  <span *hasPermission="'!RelevanceHideBridgeScore'">
                    | {{ x.score }}</span
                  >
                </h4>
              </div>
              <div class="col-2">
                <button mat-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>add_circle</mat-icon> Details
                  </span>
                  <span class="expanded-content">
                    <mat-icon>remove_circle</mat-icon> Close
                  </span>
                </button>
              </div>
            </div>
            <div class="details col-10 mx-auto">
              <p class="sub" *ngIf="x.description">{{ x.description }}</p>
              <ul *ngIf="x.bulletList">
                <li *ngFor="let bul of x.bulletList">{{ bul }}</li>
              </ul>
              <h5>Why this program scored this way:</h5>
              <app-reason-text
                class="col-12"
                [data]="x.reasons"
              ></app-reason-text>
            </div>
            <div
              class="rating-line"
              [ngClass]="{
                positive: x.score > scoring.positive[0],
                neutral:
                  x.score >= scoring.neutral[0] &&
                  x.score <= scoring.neutral[1],
                negative: x.score < scoring.negative[1]
              }"
              [style.width.%]="x.score"
            ></div>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>
<ngx-loading
  [show]="loading || internalLoading"
  *ngIf="internalLoading"
></ngx-loading>
