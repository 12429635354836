<div class="page-container proxy-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <kendo-grid
    #proxyGrid
    class="col-12"
    [scrollable]="'none'"
    [kendoGridBinding]="gridData"
    [pageSize]="50"
    [pageable]="true"
    [sortable]="true"
    [filterable]="'menu'"
    [selectable]="'single'"
    (selectionChange)="startProxy(proxyGrid, $event)"
  >
    <ng-template ngFor [ngForOf]="columns" let-column>
      <kendo-grid-column
        [field]="column.data"
        [title]="column.label"
        [sortable]="column.sortable"
        [filterable]="'false'"
        [media]="column.RespVis"
      ></kendo-grid-column>
    </ng-template>
  </kendo-grid>
</div>
<!-- <button mat-raised-button>Proxy
  <mat-icon></mat-icon>
</button> -->
<!-- <kendo-grid class="col-12" [scrollable]="'none'" [kendoGridBinding]="gridData" [pageSize]="pageSize" [pageable]="true" [sortable]="true"
    [filterable]="'menu'"> -->
