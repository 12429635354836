<h1 mat-dialog-header>Investment Amount</h1>
<div mat-dialog-content #scroll class="dialog-container">
  <form *ngIf="form" [formGroup]="form">
    <mat-form-field class="col-12">
      <mat-label
        >Enter the amount planned to invest or apply to this product.</mat-label
      >
      <input
        matInput
        placeholder="Enter the amount planned to invest or apply to this product."
        formControlName="amount"
        [maskito]="dollarMask"
        (keyup)="onChange()"
      />
      <mat-error *ngIf="form.controls['amount'].hasError('required')"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls['amount'].hasError('max')"
        >The amount entered exceeds available assets</mat-error
      >
    </mat-form-field>
    <div class="assets-label">Assets Available: {{ available | currency }}</div>
  </form>
</div>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">Save</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
