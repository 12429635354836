import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { PasswordValidation } from "../validators/password";

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {
  @ViewChild('passwordForm', { static: true }) form: FormGroup;

  headline: string;
  content: string;
  confirm: string;
  required = Validators.required;
  reset: boolean = false;
  forgot: boolean = true;
  submitted: boolean = false;
  username: string = null;
  nPass: string = null;
  vPass: string = null;

  constructor(private dialogRef: MatDialogRef<PasswordDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private rbs: RightBridgeApiService) {}

  @ViewChild('newPass') newPass;
  @ViewChild('verifyPass') verifyPass;

  ngOnInit() {
    console.log(this.data);

    if (this.data) {
      this.reset = this.data.reset;
      this.forgot = this.data.forgot;
      this.headline = this.data.headline || 'Forgot Password';
      this.content = this.forgot && !this.reset ? 'Please enter your username and a temporary password will be sent to the email address on file.' : 'It looks like your password needs to be reset. Please fill out the fields below to update your password.';
      this.confirm = this.data.confirm || 'Submit';
    }
    // if (!this.forgot && this.reset) {
    //   const formGroup = {};
    //   formGroup['newPass'] = new FormControl('', this.required);
    //   formGroup['verifyPass'] = new FormControl('', this.required);
    //   this.form = new UntypedFormGroup(formGroup);
    // }
    console.log(this.form);
  }

  cancel() {
    if (!this.forgot && this.reset){ this.rbs.logout(); }
    this.dialogRef.close({ data: 'cancel' });
  }

  savePass() {
    // console.log(this.form, this.form.value, this.forgot, this.reset);
    if(this.form.valid) {
      if(this.forgot && !this.reset) {
        if (this.submitted) {
          this.close();
        } else {
          this.rbs.forgotPassword(this.username).subscribe(res => {
              this.submitted = true;
              this.confirm = 'Close';
          });
        }
      } else if (!this.forgot && this.reset) {
        // console.log(this.form.value);
        if (this.nPass == this.vPass) {
          this.rbs.updatePassword(this.form.value).subscribe(res => {
            // console.log(res);
            this.dialogRef.close({ data: this.form.value });
            this.dialogRef.afterClosed().subscribe(result => {
              this.dialogRef = null;
            });
          })
        } else {
          this.form.controls['newPass'].setErrors({ 'noMatch': true });
          this.form.controls['newPass'].markAsTouched();
          this.form.controls['verifyPass'].setErrors({ 'noMatch': true });
          this.form.controls['verifyPass'].markAsTouched();
        }
      }
    }
  }

  close() {
    this.dialogRef.close({ data: this.form.value });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

}
