@switch (field.controlType) {
  @case ('integer') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('currency') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        mask="separator.2"
        thousandSeparator=","
        decimalMarker="."
        prefix="$"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('text') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('states') {
    <mat-form-field class="mr-2">
      <mat-label>Select States to Filter by</mat-label>
      <mat-select
        multiple
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (state of states; track state.value) {
          <mat-option [value]="state.value">
            {{ state.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @case ('dropdown') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-select
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (opt of field.options; track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.display }}
          </mat-option>
        }
      </mat-select>
      @if (
        filterForm.value[groupKey][field.varName] &&
        filterForm.value[groupKey][field.varName].length >= 1
      ) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('multiselect') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-select
        multiple
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (opt of field.options; track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.display }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @case ('repCode') {
    <mat-form-field class="mr-2 user-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoRepCode"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoRepCode="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('unit') {
    <mat-form-field class="mr-2 user-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoRepCode"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoRepCode="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('user') {
    <mat-form-field class="mr-2 user-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoRepCode"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoRepCode="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('range') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-date-range-input [rangePicker]="pickerField">
        <input
          matStartDate
          placeholder="After"
          [value]="filterForm.controls[groupKey].controls[field.varName]?.value"
          (dateChange)="changeDate($event)"
        />
        <input
          matEndDate
          placeholder="Before"
          [value]="filterForm.controls[groupKey].controls[field.varName]?.value"
          (dateChange)="changeDate($event)"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="pickerField"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #pickerField></mat-date-range-picker>
    </mat-form-field>
  }
}
