import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'localTime',
  pure: false
})
export class LocalTime implements PipeTransform{
    transform(value: string, format: string) {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let formatString = 'hh:mma'
        if (format === 'date') {
            formatString = 'MMM DD, YYYY'
        } else if (format === 'shortDate') {
            formatString = 'M/D/YYYY'
        } else if (format === 'dateTime') {
            formatString = 'MMM DD, YYYY, hh:mma'
        } else if (format === 'shortDateTime') {
            formatString = 'M/D/YY, hh:mma'
        } else if (format === 'longDateTime') {
            formatString = 'ddd MMM DD, YYYY, hh:mma'
        }
        return moment.utc(value).tz(timeZone).format(formatString)
    }
}
