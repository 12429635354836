<div class="page-container productSearch-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Product Search</h1>
  <div class="search-container row">
    <p class="col-12 mb-5" *ngIf="!historicalAllowed">
      The <strong>Current Product Search</strong> option provides access to
      information about the VA's, FIA's, RILA's and Fixed Annuities on your
      firms product shelf. This product information includes all available
      living benefit and death benefit riders for the national versions of the
      annuities. Please check with your firm to determine the availability of
      the listed riders and firm specific rates and pricing.
    </p>
    <p class="col-12 mb-5" *ngIf="historicalAllowed">
      The <strong>Current Product Search</strong> option provides access to
      information about the VA's, FIA's, RILA's and Fixed Annuities on your
      firms product shelf. This product information includes all available
      living benefit and death benefit riders for the national versions of the
      annuities. Please check with your firm to determine the availability of
      the listed riders and firm specific rates and pricing.<br /><br />The
      <strong>Historical Product Search</strong> option provides access to
      information for annuities both on and off your firms product shelf. This
      data is provided by Beacon Research and the information displayed is what
      is available from their extensive historical and current database. By
      entering a purchase/contract date the information provided will be for
      that specific contract period if available.</p
    >
    <div
      class="step one col-12 col-lg-3"
      *hasPermission="'AllowHistoricalFactSheetLookup'"
    >
      <mat-radio-group
        [(ngModel)]="searchType"
        class="search-type"
        (change)="clearAllData()"
      >
        <mat-radio-button value="current"
          >Current Product Search</mat-radio-button
        >
        <mat-radio-button value="historical"
          >Historical Product Search</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="step three col-12 col-lg-6">
      <div class="current">
        <mat-form-field class="col-12" *ngIf="searchType === 'historical'">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Annuity Purchase Date"
            [(ngModel)]="selectedDate"
            [max]="maxDate"
            (dateChange)="setDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-12" *ngIf="searchType === 'current'">
          <mat-select
            placeholder="Annuity Type"
            [(ngModel)]="annuityType"
            (selectionChange)="getCarriers($event)"
          >
            <mat-option value="fia-va">FIA's / VA's / RILA Products</mat-option>
            <mat-option value="fa">Fixed Annuities</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-select
            placeholder="Select a Carrier"
            [disabled]="!carriers || (carriers && carriers.length < 1)"
            [(ngModel)]="selectedCarrier"
            (selectionChange)="getAnnuities($event)"
          >
            <mat-option
              *ngFor="let opt of carriers"
              [value]="opt.value"
              matTooltip="{{ opt.display }}"
              matToolTipPosition="before"
              matTooltipShowDelay="750"
              >{{ opt.display }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-select
            placeholder="Select an Annuity"
            [disabled]="!annuities || (annuities && annuities.length < 1)"
            [(ngModel)]="selectedAnnuity"
            (selectionChange)="getDetails($event)"
            [matTooltipDisabled]="!selectedAnnuity"
            [matTooltip]="selectedAnnuity ? selectedAnnuity.Contract : ''"
            matToolTipPosition="before"
            matTooltipShowDelay="750"
            matTooltipHideDelay="200"
          >
            <mat-option
              *ngFor="let opt of annuities"
              [value]="opt"
              matTooltip="{{ opt.Contract }}"
              matToolTipPosition="before"
              matTooltipShowDelay="750"
              >{{ opt.Contract
              }}<ng-container *ngIf="opt.VersionDate">
                - {{ opt.VersionDate }}</ng-container
              ></mat-option
            >
          </mat-select>
        </mat-form-field>
        <span *ngIf="noAnnuities" class="message"
          >No products are available based on your selected criteria.</span
        >
      </div>
    </div>
    <div class="step four col-12 col-lg-3">
      <button mat-raised-button [disabled]="!canDownload" (click)="getPdf()"
        ><mat-icon>insert_drive_file</mat-icon>Download Fact Sheet</button
      >
    </div>
  </div>

  <div class="disclaimer row">
    Annuity contract dates and specifics should be obtained from the
    client’s/prospect’s actual contract to ensure accuracy. The Historical
    Product information is provided by Beacon Research and is not deemed or
    guaranteed by CapitalRock to be accurate. The RightBridge® application is
    intended to be used as an analytic tool for the financial representitive and
    not a replacement for a product prospectus, product summary guide or actual
    contract information. Using RightBRIDGE® is not a substitute for advisor
    due diligence. CapitalRock does not offer investment or financial advice.
    Refer to each annuity's prospectus, product summary guide or the annuity
    contract for product specific information. The information in this report is
    not guaranteed as accurate or complete. The information in a product
    prospectus or contract will always prevail over the information in this
    report. CapitalRock, LLC is not responsible for any losses or damages
    resulting from use of the information in this tool or report for any
    purpose. &copy;Copyright {{ year }} CapitalRock
  </div>
  <pre *ngIf="debug && searchType === 'historical'">{{
    this.selectedBeaconAnnuity | json
  }}</pre>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
