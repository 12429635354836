<button mat-button color="{{params?.color}}" [matMenuTriggerFor]="printMenu"><mat-icon>insert_drive_file</mat-icon> Generate Report</button>
<mat-menu #printMenu="matMenu">
  <button mat-menu-item (click)="customReport()" *ngIf="customPrintDefinition && customPrintDefinition.name">{{customPrintDefinition.name}}</button>
  <ng-container *ngIf="app?.toLowerCase() != 'aw' && !rights?.includes('HideStandardReport')">
    <button mat-menu-item (click)="generateStandardReport()">Standard Report</button>
    <ng-container *ngIf="isRollover">
      <button mat-menu-item (click)="generateRolloverReport()" *ngIf="rights?.includes('RolloverReport')">Rollover Report</button>
    </ng-container>
  </ng-container>
  <button mat-menu-item (click)="consolidatedReport()" *ngIf="app?.toLowerCase() != 'aw' && rights?.includes('ConsolidatedPrint')">Consolidated Report</button>
  <ng-container *ngIf="app?.toLowerCase() == 'aw'">
    <p class="px-3 pt-3 warning" *ngIf="(assessments && !assessments?.includes('validate')) && !annuitySelected">Please select an annuity in order to generate a report</p>
    <button mat-menu-item (click)="generateStandardReport()" *ngIf="rights?.includes('AwFullReport')" [ngClass]="{'disabled': assessments && assessments?.includes('validate') ? false : !annuitySelected}" matTooltip="Select one annuity below in order to generate a report" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'" [matTooltipDisabled]="assessments && assessments?.includes('validate') ? true : annuitySelected">Standard Report</button>
    <button mat-menu-item (click)="generateClientReport()" *ngIf="rights?.includes('AwCFVReport')" [ngClass]="{'disabled': assessments && assessments?.includes('validate') ? false : !annuitySelected}" matTooltip="Select one annuity below in order to generate a report" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'" [matTooltipDisabled]="assessments && assessments?.includes('validate') ? true : annuitySelected">Client Report</button>
    <button mat-menu-item (click)="generateExchangeReport()" *ngIf="rights?.includes('ExchangeReport') && isExchange" [ngClass]="{'disabled': !annuitySelected}" matTooltip="Select one annuity below in order to generate an exchange report" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'" [matTooltipDisabled]="annuitySelected">Exchange Report</button>
    <button mat-menu-item (click)="consolidatedReport()" *ngIf="rights?.includes('ConsolidatedPrint')" [ngClass]="{'disabled': assessments && assessments?.includes('validate') ? false : !annuitySelected}" matTooltip="Select one annuity below in order to generate a report" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'" [matTooltipDisabled]="assessments && assessments.includes('validate') ? true : annuitySelected">Consolidated Report</button>
  </ng-container>
</mat-menu>
