<mat-toolbar class="row footer">
  <div class="col-4">&copy; Copyright CapitalRock {{ year }}</div>
  <div class="app-info col-8 pull-right">
    <div class="app-info" *ngIf="globals">
      <span class="ml-40" *ngIf="uiVer">UI: {{ uiVer }}</span>
      <span class="ml-40" *ngIf="engine">Engine: {{ engine }}</span>
      <span class="ml-40" *ngIf="kb">KB Info: {{ kb }}</span>
      <span class="ml-40" *ngIf="ui">UI Config: {{ ui }}</span>
    </div>
  </div>
  <div class="col-12 cr-disclaimer" [innerHtml]="disclaimer"></div>
  <div class="col-12 kendo"
    >The RightBRIDGE&reg; system includes Progress® Telerik UI® components
    that are the intellectual property and copyrights of Telerik. Check the
    following link for the proper name.
    <a href="https://www.telerik.com/name-mapping"
      >https://www.telerik.com/name-mapping</a
    ></div
  >
</mat-toolbar>
