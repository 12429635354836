import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { throwError as observableThrowError, Observable, interval } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { ErrorReportingService } from './error-reporting.service';

@Injectable()
export class AppGlobalsService {

  constructor(private rbs: RightBridgeApiService, private titleService: Title, private errSvc: ErrorReportingService ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any>{
    // console.log('@#$@#$@#$@#$@#$@#$@#$@#$', route);
    let session: string = route.params.session || null,
        org: string = route.params.org || null;

    let globals;
    // if(session && org) {
    //   console.log('session and org', session, org);
    //   globals = this.rbs.getGlobals(session, org).map(x => x);
    // } else {
      // console.log('Normie');
    globals = this.rbs.getGlobals().pipe(map((res) => {
      return res;
    }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      }));
    // }
    return globals;
  }
}
