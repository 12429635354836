import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable()
export class BookManagementService {
  private environment;
  public baseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org || 'basev2';
  }

  gridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'pm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PolicyManagement/GridSetup`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  policyManagementData(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'pm')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = this.baseUrl + '/PolicyManagement/PolicySearch';
    const queryData = data;

    return this.http.post(url, queryData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPolicy(id, groupName) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'pm')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl +
      `/PolicyManagement/Profile?profileID=${id}&groupName=${groupName}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPolicyBridgeOptions(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'PM')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/GroupProfile?groupName=UnitValues`;

    return this.http.post(url, {}, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }
}
