export const environment = {
  production: true,
  apiBase: 'https://uat.rightbridge.net/api/BaseV2/api/v3',
  rixtremaBase: 'https://www.larkspurweb.com/PS2/AJAXPS.aspx',
  f5500Base: 'https://f5500-uat.rightbridge.net/api/products/product2020',
  basePath: '/app/',
  assets: null,
  env: 'uat',
  defaultApp: 'pp',
  org: 'basev2',
  firm: ''
};
