<div class="postback-loading" [ngClass]="{'show': show}">
  <div class="postback-loading-card">
    <div class="postback-loading-container">
      <mat-dialog-header>
        <h1 class="mb-0">Successfully Submitted</h1>
      </mat-dialog-header>
      <div class="postback-loading-content">
        <div class="postback-progress-container my-4">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- <p class="mb-0">This <strong>Firelight</strong> connection can take a minute or two.</p> -->
        <p class="mb-0">Submitting to an external system. This connection can take a minute or two.</p>
        <p class="mb-0">Thank you for your patience.</p>
      </div>
    </div>
  </div>
</div>